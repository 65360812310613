/* basic */

.basic-photo-container{
  display: flex;
}

.basic-details-container {
  padding: 20px 0px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.basic-photo-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.basic-photo-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.basic-profile-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  /* border: 2px solid #ccc; */
  margin-bottom: 10px;
  /* Space below the image */
  padding: 10px;
}

.basic-profile-pic-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  /* Space between take photo and upload button */
}

.basic-photo-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.basic-upload-button,
.basic-take-photo {
  color: var(--Gray-700, #344054);
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  background: inherit;
  border: none;
  cursor: pointer;
}

.basic-input-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 20px;
}

.grid-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.basic-custom-input {
  width: 100%;
}

.basic-input-lable {
  color: var(--Gray-700, #344054);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.voice-preference-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.voice-select-wrapper {
  flex: 1;
}

.voice-play-button {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--Turquoise, #04D9B5);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;

  transition: background-color 0.2s ease;
}

.voice-margin-top{
  margin-top: 15px;
}


.voice-play-button:hover {
  background-color: #00c99d;
}

.voice-play-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 231, 177, 0.3);
}

.date-picker-container {
  /* This makes the DOB field span the entire width */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  border-radius: 8px;
  border: 1px solid rgba(55, 57, 61, 0.4);
  background: var(--White, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  box-sizing: border-box;
  margin-bottom: 30px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.date-picker-container-input {
  border: none;
  width: 90%;
  font-family: "Urbanist", sans-serif;
  color: var(--Gray-500, #667085);

  /* Text m/Medium */

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
}

.date-picker-container-input:focus {
  outline: none;
}

/* Add these styles to your existing style.css file */

.responsive-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.birthday-field {
  grid-column: 1 / -1;
}

/* Media query for mobile screens */
@media screen and (max-width: 700px) {
  .responsive-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .basic-custom-input {
    width: 100%;
  }

  .date-picker-container-input {
    width: 100%;
  }
}

.basic-input-fields {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* Space between first and last name input */
}

.basic-input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.basic-custom-input {
  /* margin-bottom: 20px; */
  /* padding: 5px; */
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
}

.basic-replace {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: #b6b3b3;
  font-size: 16px;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  cursor: pointer;
  margin-top: 10px;
}

.camera-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* Dark semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  /* Ensure it appears above other content */
}

.camera-modal {
  background-color: white;
  margin: 10px;
  border-radius: 24px;
  display: flex;
  width: 596px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
  gap: 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;
}

.camera-title {
  color: var(--Black, #000);
  text-align: center;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 19.2px */
  letter-spacing: -0.32px;
}

.camera-suggestion {
  color: var(--Gray, #7e7f83);
  text-align: center;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 19.2px */
  letter-spacing: -0.32px;
}

.webcam {
  width: 100%;
  border-radius: 20px;
  margin-bottom: 10px;
}

.camera-instructions {
  margin: 15px 0;
  font-size: 0.9rem;
  text-align: center;
  color: #555;
}

.capture-button {
  display: flex;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 100px;
  border: 1px solid var(--Greek-Blue-Normal, #0d5eaf);
  background: var(--Greek-Blue-Normal, #0d5eaf);
  color: var(--Base-White, #fff);
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 155.556% */
}

.camera-icon {
  font-size: 1.2rem;
}

.capture-button:hover {
  background-color: #0050cc;
}

.close-camera-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #cac8c8;
  font-size: 1.3rem;
  cursor: pointer;
}

.close-camera-button:hover {
  color: #6f6f6f;
}

.close-icon {
  font-size: 1.5rem;
}

@media (max-width: 1024px) {
  .basic-details-container {
    width: 60%;
  }
}

@media (max-width: 600px) {
  .basic-details-container {
    width: 80%;
  }
}

/* email */

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Urbanist", sans-serif;
}

.Fullpage-container-email {
  height: 80dvh;
  display: flex;
  justify-content: center;
  width: 436px;
}

.email-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.email-header-container {
  display: flex;
  /* padding-top: 24px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.heading-h2 {
  color: var(--Black, #000);

  /* Display m/Medium */
  font-family: "Urbanist", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 38.4px */
  letter-spacing: -0.64px;
  margin-top: 0px;
}

.agree-heading {
  color: var(--Black, #000);
  font-size: 16px;
  font-weight: 500;
}

.signup-email-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.signup-verify-email {
  color: var(--Black, #000);

  /* Display m/Medium */
  font-family: "Urbanist", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 38.4px */
  letter-spacing: -0.64px;
}

.signup-verify-email-para {
  color: var(--Gray, #7e7f83);

  /* Text m/Medium */
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
}

.sign-up-para-title {
  color: var(--Gray-700, #344054);

  /* Text s/Medium */
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 19.6px */
  margin-bottom: 6px;
  margin-top: 0px;
}

.email-sent-container {
  display: block;
}

.signup-aggrement {
  overflow: hidden;
  color: var(--Gray, #7e7f83);
  text-overflow: ellipsis;

  /* Text m/Medium */
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-style: normal;
}

.signup-input {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(55, 57, 61, 0.4);
  background: var(--White, #fff);
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-family: "Urbanist", sans-serif;
  color: var(--Gray-500, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
}

.signup-input-boxshadow {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(55, 57, 61, 0.4);
  background: var(--White, #fff);
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;

  background: var(--White, #fff);

  color: var(--Gray-500, #667085);
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
}

.signup-input-boxshadow:focus {
  outline: none;
  border-radius: 8px;
  border: 1px solid rgba(13, 94, 175, 0.4);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
    0px 0px 0px 4px rgba(13, 94, 175, 0.07);
}

.error-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  border: 1px solid rgba(13, 94, 175, 0.4);
  border-radius: 12px;
  box-sizing: border-box;
}

.signup-input:focus {
  outline: none;
  border: 1px solid rgba(13, 94, 175, 0.4);
  box-shadow: 0 0 0 2px rgba(13, 94, 175, 0.07);
}

.signup-label {
  color: #344054;
  font-size: 14px;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  line-height: 19.6px;
  word-wrap: break-word;
}

.email-checkmark-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.disclaimer {
  text-align: center;
  font-size: 12px;
  color: #777;
  max-width: 450px;
  margin: 20px auto 5px auto;
}

.email-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  backdrop-filter: blur(2px);
  /* Adjust the blur radius as needed */
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;

  align-items: center;
}

.aggrement-policy-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 10px;
}

.aggrement-policy-container input[type="checkbox"] {
  min-width: 16px;
  min-height: 16px;
  border-radius: 4px;
  border: 1px solid #E4E4E7;
  background:#FFF;
  cursor: pointer;
}

.aggrement-policy-container p {
  color: var(--Gray-Darker, #2C2C2E);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.aggrement-policy-container p .agreement-span {
  color:#0D5EAF;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

input[type="password"]::-webkit-credentials-auto-fill-button {

  display: none;

  pointer-events: none;

  visibility: hidden;

}
 

@media (max-width: 470px) {
  .email-modal-content {
    height: 620px;
  }

  .signup-aggrement {
    max-height: 480px;
    /* Set a maximum height to enable scrolling when content overflows */
    overflow-y: auto;
    /* Enable vertical scrolling */
    color: var(--Gray, #7e7f83);
    text-overflow: ellipsis;
    /* Optional: Apply ellipsis for overflowed text */
    font-family: "Urbanist", sans-serif;
    font-size: 16px;
    font-style: normal;
    padding-right: 10px;
    /* Add padding to prevent scrollbar from overlapping text */
    box-sizing: border-box;
    /* Include padding in the width */
  }

  /* Optional: Customize scrollbar appearance */
  .signup-aggrement::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
  }

  .signup-aggrement::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Scrollbar track color */
  }

  .signup-aggrement::-webkit-scrollbar-thumb {
    background: #888;
    /* Scrollbar thumb color */
    border-radius: 4px;
  }

  .signup-aggrement::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Scrollbar thumb color on hover */
  }
}

.email-modal-content {
  background: white;
  padding: 20px;
  border-radius: 24px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  width: 90%;
  max-width: 596px;
  max-height: 540px;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.email-modal-main-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #7E7F83;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.Agree {
  color: var(--Base-White, #fff);
  font-feature-settings: "liga" off, "clig" off;
  width: 100%;
  border: none;
  display: flex;
  max-height: 52px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  /* Text lg/Medium */
  font-family: "Urbanist", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: -0.27px;
  border-radius: 100px;
  background: var(--Greek-Blue-Normal, #0d5eaf);
  cursor: pointer;
}

.signup-resendmail {
  color: var(--Black, #000);

  /* Text m/Medium */
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
  border: none;
  background: inherit;
}

.signup-error {
  color: var(--Error-500, #f04438);

  /* Text sm/Regular */
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.email-sent-message {
  margin: 40px auto;
  padding: 20px;
}

p {
  /* font-size: 12px; */
  font-weight: light;
}

.mb-10 {
  margin-bottom: 10px;
}

/* 
@media (min-width: 1440px) {
    .email-container {
        max-width: 40%;
    }
}

@media (min-width:1300px){
    .email-container{
               height: 100dvh;
       
    }
}

@media (max-width: 400px) {
    .email-container {
        max-width: 80%;
    }
} */

@media (max-width: 436px) {
  .email-container {
    padding: 10px;
  }
}

/* gender */
.Fullpage-gender-pronouns-container {
  height: 80dvh;
  display: flex;
  justify-content: center;
}

.gender-pronouns-container {
  padding: 20px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gender-pronouns-container label {
  color: var(--Gray-700, #344054);

  /* Text s/Medium */
  margin-bottom: 2rem;
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 19.6px */
}

.gender-pronouns-container select {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  color: rgba(102, 112, 133, 1);
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Urbanist", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: -0.27px;
  border-radius: 8px;
  border: 1px solid rgba(55, 57, 61, 0.4);
  background: var(--White, #fff);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.gender-pronouns-container select:focus {
  outline: none;
  box-shadow: none;
}

@media (max-width: 436px) {
  .gender-pronouns-container {
    padding: 10px;
  }
}

/* Mobile */
.mobile-para {
  color: var(--Gray, #7e7f83);

  /* Text m/Medium */
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
  margin-top: 0px;
  margin-bottom: 40px;
}

.mobile-input-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 4px;
}

.country-code {
  font-size: 16px;
  border: none;
  background: transparent;
  margin-right: 10px;
}

.mobile-number-input {
  width: 100%;
  font-size: 16px;
  outline: none;
  font-family: "Urbanist", sans-serif;
  border: none;
}

.signup-otp-container {
  display: flex;
  gap: 6px;
  justify-content: space-between;
}

@media (max-width: 330px) {
  .signup-otp-container {
    gap: 3px;
  }
}

.signup-otp-box {
  /* width: 5vw;
  height: 5vw;
  max-width: 40px;
  max-height: 40px;
  min-width: 2px;
  min-height: 2px; */
  width: 40px;
  height: 40px;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

@media (max-width: 436px) {
  .signup-otp-box {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 350px) {
  .signup-otp-box {
    width: 20px;
    height: 20px;
  }
}

.signup-otp-box:hover,
.signup-otp-box:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.signup-otp-box.error {
  border: 1px solid red;
}

.signup-no-otp {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resend-email-link-button {
  color: var(--Black, #000);

  /* Text xs/Medium */
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  border: none;
  background: inherit;
  cursor: pointer;
}

.country-code-select {
  width: 160px;
  /* Adjust this value for the desired width */
  min-height: unset;
  /* Ensure that height doesn't increase */
}

@media (max-width: 1024px) {
  .mobile-container {
    width: 60%;
  }
}

@media (max-width: 600px) {
  .mobile-container {
    width: 80%;
  }
}

/* password page */
.Fullpage-container-password {
  height: 80dvh;
  display: flex;
  justify-content: center;
}

.password-container {
  padding: 20px 0px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Style the input wrapper */

/* Style the eye icon inside the input field */
.eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 20px;
  /* Adjust size as needed */
  height: 20px;
  opacity: 0.6;
}

.eye-icon:hover {
  opacity: 1;
  /* Make it more visible when hovered */
}

@media (max-width: 1024px) {
  .password-container {
    width: 60%;
  }
}

@media (max-width: 600px) {
  .password-container {
    width: 80%;
  }
}

.password-criteria {
  margin-top: 10px;
  font-family: "Urbanist", sans-serif;
}

.criteria-item {
  display: flex;
  align-items: center;
  color: #98a2b3;
  /* Default color for unmet criteria */
  margin-bottom: 5px;
}

.criteria-item.valid {
  color: green;
  /* Change color for satisfied criteria */
}

.signup-checkmark {
  width: 16px;
  /* Adjust as needed */
  height: 16px;
  /* Adjust as needed */
  margin-right: 8px;
  /* Space between checkmark and text */
}

/* plan page */

.Full-planpage-conatiner {
  height: 80dvh;
  display: flex;
  justify-content: center;
}

.plan-page {
  width: 50%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); */
  font-family: "Urbanist", sans-serif;
}

.back-button {
  background: none;
  border: none;
  font-size: 16px;
  color: #007aff;
  cursor: pointer;
  margin-bottom: 20px;
}

.plan-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
}

.plan-subtitle {
  font-size: 16px;
  color: #888;
  text-align: center;
  margin-bottom: 30px;
}

.plan-options {
  border-radius: 12px;
  margin-bottom: 30px;
}

.plan-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, border 0.3s ease;
  position: relative;
}

.plan-header {
  display: flex;
  align-items: center;
  gap: 20px;
}

.plan-checkbox {
  margin-right: 10px;
}

.plan-option h3 {
  font-size: 18px;
  margin: 0;
}

.plan-option p {
  font-size: 14px;
  margin: 4px 0 0;
  color: #666;
}

.plan-price {
  font-size: 18px;
  font-weight: 600;
}

.advice-link {
  color: #666;
  text-decoration: none;
}

.selected {
  background-color: #f0f9ff;
  border: 2px solid #007aff;
}

/* Hover effect */
.plan-option:hover {
  background-color: #f5f5f5;
}

.subscribe-button {
  width: 100%;
  padding: 15px;
  background-color: #007aff;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscribe-button:hover {
  background-color: #005bb5;
}

/* .phone-input-container {
  margin: 20px 0;
} */

.PhoneInputCountrySelect {
  width: 65px;
}

.PhoneInput {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 12px;
}

.phone-input {
  border-radius: 8px;
  border: 1px solid rgba(13, 94, 175, 0.4);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
    0px 0px 0px 4px rgba(13, 94, 175, 0.07);
}

.PhoneInputCountry {
  margin-right: 8px;
}

.PhoneInputInput {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
}

.PhoneInputInput:focus {
  outline: none;
}

/* Add this if you want to style the country select button */
.PhoneInputCountrySelect {
  border: none;
  background: transparent;
}

.PhoneInputCountryIconImg {
  display: none;
}

/* QRpage */

.QR-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.QR-heading {
  color: var(--Black, #000);

  /* Display m/Medium */
  font-family: "Urbanist";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 38.4px */
  letter-spacing: -0.64px;
  margin: 0px;
  word-wrap: break-word;
  /* Handle long words */
}

.QR-header-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  word-wrap: break-word;
  /* Handle long words */
}

.QR-description {
  color: var(--Gray-Normal, #7e7f83);

  /* Text m/Medium */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
  margin: 0px;
}

.QR-main-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 40px;
  width: 100%;
  /* Restrict the width for larger screens */
}

.QR-web-version-button {
  display: flex;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border-radius: 100px;
  border: 1px solid var(--Greek-Blue-Normal, #0d5eaf);
  background: var(--Greek-Blue-Normal, #0d5eaf);
  color: var(--Base-White, #fff);
  font-family: "Urbanist";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 155.556% */
  gap: 16px;
  width: 70%;
}

.QR-Frame1000003933 {
  width: 100%;
  height: 22px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.QR-Line107 {
  width: 40%;
  height: 0px;
  border: 1px solid rgba(13, 94, 175, 0.2);
}

.QR-WhichAppealsToYou {
  text-align: center;
  color: #7e7f83;
  font-size: 16px;
  font-family: "Urbanist";
  font-weight: 500;
  line-height: 22px;
}

.QR-Line106 {
  width: 157px;
  height: 0px;
  border: 1px solid rgba(13, 94, 175, 0.2);
}

.QR-download-text {
  color: var(--Gray, #7e7f83);
  text-align: center;

  /* Text m/Medium */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
  align-self: stretch;
}

.QR-app-store-buttons {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 8px;
}

.QR-app-store-buttons a {
  margin: 0 15px;
  /* Adjust margin for better spacing on smaller screens */
}

@media (max-width: 768px) {
  .QR-heading {
    font-size: 24px;
  }

  .QR-web-version-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .QR-Frame1000003933 {
    gap: 4px;
  }

  .QR-download-text {
    font-size: 16px;
  }
}

/* 
@media(max-width: 436px) {
  .QR-app-store-buttons {
    flex-direction: column;
    margin-left:  0;
  }
} */

/* Faith Upgradation */

.faith-upgradation-footer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.submit-button.skip {
  background: transparent;
  color: #344054;
  border: 1px solid #D0D5DD;
}

.faith-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid rgba(55, 57, 61, 0.40);
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-top: 6px;
  color: #667085;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}
.faith-input-container.focussed {
  border: 1px solid rgba(13, 94, 175, 0.40);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(13, 94, 175, 0.07);
}
.faith-options-container {
  position: relative;
}

.faith-options-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.faith-options {
  width: 406px;
  max-width: 436px;
  max-height: 285px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #FFF;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  margin-top: 4px;
  z-index: 999999999;
  overflow-y: auto;
}

.faith-options::-webkit-scrollbar {
  width: 6px;
}
.faith-options::-webkit-scrollbar-track {
  background: transparent;
}
.faith-options::-webkit-scrollbar-thumb {
  background: #E4E4E7;
  border-radius: 999px;
}

.faith-option-first-level {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  cursor: pointer;
  margin: 2px 16px;
}

.faith-option-second-level-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0 4px 30px;
  cursor: pointer;
}

.faith-option-second-level-item:hover, .faith-option-second-level-item.active{
  background: #F7F7F7;
}

.faith-options-right {
  width: 350px;
  max-width: 350px;
  border-radius: 12px;
  background: #F7F7F7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  z-index: 999999999;
  padding: 4px 0;
  color: #2C2C2E;
  font-size: 14px;
  font-weight: 500;
  max-height: 285px;
}
.faith-option-third-level {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  cursor: pointer;
  border-radius: 8px;
}

.faith-option-third-level.active, .faith-option-third-level:hover {
  background: #E4E4E7;
}

.faith-option-fourth-level-item {
  padding: 4px 0 4px 30px;
  cursor: pointer;
}

.faith-option-fourth-level-item:hover {
  background: #E4E4E7;
}

.faith-options-center {
  text-align: center;
  color: #f04438;
}

.resend-email-link-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}


@media screen and (max-width: 480px) {
  .faith-options-container {
    flex-direction: column;
  }
}

@media(max-width: 375px)

{.voice-play-button {
  margin-top: 23px;
}}