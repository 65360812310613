.assessment-hero-container {
  width: 100%;
  min-height: 100dvh;
  background: linear-gradient(168.14deg, #4675fe 6.98%, #05d6b6 102.55%);
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-bottom: 20px; */
  box-sizing: border-box;
  position: relative;
}

.supressed-bg {
  width: 100%;
  /* background-color: bisque; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  /* background: rgba(0, 0, 0, 0.08); Semi-transparent overlay */
}
.mt-12 {
  margin-top: 12px;
}

@media (max-width: 1024px) {
  .supressed-bg {
    width: 100%;
    height: auto;
    background: none;
  }
}

@media (min-width: 1024px) and (max-width: 1050px) and (min-height: 1300px) and (max-height: 1366px) {
  .supressed-bg {
    width: 100%;
    height: auto;
    background: none;
  }
}
