.progress-button {
  display: flex;
  /* width: 343px; */
  /* height: 52px; */
  width: 90%;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 56px;
  background: var(--Buttons-Default-Base, #fff);
  cursor: pointer;
}


@media(max-width: 1024px) {
  .progress-button {
    width: 250px;
  }
}