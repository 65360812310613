/* Container styling */
.Personality0GraphGlower-hero-wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden; /* Ensure glow stays within bounds */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Image styling */
.Personality0GraphGlower-hero-wrapper img {
  display: block;
  width: 90%;
  height: 85%;
}

/* Glow effect */
.corner-glow {
  position: absolute;
  width: 150px;
  height: 150px;
  background: radial-gradient(circle, #31c6af, transparent);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none; /* Prevent user interaction */
  animation: cornerGlowEffect 3s ease-in-out forwards;
}

/* Corner positions */
.top-left {
  top: 0;
  left: 0;
  transform: translate(45%);
  animation-delay: 0s; /* Glow first */
}

.top-right {
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  animation-delay: 1s; /* Glow second */
}

.bottom-left {
  bottom: 0;
  left: 0;
  transform: translate(-50%, 50%);
  animation-delay: 2s; /* Glow third */
}

.bottom-right {
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
  animation-delay: 3s; /* Glow last */
}

/* Glow animation */
@keyframes cornerGlowEffect {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
