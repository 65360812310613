.display-center {
  text-align: center !important;
}

.bg-fb {
  background: #e9ecf2 !important;
  color: #274079 !important;
}

.bg-fb-active {
  color: #e9ecf2 !important;
  background: #274079 !important;
}

.txt-area-fb-width {
  width: 100% !important;
}

.fb-txt-input {
  color: var(--Base-Black, #000);

  /* Text s/Medium */
  font-family: Urbanist;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
}

.set-phone-submit-button {
  display: flex;
  max-height: 52px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* flex: 1 0 0; */
  width: 40%;
  border-radius: 100px;
  background: var(--Greek-Blue-Normal, #0d5eaf);
  border: none;

  color: var(--Base-White, #fff);
  font-feature-settings: "liga" off, "clig" off;

  /* Text lg/Medium */
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.27px;
}
