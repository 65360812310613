.index-container {
  height: 25px;
  margin: 5px 0;
  padding: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  /* text-transform: uppercase; */
  font-size: 1rem;
  background-color: #f7f7f7;
  font-weight: 500;
}

.index-container-inner {
  background-color: #000 !important;
  color: #fff;
  padding: 12px;
  border-radius: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-bc {
  background-color: #fff !important;
}

@media screen and (max-width: 768px) {
  .index-container {
    font-size: 0.8rem;
  }
}
