/* .forgetpassword-left-section {
    width: 60%;
    background: linear-gradient(to bottom right, #00c6ff, #0072ff);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  /* .group-1{
    width: 70%;
    border: 2px solid rgb(243, 237, 237);
    opacity: 0.5;
    padding: 20px;
    border-radius: 50%;
  }


  .group-2{
    width: 70%;
    border: 2px solid rgb(243, 237, 237);
    opacity: 0.5;
    padding: 50px;
    border-radius: 50%;
  }


  
  .group-3{
    width: 70%;
    border: 2px solid rgb(243, 237, 237);
    opacity: 0.5;
    padding: 50px;
    border-radius: 50%;
  } */


  /* @media (max-width: 1024px) {

    .forgetpassword-left-section {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
    }   */




    .forgetpassword-left-section {
      width: 60%;
      height: 100vh;
      background: linear-gradient(to bottom right, #00c6ff, #0072ff);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    
    .stroke-1, .stroke-2, .stroke-3 {
      position: absolute;
  
    }
    
    .stroke-1 {
      width: 250px;
      height: 250px;
      animation: pulse 2s infinite 1s;
    }
    
    .stroke-2 {
      width: 200px;
      height: 200px;
      animation: pulse 2s infinite 1s;
    }
    
    .stroke-3 {
      width: 150px;
      height: 150px;
      animation: pulse 2s infinite 2s;
    }
    
    .group-container {
      position: relative;
      z-index: 1; /* Ensures the main image stays above the strokes */
    }
    
    .main-group {
      width: 100px;
      height: 100px;
    }
    
    @keyframes pulse {
      0%, 100% {
        transform: scale(1);
        opacity: 0.6;
      }
      50% {
        transform: scale(1.1);
        opacity: 1;
      }
    }
    
    @media (max-width: 1024px) {
      .forgetpassword-left-section {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
    }