.session-selection-container {
    padding: 1rem;
}
.session-selection-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.session-selection-header p {
    font-size: 24px;
    font-weight: 500;
}
.session-buttons-container {
    display: flex;
    align-items: center;
    background-color: #F7F7F7;
    border-radius: 1rem;
    padding: 4px;
    margin-bottom: 1rem;
}
.session-buttons-container .session-button {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 4px;
    border-radius: 12px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
}
.session-buttons-container .session-button.selected {
    background-color: #4674FE;
    color: #FFFFFF;
    font-weight: 500;
}
.buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
}
.buttons-container .start-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 20px;
    border: 1px solid #D0D5DD;
    border-radius: 100px;
    font-size: 18px;
    font-weight: 500;
    background-color: #0D5EAF;
    color: #FFFFFF;
    cursor: pointer;
}

.buttons-container .schedule-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem 20px;
    border: 1px solid #D0D5DD;
    border-radius: 100px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

@media screen and (max-width: 440px) {
  .session-selection-container {
    /* padding-left: 0;
    padding-right: 0; */
    width: 350px;
  }
}

/* calendar page */

.session-schedule-container {
    padding: 1rem;
}
.session-schedule-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.session-schedule-container .schedule-title {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 1rem;
}

.session-schedule-container .continue-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 20px;
    border: 1px solid #D0D5DD;
    border-radius: 100px;
    font-size: 18px;
    font-weight: 500;
    background-color: #0D5EAF;
    color: #FFFFFF;
    margin-top: 1rem;
    cursor: pointer;
}

/* time selector page */
.time-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 1rem;
  }
  
  .coaching-session-scheduler-time-dropdown-container {
    width: 50%;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    position: relative;
    padding: 10px 0 10px 14px;
    font-size: 16px;
    font-weight: 400;
  }
  
  .coaching-session-scheduler-time-dropdown-container .time-dropdown {
    width: 90%;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 400;
  }
  
  .coaching-session-scheduler-time-dropdown-container .separator {
    font-size: 16px;
    font-weight: bold;
    color: #374151; /* Dark gray */
  }

  .time-error-container {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border: 1px solid #F04438;
    border-radius: 10px;
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 400;
    justify-self: center;
  }

  @media screen and (max-width: 440px) {
    .session-schedule-container{
      /* padding-left: 0;
      padding-right: 0; */
      width: 350px;
    }
  }

  /* Add to calendar page */

  .scheduled-title{
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    color: #000000;
    margin-bottom: 1rem;
  }
  .scheduled-text{
    text-align: center;
  }

  .scheduled-text .date-text, .scheduled-text .time-text{
    font-size: 32px;
    font-weight: 500;
    color: #4674FE;
  }

  .scheduled-text .time-text {
    margin-bottom: 1rem;
  }

  .scheduled-text .scheduled-description {
    font-size: 16px;
    font-weight: 500;
    color: #7E7F83;
  }

  /* Add session page */

  .add-session-container {
    padding: 1rem;
  }

  .add-session-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .add-session-header p {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  @media screen and (max-width: 440px) {
    .add-session-container{
      /* padding-left: 0;
      padding-right: 0; */
      width: 350px;
    }
  }

  /* Additional Coaching Plan page */

  .additional-coaching-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0.5rem;
  }
  .additional-coaching-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .additional-coaching-header p {
    font-size: 24px;
    font-weight: 500;
  }
  .additional-coaching-plans-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 24px 20px;
    border-radius: 16px;
    border: 1px solid #E4E4E7;
    background-color: #EDF1FF33;
    box-shadow: 0px 4px 14px 0px #00000012;
  }
  .additional-coaching-plan-container {
    display: flex;
    align-items: center;
    gap: 0.1rem;
  }
  .additional-coaching-plan-container p {
    font-weight: 400;
    font-size: 16px;
  }
  .additional-coaching-plan-container input[type="checkbox"] {
    appearance: none;
    min-width: 16px;
    min-height: 16px;
    border: 2px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
  }

  .additional-coaching-plan-container input[type="checkbox"]:checked {
    background-color: #04D9B5;
    border-color: #04D9B5;
  }
  .additional-coaching-plan-container input[type="checkbox"]:checked::after {
    content: "✓";
    position: absolute;
    color: white;
    font-size: 14px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .add-hours-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .add-hours-increment-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .add-hours-button {
    padding: 4px 12px;
    border-radius: 4px;
    background-color: #4674FE;
    color: #FFFFFF;
    cursor: pointer;
  }
  .additional-coaching-footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .additional-coaching-total-price-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .additional-coaching-total-price-container .total-price-text {
    font-size: 18px;
    font-weight: 500;
    color: #7E7F83;
  }
  .additional-coaching-total-price-container .total-price-number {
    font-size: 18px;
    font-weight: 600;
  }
  .continue-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 20px;
    border: 1px solid #D0D5DD;
    border-radius: 100px;
    font-size: 18px;
    font-weight: 500;
    background-color: #0D5EAF;
    color: #FFFFFF;
    cursor: pointer;
    margin-bottom: 1rem;
  }