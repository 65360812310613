.login-container {
  display: flex;
 height: 100vh;
 min-height: 100dvh;
}

/* Left Section */
.left-section {
  width: 55%;
  background: linear-gradient(168deg, #4675fe 6.98%, #05d6b6 102.55%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Right Section */
.right-section {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 15px;
  align-items: center;
  padding: 20px;
}


.right-section-content {
  width: 75%;
}


/* Toggle Buttons */
.login-toggle-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  width: 16rem;
}

.toggle-button {
  background-color: white;
  /* width: 120px; */
  /* padding: 0px 29px 6px 29px; */
  border: none;
  font-family: "Urbanist", sans-serif;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  color: #b9b9b9;
}

.toggle-button.active {
  border: none;
  border-bottom: 1px solid #b4cde6;
  border-width: 2px;
  color: #2c2c2e;
}

/* Form Elements */
.login-form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login-form-container .login-label {
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  font-weight: 500;
  /* margin-bottom: 1rem; */
}

.login-h2 {
  font-size: 32px;
  font-family: Urbanist;
  font-weight: 500;
}

.login-form-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-form-input::placeholder {
  font-size: 14px;
  color: #999;
}

.login-form-button {
  width: 100%;
  background-color: rgba(13, 94, 175, 1);
  color: white;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.login-form-button:hover {
  background-color: #0056b3;
}

.forgetpassword {
  padding: 0px;
  background-color: inherit;
  font-size: 14px;
  color: rgba(71, 84, 103, 1);
  border: none;
  text-decoration: none;
  font-family: "Urbanist", sans-serif;
}

.login-page-create-account {
  margin-top: 10px;
  color: #000;
  text-decoration: none;
  font-family: "Urbanist", sans-serif;
}

/* Responsive Layouts */

@media (max-width: 1023px) {
  .login-container {
    flex-direction: column;
    height: auto;
    background: linear-gradient(168deg, #4675fe 6.98%, #05d6b6 102.55%);
    justify-content: center;
    align-items: center;
    padding: 20px; /* Add padding for smaller screens */
  }

  .left-section {
    width: 100%;
    max-width: 90%; /* Limit width for mobile/tablet */
    background: none;
    color: black;
    border-radius: 20px;
    padding: 20px;
  }
  .right-section {
    width: 85%;
    margin-top: 30px;
  }

  .login-otp-box {
    width: 5.1dvw;
    height: 5.1dvw !important;
  }

  .right-section-content {
    width: 60%;
  }

  .login-form-container {
    margin: 0 auto;
  }
}


.login-form-container {
  margin: 0 auto;
}

.login-form-button {
  font-size: 14px;
  color: var(--White, #fff);
  font-feature-settings: "liga" off, "clig" off;

  /* Text lg/Medium */
  font-family: "Urbanist", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.27px;
  border-radius: 100px;
  padding: 14px 24px;
}

.forgot-password {
  font-size: 12px;
}

.create-account a {
  font-size: 14px;
}

.login-otp-header {
  color: var(--Black, #000);
  font-feature-settings: "liga" off, "clig" off;

  /* Text lg/Medium */
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.27px;
}

.login-otp-txt{
  color: var(--Gray, #7E7F83);

  /* Text m/Medium */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
margin-bottom: 40px;}

.login-otp-box {
  width: 3.1dvw;
  height: 3.1dvw !important;
}

.login-resend-code-container{
  margin-top: 6px;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}


.login-send-code{
  color: var(--Gray-600, #475467);
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
}

.login-resend-code{color: var(--Black, #000);
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
cursor: pointer;
}

.login-changephone{color: var(--Black, #000);
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
cursor: pointer;
margin-top: 12px;
margin-bottom: 40px;}

@media (max-width: 650px) {
  .right-section-content {
    width: 90%;
  }
}

@media (max-width: 560px) {
  .login-container {
    padding: 10px 10px 0px 10px;
  }

 .right-section{
  border-radius: 15px 15px 0px 0px;
  width: 94%;
 }
 .right-section-content {
  width: 100%;
 
}
}

.reset-password-wrapper {
  width: 100%;
}

.reset-password-wrapper.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.reset-password-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 400px;
  width: 100%;
  height: 60%;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.password-input {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 95%;
}

/* Modal container */
.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  font-family: Urbanist;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  z-index: 1000; /* Ensure it's above other elements */
}

/* Modal content */
.login-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 90%;
  max-width: 350px;
}

.managesession-para {
  font-weight: 500px;
  font-size: 16px;
  text-align: left;
}

.managesession-cancelbtn {
  padding: 10px 16px;
  margin: 16px;
  margin-bottom: 0px;
  margin-left: 0px;
  border-radius: 100px;
  color: #131313;
  border: none;
  font-family: Urbanist;
}

.managesession-continuebtn {
  padding: 10px 16px;
  margin: 16px;
  margin-left: 0px;
  margin-bottom: 0px;
  border-radius: 100px;
  border: none;
  background-color: #0056b3;
  color: #fff;
  font-family: Urbanist;
}

.managesession-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 370px) {
  .login-otp-box {
    width: 25px;
    height: 25px;
  }
}
