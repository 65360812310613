.chat-input {
  background-color: aqua;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  background: #f9f9f9;
  color: #667085;
  width: 100%; /* Make it take full width of parent */
  height: 100%; /* Make it take full height of parent */
  font-size: 1rem;
  color: var(--Gray-500, #667085);
  font-family: Urbanist;

  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
