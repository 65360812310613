.chat-contacts-hero-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 85px;
  flex-wrap: wrap;
  cursor: pointer;
}

.chat-contacts-initial {
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #005f6d;
  text-align: center;
  font-family: Urbanist;
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 9px; /* 37.5% */

  background: linear-gradient(45deg, #9eecff 23%, #a0ffea 100%);

  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 200px;
}

.chat-add-req-bg {
  background: #0d5eaf !important;
}

.chat-contact-img {
  width: 64px;
  height: 64px;
  border-radius: 200px;
}

.chat-contacts-name {
  color: var(--Black, #000);
  text-align: center;

  /* Text xs/Semibold */
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
  text-transform: capitalize;
}
