.assess-container {
  width: 29%;
  min-height: 86dvh;
  max-height: 85vh;
  padding: 20px 20px 20px 30px;
  border-radius: 24px;
  /* opacity: 0px; */
  background: var(--White, #ffffff);
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
  /* overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none; */
}

.assess-container::-webkit-scrollbar {
  display: none;
}

.assess-inner {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.assess-header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.assess-body {
  display: flex;
  flex-direction: column;
  height: calc(85dvh - 160px);
  gap: 20px;
  overflow-y: auto;
  scrollbar-width: 4px;
  /* scrollbar-width: thin; For Firefox */
  scrollbar-gutter: stable;
  padding-right: 10px;
  overflow-x: hidden;
}

.mt-5 {
  margin-top: 5px;
}

.not-allowed {
  cursor: not-allowed !important;
}

.assess-body::-webkit-scrollbar {
  width: 4px;
}

.assess-body::-webkit-scrollbar-thumb {
  background-color: #4674fe;
  border-radius: 2px;
  height: 100px;
}

.assess-body::-webkit-scrollbar-track {
  background-color: transparent;
}

.assess-question {
  font-size: 1.48rem;
  font-weight: 500;
  line-height: 2.2rem;
  letter-spacing: -0.02em;
  text-align: left;
}

.assess-descripption {
  color: var(--Black, #000);

  /* Text xl/Medium */
  font-family: "Urbanist", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 28px */
  letter-spacing: -0.3px;
}

.assess-options-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* max-height: 240px; */
  /* overflow-y: auto; */
}

.flx-row {
  flex-direction: row;
}

.assess-options {
  background: var(--Gray-Light, #f7f7f7);
  border-radius: 12px;
  padding: 12px;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  gap: 5px;
  cursor: pointer;
  /* line-height: 24px; */

  color: var(--Base-Black, #000);

  /* Text m/Medium */
  font-family: Urbanist;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.32rem;
  /* 137.5% */
}

.assess-description {
  color: var(--Black, #000);

  /* Text xl/Medium */
  font-family: Urbanist;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 28px */
  letter-spacing: -0.3px;
}

.assess-options input[type="checkbox"] {
  accent-color: #274079;
  width: 0.8rem;
  height: 0.8rem;
}

.assess-options-active {
  background: var(--Mint-Green-Normal, #82e3cc) !important;
}

.assess-footer {
  height: 80px;
  /* box-shadow: 0px 9px 29px 0px #00000026; */
  border-radius: 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.disabled-progress {
  /* opacity: 50% !important; */
  background-color: #dcdcdc80 !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
  -khtml-border-radius: 50%;
  cursor: not-allowed !important;
}

.flex-center {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.error-msg {
  color: red;
  font-size: 1rem;
}

.assessment-title {
  color: #000;
  font-family: "Urbanist";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

@media (max-width: 500px) {
  .assess-options-container {
    /* max-height: 240px; */
  }
}

@media (max-width: 500px) {
  .assess-options-container {
    /* max-height: 240px; */
  }
}

@media (min-width: 1024px) and (max-width: 1220px) {
  .flx-row {
    /* max-height: 214px; */
  }
}

/* @media (min-width: 1024px) {
  .assess-container {
    width: 40%;
    box-sizing: border-box;
    margin: 10px 0;
    overflow: hidden;
    max-height: none;
    min-height: 86dvh;
  }
} */
@media (max-width: 1024px) {
  .assess-body {
    height: calc(85dvh - 170px);
  }
}

@media (min-width: 1024px) and (max-width: 1050px) and (min-height: 1300px) and (max-height: 1366px) {
  .assess-container {
    width: 80% !important;
    box-sizing: border-box;
    margin: 10px 0;
    /* background-color: #274079 !important; */
  }
}