.about-aris-app * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Urbanist";
}

.about-aris-nav-container {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.about-aris-content-container {
  background-color: #f2f7fe;
  /* border-top-left-radius: 30px;
  border-top-right-radius: 30px; */
}
.about-aris-logo-container {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 1px solid #e4e4e7;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
}

.about-aris-space {
  width: 3rem;
  height: 3rem;
}

.about-aris-back-button {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-aris-title {
  font-size: 32px;
}

.vision {
  width: 100%;
  /* height: 80vh; */
  text-align: left;
  padding: 2rem 1.5rem;
}

.vision-logo {
  width: 100px;
  height: 100px;
  margin: 0 auto 2rem;
  display: none;
}

.vision-content {
  display: flex;
  flex-direction: column;
}

.vision-label {
  font-size: 24px;
  display: block;
  margin-bottom: 1rem;
  text-align: center;
  color: #4674fe;
  font-weight: bold;
}

.vision-heading {
  width: 100%;
  font-size: 28px;
  margin-bottom: 1rem;
  line-height: 120%;
  font-weight: 600;
  text-align: center;
}

.vision-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  opacity: 60%;
  text-align: center;
}

.about-aris-section {
  padding: 2rem 1.5rem;
  background-color: #fff;
  /* border-top-left-radius: 30px;
  border-top-right-radius: 30px; */
}

.about-aris-section .overview-label {
  font-size: 24px;
  display: block;
  margin-bottom: 1.5rem;
  text-align: center;
  width: 100%;
  color: #4674fe;
  font-weight: bold;
}

.about-aris-section .overview-content {
  display: flex;
  flex-direction: column;
}

.about-aris-section .overview-content .overview-heading {
  width: 100%;
  font-size: 40px;
  line-height: 32px;
  margin-bottom: 2rem;
  font-weight: 600;
}

.about-aris-section .overview-content .overview-text {
  font-weight: 500;
  font-size: 20px;
  width: 100%;
  margin-bottom: 1rem;
  line-height: 28px;
  opacity: 60%;
}

.about-aris-cards-section {
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 24px;
  max-width: 1200px;
  flex-wrap: wrap;
}

/* Desktop Styles */
@media screen and (min-width: 488px) {
  .about-aris-header {
    height: 65vh;
    background-image: url("../../Assets/images/sphere.svg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 1300px;
    width: 100%;
  }

  .vision {
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 3rem;
    overflow: hidden;
  }

  .vision-heading {
    width: 49%;
    align-self: center;
    font-size: 40px;
    line-height: 120%;
    font-weight: 600;
  }

  .vision-text {
    width: 36%;
    align-self: center;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
  }

  .vision-label {
    text-align: center;
  }

  .about-aris-section {
    position: relative;
    padding-top: 18rem;
  }

  .about-aris-section .overview-label {
    text-align: center;
  }

  .about-aris-section .overview-content .overview-heading {
    text-align: center;
  }

  .about-aris-section .overview-content .overview-text {
    width: 45%;
    align-self: center;
    text-align: center;
    opacity: 80%;
  }
  .about-aris-stroke-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* z-index: -1; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .stroke-image1 {
    opacity: 0.8;
    filter: invert(57%) sepia(87%) saturate(1771%) hue-rotate(182deg)
      brightness(101%) contrast(98%);
    width: 350px;
    height: 350px;
  }

  .stroke-image2 {
    opacity: 0.5;
    filter: invert(57%) sepia(87%) saturate(1771%) hue-rotate(182deg)
      brightness(101%) contrast(98%);
    width: 480px;
    height: 480px;
  }
  .stroke-image3 {
    opacity: 0.3;
    filter: invert(57%) sepia(87%) saturate(1771%) hue-rotate(182deg)
      brightness(101%) contrast(98%);
    width: 600px;
    height: 600px;
  }

  .stroke-image1,
  .stroke-image2,
  .stroke-image3 {
    position: absolute;
  }

  .about-overview {
    position: relative;
    bottom: 65px;
    /* z-index: 1; */
  }
  .about-aris-cards-section {
    margin: 2rem auto;
  }
}

/* Tablet Responsive Styles */
@media screen and (max-width: 769px) {
  .vision {
    padding: 0 1.5rem;
    padding-top: 4rem;
  }
  .about-gradient-background {
    /* background: conic-gradient(
        from 130deg at -4.63% 74.02%,
        #05d6b6 0deg,
        #4675fe 360deg
      ),
      linear-gradient(to right, #1afafa, #010db3),
      linear-gradient(to bottom, #98cdf9 0%, #ffffff 100%);

    background-blend-mode: overlay; */
    /* width: 100%;
    height: 20dvh;

    padding: 0px;

    text-align: center;
    margin-bottom: 0px; */
  }
  .vision-heading {
    width: 65%;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
  .vision-text {
    width: 56%;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
  .about-aris-section {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
    padding-top: 15rem;
  }
  .about-aris-section .overview-content .overview-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    width: 75%;
  }
  .about-aris-section .overview-content .overview-heading {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
  .about-aris-header {
    height: 90vh;
  }
}

/* Mobile Responsive Styles */
@media screen and (max-width: 488px) {
  .about-aris-nav-container {
    gap: 1rem;
  }

  .about-aris-header {
    height: fit-content;
  }

  .about-aris-logo-container {
    border: none;
    width: 50px;
    height: 50px;
  }

  .about-aris-title {
    font-size: 20px;
  }

  .vision {
    background-image: none;
    background-color: none;
  }

  .vision-label {
    text-align: left;
    font-weight: 500;
  }

  .vision-text {
    text-align: left;
    width: 100%;
  }

  .vision-heading {
    width: 100%;
    font-size: 32px;
    line-height: 38.4px;
    text-align: left;
    font-weight: 500;
  }

  .vision-logo {
    display: block;
  }
  .about-aris-stroke-container {
    display: none;
  }
  .about-gradient-background {
    /* background: conic-gradient(
        from 130deg at -4.63% 74.02%,
        #05d6b6 0deg,
        #4675fe 360deg
      ),
      linear-gradient(to right, #1afafa, #010db3),
      linear-gradient(to bottom, #98cdf9 0%, #ffffff 100%); */

    background-blend-mode: overlay;
    width: 100%;
    height: 20dvh;

    padding: 0px;

    text-align: center;
    margin-bottom: 0px;
  }

  .about-aris-cards-section {
    align-items: center;
    padding: 0;
  }

  /* .vision {
    position: relative;
    z-index: 1;
    padding-top: 3rem;
    padding-bottom: 0;
  } */
  .about-aris-section {
    padding-top: 4rem;
  }

  .about-aris-section .overview-label {
    text-align: left;
    font-weight: 500;
  }

  .about-aris-section .overview-content .overview-heading {
    font-size: 32px;
    line-height: 38.4px;
    text-align: left;
    font-weight: 500;
    width: 100%;
  }

  .about-aris-section .overview-content .overview-text {
    font-size: 16px;
    text-align: left;
    width: 100%;
  }

  .about-aris-navigate-icon {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 2rem 1rem;
  }

  .about-aris-navigate-icon div {
    background-color: #4674fe;
    border-radius: 50%;
    padding: 0.75rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-height: 910px) and (min-width: 488px) {
  .about-aris-header {
    height: 38dvh;
  }
}
