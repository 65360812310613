@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;700&display=swap");
html {
  font-size: 16px; /* default root font size */
}
body {
  margin: 0;
  font-family: "Urbanist", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

html {
  font-size: 16px; /* base font size for smaller devices */
}

/* For larger screens (laptops, 1024px and above) */
@media (max-width: 578px) {
  html {
    font-size: 14px; /* maximum font size for large screens */
  }
}
/* For very large screens (large desktops, 1440px and above) */
@media (min-width: 1440px) {
  html {
    font-size: 16px; /* maximum font size for large screens */
  }
}

@media (min-width: 1840px) {
  html {
    font-size: 16px; /* maximum font size for large screens */
  }
}

@media (min-width: 2500px) {
  html {
    font-size: 22px; /* maximum font size for large screens */
  }
}
