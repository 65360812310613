.about-aris-card {
    border-radius: 24px;
    padding: 32px;
    width: 100%;
    margin: 16px;
    flex: 1 1 300px;
  }
  
  .about-aris-card-number {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 2rem;
    text-align: left;
  }
  
  .about-aris-card-heading {
    font-size: 32px;
    margin-bottom: 16px;
    text-align: left;
    font-weight: 500;
  }
  
  .about-aris-card-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
    /* Desktop Styles */
@media screen and (min-width: 769px) {
  .about-aris-card {
      max-width: 16rem;
      max-height: 17.25rem;
    }

}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {

  .about-aris-card {
    max-width: 13.5rem;
    max-height: 17rem;
    padding: 24px;
    flex: 1;
  }
  .about-aris-card-heading {
    font-size: 24px;
  }
}

@media screen and (max-width: 440px) {

  .about-aris-card {
    max-width: 21.5rem;
    max-height: 17rem;
    padding: 24px;
    flex: 1 21.5rem;
  }
  .about-aris-card-heading {
    font-size: 24px;
  }
}