.Managemanage-wrapper {
  max-width: 800px;
  margin: 0 auto;
  background: white;
  border-radius: var(--Radius-radius-2xl, 24px);
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 16px;
  margin-bottom: 24px;
  z-index: 1;
}
.Managemembership-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.Annual-membership {
  display: flex;
  flex-direction: column;
  padding: var(--Spacing-spacing-lg, 16px);
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: var(--Radius-radius-lg, 16px);
  border: 1px solid rgba(53, 87, 191, 0.07);
}

.current-plan {
  display: inline;
  text-align: left;
  color: var(--Gray-Normal, #7e7f83);

  /* Text m/Medium */
  font-family: Urbanist;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}

.Arismembership-button-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  text-align: left;
}

.switchpayment-btn {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  color: var(--Gray-700, #344054);

  /* Text md/Semibold */
  font-family: Urbanist;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  cursor: pointer;
}

.purchase-additonal-btn {
  color: var(--Base-White, #fff);

  /* Text md/Semibold */
  font-family: Urbanist;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  border-radius: 100px;
  border: 1px solid var(--Marian-Blue, #274079);
  background: var(--Marian-Blue, #274079);
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.membership-btn-container {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.membership-aris {
  overflow: hidden;
  color: var(--Black, #000);
  text-overflow: ellipsis;
  font-family: Urbanist;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.24px;
}

.membership-arist2 {
  overflow: hidden;
  color: var(--Crayola-Blue, #4674fe);
  text-overflow: ellipsis;

  /* Text m/Medium */
  font-family: Urbanist;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  /* cursor: pointer; */
}

.Additional-membership {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Additonal-membership-heading-container {
  text-align: left;
}

.Additonal-membership-heading {
  color: var(--Base-Black, #000);

  /* Display xs/Semibold */
  font-family: Urbanist;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.Additonal-membership-para {
  color: var(--Gray-Normal, #7e7f83);

  /* Text m/Medium */
  font-family: Urbanist;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}

.membership-Add-lisence-btn {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  color: var(--Gray-700, #344054);
  font-family: Urbanist;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  cursor: pointer;
}

.annual-membership-validity-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.annual-membership-validity {
  overflow: hidden;
  color: var(--Gray-Normal, #7e7f83);
  text-overflow: ellipsis;

  /* Text xs/Medium */
  font-family: Urbanist;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
}

.faith-button-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.membership-showmore {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 18px 8px;
  border-top: 1px solid var(--stroke-10, rgba(255, 255, 255, 0.1));
  background: var(--Gray-Light, #f7f7f7);
  color: var(--Primary-colors-Blue, #00a3ff);
  text-align: center;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.21px;
  box-sizing: border-box;
}

.annualMembershipsubtxt {
  overflow: hidden;
  color: var(--Gray-Normal, #7e7f83);
  text-overflow: ellipsis;

  /* Text xs/Medium */
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
}

.annualMembershipsubtxt-blue {
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--Crayola-Blue-Normal, #4674fe);
  text-overflow: ellipsis;

  /* Text xs/Medium */
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.plan-info-membership-container {
  text-align: left;
  display: flex;
  padding: var(--Spacing-spacing-xl, 20px) 20px;
  flex-direction: column;
  align-items: left;
  gap: var(--Spacing-spacing-lg, 16px);
  align-self: stretch;
  border-radius: 16px;
  background: var(--Transperency, rgba(237, 241, 255, 0.2));
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
}

.border-buymins{
  border: 1px solid #4674fe !important; 
}

.plan-info-membership-container-faith {
  text-align: left;
  display: flex;
  padding: var(--Spacing-spacing-xl, 20px) 20px;
  flex-direction: column;
  align-items: left;
  gap: var(--Spacing-spacing-lg, 16px);
  align-self: stretch;
  border-radius: 16px;
  background: var(--Transperency, rgba(237, 241, 255, 0.2)) !important;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07) !important;
  border: 1px solid #4674fe;
}

.plan-info-membership-heading {
  text-align: left;
  color: var(--Gray-Darker, #2c2c2e);

  /* Text lg/Semibold */
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
}

.membership-info-txt-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.membership-info-txt {
  overflow: hidden;
  color: var(--Gray, #7e7f83);
  text-overflow: ellipsis;

  /* Text s/Regular */
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}

.point-style {
  display: flex;
  align-items: center;
}

.plan-info-membership-heading-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.primary-acc-holder {
  width: 100%;
  box-sizing: border-box;
}

.takeownership-sublabel {
  color: var(--Gray-Normal, #7e7f83);

  /* Text s/Regular */
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}

.req-radio-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  color: var(--Gray-Darker, #2c2c2e);
  text-overflow: ellipsis;

  /* Text m/Regular */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  margin-bottom: 20px;
}

.Req-mins-btn-container {
  margin-top: 24px;
  display: flex;
  gap: 8px;
}

.req-skip {
  display: flex;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 100px;
  border: 1px solid var(--Gray-300, #d0d5dd) !important;
  background: var(--Base-White, #fff) !important;
  color: var(--Gray-700, #344054) !important;
}

.membership-price-text-1 {
  color: var(--Gray-Normal, #7e7f83);

  /* Text s/Regular */
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.pricing-counter-amount-1 {
  overflow: hidden;
  color: var(--Gray, #7e7f83);
  text-overflow: ellipsis;

  /* Text m/Medium */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}

.manage-faith {
  padding: 20px;
  border-radius: 16px;
  background: var(--Transperency, rgba(237, 241, 255, 0.2)) !important;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
  border: 1px solid #4674fe;
  margin-top: 24px;
}

.manage-left-txt {
  text-align: left !important;
}

@media (max-width: 768px) {
  .Managemanage-wrapper {
    max-width: 476px;
  }

  .Arismembership-button-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .membership-btn-container {
    margin-top: 16px;
  }

  .Additional-membership {
    flex-direction: column;
    align-items: flex-star;
  }

  .membership-Add-lisence-btn {
    margin-top: 16px;
  }

  .purchase-additonal-btn{
    margin-top: 16px;
  }
  .switchpayment-btn{
    margin-top: 16px;
  }
}

@media (max-width: 768px) {
  .Managemanage-wrapper {
    background: none;
    border: none;
  }
  .membership-aris {
    font-size: 18px;
  }

  .Additonal-membership-heading {
    font-size: 18px;
  }
}

@media (max-width: 360px) {
  .annual-membership-validity-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
}
