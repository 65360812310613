.img-cnt {
  position: relative;
  max-width: 100dvw;
  max-height: 100dvh;
  overflow: hidden;
}

.confetti {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

@media (min-width: 800px) {
  .confetti {
    position: absolute;
    top: 0;
    left: 20%;
    z-index: 999;
  }
}
