/* style.css */
.page-container {
  min-height: 100vh;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.relationships-container {
  width: 100%;
}

/* Top Bar */
.relationship-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* margin-top: 10px; */
}

.relationship-top-bar.centered {
  justify-content: center;
  /* Center the header */
}

.relationship-top-bar h1 {
  font-size: 1.5rem;
  font-family: "Urbanist", sans-serif;
  font-style: normal;
  /* z-index: 1; */
  font-weight: 600;
  line-height: 38px;
}

.relation-hover-pointer {
  height: 20px;
  width: 20px;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid #e4e4e7;
  background: #fff;
  cursor: pointer;
}

@media (max-width: 480px) {
  .relation-hover-pointer {
    stroke: #fff;
    background: inherit;
    border: none;
    color: #fff;
    padding: 0;
  }

  .relationship-top-bar h1 {
    font-family: Urbanist, sans-serif;
    font-weight: 200;
  }
}

.header-person {
  position: absolute;
  right: 10px;
}

/* Main Content */
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 200px);
}

/* Profile Container */
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-align: center;
  margin-top: 55px;
}

/* Circle Wrapper and Vectors */
.circle-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vector-circle {
  position: absolute;
  border-radius: 50%;
  animation: pulse 2s infinite ease-in-out;
}

.circle-1 {
  width: 100px;
  height: 100px;
  stroke-width: 0.449px;
  stroke: var(--Crayola-Blue, #507cfe);
  opacity: 0.15;
}

.circle-2 {
  width: 80px;
  height: 80px;
  stroke-width: 0.449px;
  stroke: var(--Crayola-Blue, #6b90ff);
  opacity: 0.2;
}

.circle-3 {
  width: 60px;
  height: 60px;
  background: var(
    --Gradient,
    linear-gradient(168deg, #4675fe 6.98%, #05d6b6 102.55%)
  );
}

.profile-image {
  position: relative;

  border-radius: 50%;
  /* object-fit: cover; */
  z-index: 1;
}

/* Button Styles */
/* .add-person-btn {
  width: 100%;
  border: none;
  background-color: rgba(224, 231, 245, 1);
  color: rgba(116, 148, 210, 1);
  border-radius: 25px !important;
  padding: 12px 24px !important;
  text-transform: none !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
} */

/* Add these new styles while keeping your original ones */
/* Add these new styles while keeping your original ones */

.relationship-modal-content {
  background: white;
  padding: 20px;
  border-radius: var(--Radius-radius-2xl, 24px);
  border: 1px solid rgba(0, 0, 0, 0.05);
  max-width: 800px;
  margin: 0 auto;
}

.upload-photo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

.upload-photo-label {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.upload-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  /* Ensure the image stays inside the circle */
}

.centered-modal .left-arrow {
  position: absolute;
  left: 10px;
}

.centered-title {
  text-align: center;
  flex-grow: 1;
}

@media (max-width: 480px) {
  .centered-title {
    color: var(--White, #fff);
    text-align: center;

    /* Text lg/Semibold */
    font-family: "Urbanist";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 23.4px */
  }

  /* .relationship-top-bar h1{
   display: none;
  } */

  /* .relation-hover-pointer{
    padding: 0px;
    background: inherit;
    border: none;
    stroke: white;
    color: white;
  } */
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  margin-bottom: 24px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
}

.form-group label {
  font-size: 14px;
  font-weight: 500;
  color: #1a1a1a;
}

.optional {
  color: var(--Gray-Normal, #7e7f83);
  font-weight: normal;
  font-size: 14px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: var(--Spacing-spacing-lg, 16px) var(--Spacing-spacing-md, 12px);
  border: 1px solid #e4e4e7;
  border-radius: 12px;
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  color: #1a202c;
  background-color: white;
  max-width: 100%;
  box-sizing: border-box;
  color: var(--Base-Black, #000);

  /* Text m/Medium */
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}

.form-group-mobile {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.addrelationship-phoneinput {
  width: 100%;
  padding: var(--Spacing-spacing-lg, 17px) var(--Spacing-spacing-md, 12px);
  border: 1px solid #e4e4e7;
  border-radius: 12px;
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  color: #1a202c;
  background-color: white;
  max-width: 100%;
  box-sizing: border-box;
  color: var(--Base-Black, #000);

  /* Text m/Medium */
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
}

.form-group.full-width {
  grid-column: 1 / -1;
}

.form-group textarea {
  padding: 8px 12px;
  font-family: "Urbanist", sans-serif;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  resize: vertical;
  min-height: 100px;
}

.form-group .optional {
  color: var(--Gray-Normal, #7e7f83);
  font-weight: normal;
  font-size: 14px;
}

/* Add these styles for better select appearance */
.form-group select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%234A5568'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.record-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

/* .record-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: 1px solid #E0E7F5;
  border-radius: 8px;
  background: white;
  cursor: pointer;
} */

.record-button.recording {
  background: #ffebee;
  border-color: #ef5350;
}

.mic-icon {
  width: 20px;
  height: 20px;
}

.modal-actions {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
}

/* .add-person-btn {
  font-family: "Urbanist", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 200px;
} */

.add-person-btn.filled {
  background-color: rgba(13, 94, 175, 1);
  color: white;
}

.cancel-btn {
  background: none;
  border: none;
  color: #fb3a3a;
  cursor: pointer;
  padding: 12px 24px;
}

/* Audio player styles */
audio {
  height: 32px;
  margin-left: 12px;
}

.mobile-label-addrelation {
  font-size: 14px;
  font-weight: 500;
  color: #1a1a1a;
}


.name-align-left
{
  text-align: left !important;
}

@media (max-width: 768px) {
  .form-grid {
    grid-template-columns: 1fr;
  }

  .relationship-modal-content {
    padding: 16px;
    /* max-width: 600px; */
  }

  .modal-actions {
    flex-direction: column;
    width: 100%;
  }

  .add-person-btn {
    font-family: "Urbanist", sans-serif;
    display: flex;
    align-items: center;
    width: 50%;
  }
}

/* Pulse Animation */
@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.7;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.95);
    opacity: 0.7;
  }
}

/* Responsive Design */
@media (max-width: 480px) {
  /* .circle-1 {
    width: 80px;
    height: 80px;
  }

  .circle-2 {
    width: 60px;
    height: 60px;
  }

  .circle-3 {
    width: 40px;
    height: 40px;
  }

  .profile-image {
    width: 30px;
    height: 30px;
  } */

  /* .top-bar h1{
    font-size: 1rem;
  } */
}

.mt-10 {
  margin-top: 24px;
}

@media (max-width: 768px) {
  .content {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 200px);
    align-items: flex-start;
    background-color: white;
    border-radius: 12px;
  }
}
