.potrait-hero {
  max-width: 100dvw;
  overflow-x: hidden;
}

.potraits-header-hero-cntr {
  display: flex;
  position: fixed;
  top: 0;
  padding: 8px;
  width: 100%;
  /* height: 84px; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  box-sizing: border-box;
  z-index: 100;
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(100px);
  ;
  /* overflow: hidden; */
}

.combi-pot-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000004;
}

.arrow-bg {
  background-color: #fff;
  display: flex;
  /* width: 44px; */
  max-width: 44px;
  max-height: 44px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 100px;
  border: 1px solid var(--gray-light-hover, #e4e4e7);
  background: var(--Base-White, #fff);
}

.potraits-header-txt {
  color: #000;
  text-align: center;

  /* Display m/Semibold */
  font-family: "Urbanist";
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.1rem;
  /* 118.75% */
  margin-top: 15px;
  margin-right: 50px;
}

.potraits-container {
  position: relative;
  margin-top: 12dvh;
  z-index: 12;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.potrait-cntr-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.potraits-typo-1 {
  /* Text m/Regular */
  font-family: "Urbanist";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: 32px;
  width: 60%;
  text-align: center;
}

/* .potraits-head-txt {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
} */

.potrait-tab-selection-txt {
  /* Text s/Medium */
  font-family: "Urbanist";
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 19.6px */
}

.gradient-background-2 {
  background: conic-gradient(from 130deg at -4.63% 74.02%,
      #05d6b6 0deg,
      #4675fe 360deg),
    linear-gradient(to right, #1afafa, #010db3),
    linear-gradient(to bottom, #98cdf9 0%, #ffffff 100%);
  background-blend-mode: overlay;
  width: 100%;
  height: 14dvh;
  padding: 0px;
  text-align: center;
  margin-bottom: 0px;
  position: absolute;
  top: 0;
}

.combined-gradient-background {
  display: block;
  height: 10dvh;
  /* background: linear-gradient(168deg, #4675fe 0%, #05d6b6 100%); */
  background: conic-gradient(from 178deg at 50% 50%,
      #05d6b6 0deg,
      #4675fe 360deg);
  filter: blur(50px);
  background-blend-mode: overlay;
  width: 100%;
  /* height: 14dvh; */
  padding: 0px;
  text-align: center;
  margin-bottom: 0px;
  position: absolute;
  top: 0;
}

.potraits-tab-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px !important;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.overview-container-hero {
  width: 100%;
  /* margin-top: 5px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 43dvh;
}

.overview-image-container {
  width: 350px;
  height: 350px;
  transform: rotate(-47.426deg);
  flex-shrink: 0;

  border-radius: 440.647px;
  opacity: 1;
  /*0.8;*/
  background: conic-gradient(from 177deg at 52.81% 53.92%,
      rgba(5, 214, 182, 0.03) 42.74410128593445deg,
      #fff 76.91867887973785deg,
      #05d6b6 131.95531725883484deg,
      #05d6b6 156.60000085830688deg,
      rgba(255, 255, 255, 0) 229.18031930923462deg,
      rgba(0, 163, 255, 0) 257.29302406311035deg,
      #00a3ff 360deg);

  filter: blur(41.4129638671875px);
  position: absolute;
}

@media (max-width: 600px) {
  .overview-image-container {
    /* width: 250px; */
    /* height: 250px; */
    width: 80%;
    height: 80%;
  }
}

.stroke-1-ov {
  position: absolute;
  width: 288.596px;
  height: 331.886px;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: var(--White, #fff);
  opacity: 0.9;
  z-index: 10;
}

.stroke-2-ov {
  position: absolute;

  width: 398.263px;
  height: 458.868px;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: var(--White, #fff);
  opacity: 0.8;
}

.overview-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overview-image {
  z-index: 11;
  display: flex;
  width: 203.515px;
  height: 203.515px;
  /* height: 50%; */
  min-height: 100px;
  padding: var(--Spacing-spacing-xs, 8px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: var(--Radius-radius-full, 999px);
  /* background: var(
    --image-jane-smith-02,
    url(<path-to-image>) lightgray 50% / cover no-repeat,
    #ddeeea
  ); */
}

.badged-background {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00ffd8;
  text-transform: uppercase;
  font-size: 4rem;
  font-weight: 500;
  color: #fff;
  background: linear-gradient(45deg, #9eecff 23%, #a0ffea);
  color: #005f6d;
  font-weight: thin;
  border-radius: 50%;
}

@media (max-width: 480px) {
  .overview-image {
    width: 100px;
    height: 100px;
  }

  .badged-background {
    font-size: 2.2rem;
  }

  .stroke-1-ov {
    width: 141px;
    height: 163px;
  }

  .stroke-2-ov {
    width: 195px;
    height: 225px;
  }

  .overview-container-hero {
    height: 30dvh;
  }

  .overview-name {
    margin-bottom: 15px;
  }
}

.content-overview-loader {
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview-name {
  position: relative;
  bottom: 0;

  color: var(--Base-Black, #000);
  text-align: center;

  /* Display s/Semibold */
  font-family: "Urbanist";
  font-size: 2.1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  /* 135.714% */
  margin-bottom: 5px;
  top: -10px;
}

.overview-text-container {
  width: 60%;
  margin: auto;
  color: var(--Gray-Normal, #7e7f83);
  text-align: center;

  /* Text lg/Regular */
  font-family: "Urbanist";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 155.556% */
  letter-spacing: -0.27px;
}

.personality-hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100dvw;
  overflow-x: hidden;
}

.potraits-tab-header-txt {
  color: #000;

  /* Display xs/Semibold */
  font-family: "Urbanist";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 133.333% */
  margin-bottom: 32px;
  margin-top: 24px;
}

.align-text-left {
  text-align: left !important;
}

.potraits-sub-txt {
  color: #000;

  /* Display xs/Medium */
  font-family: "Urbanist";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 28.8px */
}

.personality-txt-normal {
  /* Text m/Regular */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
}

.personality-upper-head {
  color: rgba(0, 0, 0, 0.8);
}

.personality-head-cntr {
  margin-top: 36px;
  text-align: center;
}

.graph-tmp-personality {
  margin: 10px;
  width: 70%;
  height: 70%;
}

.potraits-explanation-cntr {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  /* background-color: #00a3ff; */
  position: relative;
}

.potraits-bordered-container {
  display: flex;
  width: 40%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: var(--White, #fff);
}

.potraits-details-explanation-txt {
  color: rgba(19, 19, 22, 0.8);
  text-align: center;

  /* Text m/Regular */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
}

.video-tmp-personality {
  width: 100%;
  margin: 2rem 0;
  border-radius: 12px;
}

.personality-summary {
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  background: var(--2, #fafafa);
}

.summary-head {
  color: var(--Base-Black, #000);
  align-self: flex-start;

  /* Text xl/Medium */
  font-family: "Urbanist";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 28px */
  letter-spacing: -0.3px;
}

.summary-txt {
  overflow: hidden;
  color: #7e7f83;
  text-overflow: ellipsis;

  /* Text m/Regular */
  font-family: "Urbanist";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  /* 125% */
}

.mt-16 {
  margin-top: 16px;
}

.potraits-show-more-container {
  display: flex;
  padding: 18px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 20px 16px;
  box-sizing: border-box;
  cursor: pointer;
  /* align-self: stretch; */

  border-top: 1px solid var(--stroke-10, rgba(255, 255, 255, 0.1));
  background: var(--Gray-Light, #f7f7f7);

  color: var(--Primary-colors-Blue, #00a3ff);
  text-align: center;
  font-family: "Urbanist";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.21px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.potraits-instructors-cntr {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;

  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: var(--White, #fff);
}

.head-top-strength {
  color: var(--Base-Black, #000);

  /* Text xl/Medium */
  font-family: "Urbanist";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 28px */
  letter-spacing: -0.3px;
}

.top-strength-hero-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  margin-bottom: 20px;
}

.show-more-less-hero {
  width: 100%;
  margin: 12px 0;
}

.show-more-less-heading {
  color: var(--Base-Black, #000);

  /* Text xl/Medium */
  font-family: "Urbanist";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 28px */
  letter-spacing: -0.3px;
}

.inside-show-less-cntr {
  display: flex;
  /* padding: 20px 16px; */
  padding-bottom: 20px;
  flex-direction: column;
  /* align-items: center; */
  gap: 16px;
  align-self: stretch;
  background: #fafafa;
}

.show-less-in-head {
  color: var(--Base-Black, #000);

  /* Text m/Semibold */
  font-family: "Urbanist";
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  /* 137.5% */
  letter-spacing: -0.24px;
}

.show-less-in {
  color: var(--Gray-Normal, #7e7f83);

  /* Text m/Regular */
  font-family: "Urbanist";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  /* 125% */
}

.top-strength-list-cntr {
  display: flex;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
}

.strength-list {
  display: flex;
  gap: 8px;

  color: var(--Base-Black, #000);
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Work Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
  letter-spacing: -0.32px;
}

.portraits-width {
  width: 20px;
  height: 20px;
}

.m-16 {
  margin: 24px 0;
  box-sizing: border-box;
}

.bg-clr-none {
  background-color: none;
  width: 100%;
}

.potraits-explanation-cntr-2 {
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.instructors-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
}

.potraits-graph-summary-cntr {
  display: flex;

  padding: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 60%;

  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  /* margin: 24px 0; */
}

.potraits-graph-decision-making {
  display: flex;

  padding: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.potraits-separater-line {
  background: rgba(0, 0, 0, 0.05);
  height: 1px;
  width: 90%;
}

.potraits-content-head-txt {
  color: var(--Base-Black, #000);
  font-feature-settings: "liga" off, "clig" off;

  /* Text lg/Medium */
  font-family: "Urbanist";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: -0.27px;
}

.hover-pointer {
  cursor: pointer;
}

.hover-error {
  cursor: not-allowed;
}

.potraits-sub-txt {
  color: var(--Gray-Normal, #7e7f83);
  text-align: center;

  /* Text m/Regular */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
}

.potraits-graph-summary-txt-cntr {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 12px;
  align-self: stretch;
  background: var(--2, #fafafa);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.potraits-common-head-bottom-hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.125rem;
  margin-bottom: 20px;
}

.potraits-common-head-bottom-heading-secondary {
  /* text-align: center; */
  /* width: 100%; */
  color: #000;
  text-align: center;
  align-self: center;

  /* Text xl/Semibold */
  font-family: "Urbanist";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.3px;
}

.personality-other-data-container {
  width: 80%;
  position: relative;
  margin: 40px 0;
}

.potraits-percent-summary-hero-cntr {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 21px;
}

.potraits-common-head-bottom-heading {
  color: #000;
  text-align: center;

  /* Text xl/Semibold */
  font-family: "Urbanist";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.3px;
}

.potraits-common-head-bottom-section {
  /* overflow: hidden; */
  color: rgba(19, 19, 22, 0.8);
  /* text-overflow: ellipsis;
  white-space: nowrap; */

  /* Text m/Regular */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
}

.bg-none {
  background: none;
}

.personality-graph-txt-emiter {
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 30px 0;
  border-radius: var(--Radius-radius-2xl, 24px);
  border: 1.412px solid rgba(0, 0, 0, 0.05);
}

.personality-graph-txt-emiter_story {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 30px 0;
  border-radius: var(--Radius-radius-2xl, 24px);
  border: 1.412px solid rgba(0, 0, 0, 0.05);
}

.non-boreder-margin {
  border: none;
  margin: 0;
}

.personality-graph-txt {
  display: flex;
  width: 85%;
  /* Commented the previous value, needs to be removed after the verification and testing */
  /* width: 100%; */
  align-items: center;
  justify-content: space-between;
  margin: 28px 0;

  color: #131316;

  /* Text xl/Medium */
  font-family: "Urbanist";
  font-size: 1rem;
  /* 20px; */
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 28px */
  letter-spacing: -0.3px;
}

.whit-color {
  color: #fff;
}

.potraits-sub-txt-adjusted {
  color: #000;

  /* Display xs/Medium */
  font-family: "Urbanist";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 28.8px */
  margin-top: 8px;
}

.potraits-graph-tab {
  margin-left: -20px;
  padding: 2;
  background: "#F7F7F7";
  border-radius: 16;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  width: 60%;
  background-color: #e4e4e7;
  border-radius: var(--Radius-radius-lg, 16px);

  color: var(--Base-Black, #000);
  text-align: center;

  /* Text m/Regular */
  /* color: var(--Base-White, #fff); */
  text-align: center;

  color: var(--Base-Black, #000);
  text-align: center;

  /* Text m/Regular */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
}

.active-graph-tab {
  border-radius: 14px;
  border: 1px solid var(--gray-light-hover, #e4e4e7);
  background: var(--Base-White, #4674fe);
  width: 50%;
  height: 0;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #fff;
  /* Tab-Button/Shadow (2) */
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.in-active-graph-tab {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.des-graph-hero-cntr {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.des-graph-inside-cntr {
  display: flex;
  gap: 8px;
  align-items: center;
}

.des-graph-percent {
  color: var(--Black, #000);
  text-align: center;
  font-family: "Urbanist";
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  /* 133.333% */
  letter-spacing: -0.18px;
}

.des-graph-sub {
  color: var(--Gray, #7e7f83);
  text-align: center;
  font-family: "Urbanist";
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  /* 133.333% */
  letter-spacing: -0.18px;
}

.potraits-common-head-bottom-primary-text {
  color: rgba(0, 0, 0, 0.8);

  /* Text m/Regular */
  font-family: "Urbanist";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
  text-align: center;
}

.potraits-common-head-bottom-primary-head {
  color: var(--Black, #000);
  text-align: center;
  font-family: "Urbanist";
  font-size: 19.697px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.262px;
  /* 133.333% */
  letter-spacing: -0.295px;
}

.bullet-line-container {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.conflict-management-primary {
  color: var(--Black, #000);
  text-align: center;
  font-family: Urbanist;
  font-size: 19.697px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.262px;
  /* 133.333% */
  letter-spacing: -0.295px;
  margin-top: 36px;
  margin-bottom: 8px;
}

.conflict-management-primary-para {
  color: rgba(19, 19, 22, 0.8);

  /* Text m/Regular */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
  margin-bottom: 24px;
}

.conflict-management-primary-heading {
  color: #000;
  text-align: center;

  /* Text xl/Semibold */
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.3px;
}

.portrait-warning {
  background-color: var(--Yellow-Normal, #F4C908);
  padding: 6px 6px 6px 6px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.portrait-tick {
  background-color: var(--Greek-Blue-Light, #E7EFF7);
  padding: 6px 6px 6px 6px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

/* Media */
@media (max-width: 900px) {
  .potraits-head-txt {
    margin-top: 30px;
  }

  .potraits-header-txt {
    margin-top: 20px;
  }

  .potraits-tab-header-txt {
    align-self: flex-start;
    margin-left: 12px;
    margin-top: 0;
  }

  .top-strength-list-cntr {
    flex-direction: column;
  }

  .personality-other-data-container {
    width: 90%;
  }

  .gradient-background-2 {
    display: none;
  }

  .potraits-graph-summary-cntr {
    width: 100%;
  }

  .combined-gradient-background {
    display: block;
    height: 30vh;
    /* Full screen height or adjust as needed */
    /* background: linear-gradient(168deg, #4675fe 0%, #05d6b6 100%); */
    background: conic-gradient(from 178deg at 50% 50%,
        #05d6b6 0deg,
        #4675fe 360deg);

    filter: blur(50px);

    background-blend-mode: overlay;
    width: 100%;
    /* height: 14dvh; */
    padding: 0px;
    text-align: center;
    margin-bottom: 0px;
    position: absolute;
    top: 0;
  }

  .combined-gradient-background-2 {
    display: block;
    height: 30vh;
    /* Full screen height or adjust as needed */
    /* background: linear-gradient(168deg, #4675fe 0%, #05d6b6 100%); */
    background: conic-gradient(from 187deg at 50% 50%,
        #82e3cc 0deg,
        #0d5eaf 360deg);

    filter: blur(150px);

    background-blend-mode: overlay;
    width: 100%;
    /* height: 14dvh; */
    padding: 0px;
    text-align: center;
    margin-bottom: 0px;
    position: absolute;
    top: 0;
  }

  .combined-gradient-background-3 {
    display: block;
    height: 30vh;
    /* Full screen height or adjust as needed */
    background: conic-gradient(from 187deg at 50% 50%,
        #00ffd8 0deg,
        #0041ff 360deg);

    filter: blur(150px);

    background-blend-mode: overlay;
    width: 100%;
    /* height: 14dvh; */
    padding: 0px;
    text-align: center;
    margin-bottom: 0px;
    position: absolute;
    top: 0;
  }

  .overview-text-container {
    width: 65%;
  }

  .combined-gradient-background-4 {
    display: block;
    height: 30vh;
    /* Full screen height or adjust as needed */
    background: conic-gradient(from 178deg at 50% 50%,
        #05d6b6 0deg,
        #4675fe 360deg);

    filter: blur(50px);

    background-blend-mode: overlay;
    width: 100%;
    /* height: 14dvh; */
    padding: 0px;
    text-align: center;
    margin-bottom: 0px;
    position: absolute;
    top: 0;
  }

  .potraits-body-merger {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    /* padding-top: 20px; */
    width: 100%;
  }

  .potraits-header-hero-cntr {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .potraits-header-txt {
    color: var(--Base-White, #fff);

    /* Display m/Medium */
    font-family: "Urbanist";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 38.4px */
    letter-spacing: -0.64px;
  }

  .potraits-container {
    margin-top: 13dvh;
  }

  .potrait-cntr-container {
    align-items: flex-start;
    padding: 8px;
  }

  .potraits-head-txt {
    color: #fff;
    text-align: left;
    padding-left: 8px !important;
    /* margin-top: 10px; */
  }

  .potraits-tab-section {
    /* background-color: #000; */
    flex-wrap: nowrap;
    max-width: 95%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    overflow-x: auto;
    /* Enable horizontal scrolling */
    overflow-y: hidden;
    /* Prevent vertical scrolling */
    white-space: nowrap;
    /* Prevent items from wrapping */
    -webkit-overflow-scrolling: touch;
    /* Enable smooth scrolling on touch devices */
    scrollbar-width: none;
    /* Hide scrollbar in Firefox */
    z-index: 1999;
    /* background-color: #000; */
    padding: 24px 1px;
  }

  .potraits-tab-section::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar in WebKit-based browsers */
  }

  .contact-relationship-modal-category-cntr {
    /* display: none; */
  }

  .personality-graph-txt-emiter {
    width: 80%;
  }
}

@media (max-width: 1024px) {
  .potrait-cntr-container {
    width: 100%;
    height: auto;
  }

  /* .potraits-head-txt {
    width: 90%;
  } */
  .top-strength-hero-container {
    margin-left: 1.5rem;
  }

  .video-tmp-personality {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .potraits-explanation-cntr-2 {
    flex-direction: column;
    gap: 24px;
    box-sizing: border-box;
  }

  .potraits-explanation-cntr {
    flex-direction: column;
    gap: 24px;
  }

  .potraits-bordered-container {
    width: 94%;
  }

  .personality-graph-txt {
    width: 82%;
  }

  .potraits-percent-summary-hero-cntr {
    flex-direction: column;
  }

  .potraits-graph-summary-txt-cntr {
    align-items: flex-start;
  }

  .potraits-sub-txt {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .personality-graph-txt-emiter {
    width: 60%;
  }

  .personality-graph-txt-emiter_story {
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1050px) and (min-height: 1300px) and (max-height: 1366px) {
  .potrait-cntr-container {
    width: 100%;
    height: auto;
  }
}

.overview-button-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.mobile-only-button {
  display: none;
}

@media screen and (max-width: 600px) {
  .mobile-only-button {
    color: var(--Base-White, #fff);
    font-feature-settings: "liga" off, "clig" off;

    /* Text lg/Medium */
    font-family: "Urbanist";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: -0.27px;
    border-radius: 100px;
    background: var(--Greek-Blue-Normal, #0d5eaf);
    display: flex;
    width: 339px;
    max-height: 52px;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
    margin-top: 15px;
  }

  .potraits-graph-tab {
    width: 80%;
  }

  .pot-head {
    margin-bottom: 10px !important;
  }
}

@media (min-width: 400px) {
  .personality-graph-txt {
    width: 80% !important;
  }
}

@media (max-width: 400px) {
  .personality-graph-txt {
    width: 90%;
  }
}

@media (max-width: 450px) {
  .personality-graph-txt-emiter {
    width: 100%;
    border: none;
  }

  .potraits-graph-summary-cntr {
    border: none;
  }

  .potraits-graph-decision-making {
    border: none;
  }
}