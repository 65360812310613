.add-relationship-para{
    color: var(--Gray, #7E7F83);

/* Text m/Regular */
font-family: Urbanist;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
margin-bottom: 16px;
}

.add-person-btn{
    border-radius: 56px;
background: var(--Light-Blue, #E7F3FF);
display: flex;
width: 343px;
height: 52px;
padding: 14px 24px;
justify-content: center;
align-items: center;
gap: 8px;
border: none;
color: var(--Greek-Blue, #0D5EAF);
font-feature-settings: 'liga' off, 'clig' off;

/* Text lg/Medium */
font-family: Urbanist;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
letter-spacing: -0.27px;
}


.emptystate-pendingrelationship{
    color: #000;

/* Text m/Regular */
font-family: Urbanist;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
margin: 0px;
}

.empty-pending-requests-para{
    color: var(--Greek-Blue-Normal, #0D5EAF);
text-align: right;

/* Text s/Medium */
font-family: Urbanist;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
cursor: pointer;
}

.empty-pending-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 24px;
    border-radius: 24px;
    background: white;
    max-width: 800px;
  }
  
  
  
  .no-requests-content {
    max-width: 600px;
    width: 100%;
  }
  

  .emptystate-loader-container {
    position: fixed; /* Ensures the loader stays in the center even if the page scrolls */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centers the loader perfectly */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; /* Ensures it spans the entire screen */
    
    z-index: 1000; /* Keeps it above other elements */
  }
  