.error-screen-bg {
  background: linear-gradient(168.14deg, #4675fe 6.98%, #05d6b6 102.55%);
  height: 100dvh;
}

.err-screen-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.err-in-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  height: 100vh; /* Full screen height */
  width: 100dvw;
}

.err-icon-class {
  width: 60%;
}

.err-icon-container {
  height: 80px;
  width: 80px;
  background-color: aliceblue;
  border-radius: 150px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.err-icon-h {
  position: relative;
  height: 18dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.err-icon {
  height: 33dvh;
}

.error-head {
  color: var(--Base-White, #fff);
  text-align: center;

  /* Display xs/Semibold */
  font-family: Urbanist;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.error-sub {
  color: var(--gray-light-hover, #e4e4e7);
  text-align: center;

  /* Text m/Regular */
  font-family: Urbanist;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.err-txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.stroke-1-err {
  position: absolute;
  width: 6dvw;
  height: 331.886px;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: var(--White, #fff);
  opacity: 0.9;
  z-index: 10;
  animation: pulse 8s infinite;
}

.stroke-2-err {
  position: absolute;

  width: 9dvw;
  height: 458.868px;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: var(--White, #fff);
  opacity: 0.8;
  animation: pulse 8s infinite;
}
.stroke-3-err {
  position: absolute;

  width: 12dvw;
  height: 458.868px;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: var(--White, #fff);
  opacity: 0.8;
  animation: pulse 8s infinite;
}
.stroke-4-err {
  position: absolute;

  width: 15dvw;
  height: 458.868px;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: var(--White, #fff);
  opacity: 0.8;
  animation: pulse 8s infinite;
}
