.termsofuse-last-subtext {
  color: #000;
  text-align: center;

  /* Text xl/Medium */
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  letter-spacing: -0.3px;
}

.notification-set-hero-container-settings {
  position: absolute;
  top: 60px;
  right: 90px;
  width: 374px;
  height: 50dvh;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  margin: 0 auto;
  border-radius: var(--Radius-radius-2xl, 24px);
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  max-height: 50dvh;
}

.notification-set-accord-settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  gap: 16px;
}

.notification-set-accord-title-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.not-set-head {
  color: #000;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;

  /* Text lg/Medium */
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.27px;
}

.not-set-sub {
  text-wrap: wrap;
  color: var(--Gray-Dark, #5f5f62);

  /* Text s/Regular */
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}

.close-x-icon-settings {
  border: 1px solid transparent;
  border-radius: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
}

.toggle-wrapper {
  margin-right: 0 !important;
}
.css-19opgx6-MuiFormControlLabel-root {
  margin-right: 0 !important;
}

.settings-mobile {
  display: none;
}

.settings-phone {
  /* background: var(--White, #fff); */
  flex-grow: 1;
  padding: 16px;
}
.alg-left {
  text-align: left;
}

.termsofuse-header {
  z-index: 10;
}
@media screen and (max-width: 760px) {
  .notification-set-hero-container-settings {
    width: 50%;
  }
}

/* Mobile Responsive Styles */
@media screen and (max-width: 488px) {
  .settings-mobile {
    display: flex;
    flex-direction: column;
    width: 100dvw;
    position: fixed;
    height: 100dvh !important;
    min-height: 100dvh !important;
    top: 0 !important;
    left: 0;
    border-radius: 0;
    border: none;
    z-index: 1000;
    background-color: #fff;
  }

  .notification-set-hero-container-settings {
    display: none;
  }

  .notification-set-accord-settings {
    padding: 16px 0;
  }
}
