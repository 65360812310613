.dropdown-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  font-family: "Urbanist";
  z-index: 10;
}

.dropdown-container.isFullWidth {
  max-width: none;
  width: 100%;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 4px;
  background: white;
  border: 1px solid #e4e4e7;
  border-radius: 8px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  /* Increased height */
  max-height: 500px;
  min-height: 350px;
  overflow-y: auto;
  /* Make sure it's displayed properly as an overlay */
  display: block;
  /* Ensure it's on top of other content */
  z-index: 99999;
  width: 100%;
}

.dropdown-menu.onboarding {
  max-height: 450px;
  min-height: 300px;
}

.dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--Spacing-spacing-xl, 20px) 20px;
  border: 1px solid var(--Gray-200, #e4e4e7);
  border-radius: 8px;
  background: white;
  cursor: pointer;
  color: var(--Gray, #7e7f83);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  font-family: "Urbanist";
}

.dropdown-header.onboarding {
  padding: 20px;
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #e4e4e7);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-top: 6px;
  color: #667085;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.dropdown-header.onboarding.is-open {
  border: 1px solid rgba(13, 94, 175, 0.4);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
    0px 0px 0px 4px rgba(13, 94, 175, 0.07);
}

.header-search-wrapper {
  flex: 1;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.header-search-input {
  width: 100%;
  padding-right: 28px; /* Make room for the clear button */
  border: none;
  outline: none;
  font-size: 14px;
  background: transparent;
  font-family: "Urbanist";
  color: var(--Gray, #7e7f83);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.dropdown-items {
  padding: 8px 0;
  background: white;
  /* Ensure this takes up the full height */
  height: 100%;
}

.dropdown-item {
  position: relative;
  background: white;
  z-index: 10000;
}

.item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.item-content:hover {
  background-color: #f4f4f5;
}

.item-content.selected {
  background-color: #f4f4f5;
  font-weight: bold;
  color: #000;
}

/* Highlight items that match the search term */
.item-content.search-match {
  background-color: #edf2f7;
  font-weight: 600;
}

.item-content.search-match:hover {
  background-color: #e2e8f0;
}

.chevron {
  width: 16px;
  height: 16px;
  transition: transform 0.2s;
}

.chevron.chevexpanded {
  transform: rotate(180deg);
}

/* Scrollbar styles */
.dropdown-menu::-webkit-scrollbar {
  width: 8px;
}

.dropdown-menu::-webkit-scrollbar-track {
  background: #f4f4f5;
  border-radius: 4px;
}

.dropdown-menu::-webkit-scrollbar-thumb {
  background: #a1a1aa;
  border-radius: 4px;
}

.dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #71717a;
}

.header-search-input .font-bold {
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.clear-selection-btn {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #666;
  transition: color 0.2s;
}

.clear-selection-btn:hover {
  color: #333;
}
