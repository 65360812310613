/* General styles */

.manage-wrapper {
  max-width: 800px;
  margin: 0 auto;
  background: white;
  border-radius: var(--Radius-radius-2xl, 24px);
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 16px;
  margin-bottom: 24px;
  z-index: 1;
}

.manage-title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 24px;
}

.manage-search-container {
  background: white;
  border-radius: 24px;
  padding: 16px;
  padding-bottom: 0px;
  /* margin-bottom: 24px; */
}

.manage-search-box {
  position: relative;
  margin-bottom: 16px;
}

.manage-search-input {
  width: 100%;
  box-sizing: border-box;
  padding: 8px 8px 8px 36px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
}

.manage-search-input:focus {
  outline: none;
}

.manage-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #9e9e9e;
}

.manage-category-button {
  border-radius: 100px;
  padding: 4px 12px;
  border: 1px solid var(--Gray-200, #e4e4e7);
  background: var(--Gray-100, #f7f7f7);
  cursor: pointer;
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
}

.manage-category-button:hover {
  background: rgb(113, 147, 252);
  color: rgba(255, 255, 255, 1);
  border: solid rgb(113, 147, 252);
}

.manage-category-button.selected {
  background: rgba(70, 116, 254, 1);
  color: rgba(255, 255, 255, 1);
  border: solid rgba(70, 116, 254, 1);
}

.manage-contacts-container {
  background: white;
  border-radius: 8px;
  padding: 16px;
}

.manage-contacts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.manage-header-title {
  font-weight: 500;
}

.manage-contacts-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

@media (min-width: 768px) {
  .manage-contacts-grid {
    grid-template-columns: 1fr 1fr;
  }
}

/* ContactCard styles */
.manage-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: var(--Radius-radius-sm, 10px);
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.manage-card:hover {
  background: #f5f5f5;
}

.manage-card-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.manage-avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #005f6d;
  background: linear-gradient(45deg, #9eecff 23%, #a0ffea);
  margin-right: 12px;
  overflow: hidden;
}

.manage-avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.manage-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.manage-name {
  font-weight: 500;
}

.manage-type {
  font-size: 14px;
  color: #757575;
}

.manage-more-button {
  color: #9e9e9e;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
}

.manage-more-button:hover {
  color: #616161;
}

/* Modal styles */
.manage-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
}

.manage-category-list {
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding-bottom: 8px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  max-width: 100%; /* Ensure it doesn't take full width if items exceed */
  white-space: nowrap; /* Prevent items from wrapping */
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari) */
.manage-category-list::-webkit-scrollbar {
  display: none;
}

.manage-modal {
  background: white;
  border-radius: 20px;
  width: 60%;
  max-width: 476px;
  max-height: 80%;
  min-height: 80%;
  overflow: hidden; /* Remove scrolling from parent */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.adviceheight {
  min-height: 80% !important;
}

.manage-modal-card {
  background: white;
  border-radius: 20px;
  max-width: 400px;
  width: 390px;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  scrollbar-width: thin;
  /* overflow: hidden; */
  margin: 10px;
}

.manage-modal::-webkit-scrollbar {
  display: none; /* Hides scrollbar in WebKit browsers (Chrome, Safari) */
}

.manage-modal {
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}

.manage-modal-header {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.manage-close-button {
  margin-left: auto;
  background: none;
  border: none;
  color: #9e9e9e;
  cursor: pointer;
  padding: 4px;
}

.manage-close-button:hover {
  color: #616161;
}

.manage-modal-content {
  padding: 8px;
}

.manage-option-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #f5f5f5;
  text-align: left;
  font-family: "Urbanist", sans-serif;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.27px;
  padding: 10px;
  margin-bottom: 8px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.manage-option-button:hover {
  background: #d4d4d4;
}

.manage-rightarrowicon {
  width: 15px;
  height: 15px;
  padding: 5px;
  background-color: white;
  border-radius: 50%;
}

.manage-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
  font-size: 18px;
  color: #333; /* Adjust color to match your theme */
}

.request-count-button {
  background: inherit;
  border: none;
  color: #1976d2;
  font-size: 14px;
  font-family: "Urbanist", sans-serif;
  font-style: normal;
  cursor: pointer;
}

.pending-relationships-container {
  width: 100%;
  padding: 20px;
}

.pending-relationships-content {
  max-width: 1200px;
  margin: 0 auto;
}

.pending-header {
  margin-bottom: 24px;
}

.header-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #374151;
}

.pending-requests-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

.delete-notification {
  width: 300px;
  position: fixed;
  bottom: 0px;

  background-color: #f44336; /* Red background */
  color: var(--Base-Black, #000);
  /* Text s/Regular */
  font-family: "Urbanist", sans-serif;

  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1000;
  animation: fadein 0.3s ease, fadeout 0.3s ease 4.7s;
  border-radius: var(--Radius-radius-xs, 8px);
  background: var(--Turquoise-Normal, #04d9b5);
}

.delete-notification svg {
  width: 20px;
  height: 20px;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

@media (min-width: 768px) {
  .pending-requests-list {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 460px) {
  .manage-wrapper {
    padding: 0px;
  }
}

.manage-contacts-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

@media (max-width: 720px) {
  .manage-modal {
    width: 70%;
  }
}

@media (max-width: 580px) {
  .manage-modal {
    width: 90%;
  }
}


@media(max-width: 414px)
{
  .manage-overlay{
    align-items: flex-end;
  }

  .manage-modal-card{
    margin: 0px;
    border-radius: 20px 20px 0px 0px;
  }

  .edit-container
  {
    border-radius: 24px 24px 0px 0px;
  }

  
}