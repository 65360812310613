.calendar-container {
    border: 1px solid #F2F4F7;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    overflow: hidden;
    padding: 1rem;
  }
  
  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 0.5rem;
  }
  
  .calendar-header button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 5px 10px;
  }
  
  .calendar-header span {
    font-size: 16px;
    font-weight: 600;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    padding: 10px;
  }
  
  .calendar-day,
  .calendar-day-name {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 14px;
  }
  
  .calendar-day {
    cursor: pointer;
    border-radius: 50%;
  }
  
  .calendar-day.empty {
    background-color: transparent;
    cursor: default;
  }
  
  .calendar-day:hover {
    background-color: #eee;
  }
  
  .calendar-day.selected {
    border: none;
    background-color: #0D5EAF;
    color: #fff;
  }
  
  .selected-date-container {
    padding: 8px 14px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
  }
  
  .calendar-day.disabled {
    pointer-events: none;
    opacity: 0.5;
  }