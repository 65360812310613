.termsofuse-action {
  color: #000;
  text-align: center;

  /* Text xl/Medium */
  font-family: "Urbanist";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  letter-spacing: -0.3px;
}

.settings-hero-container {
  width: 80%;
  margin: 18px auto;
  position: relative;
  display: flex;
  justify-content: center;
}

.set-acc-container {
  width: 100%;
  max-width: 444px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
}
.set-accordian-menu {
  display: flex;
  padding: var(--Spacing-spacing-xl, 20px) var(--Spacing-spacing-lg, 16px);
  align-items: center;
  justify-content: space-between;

  align-self: stretch;

  border-radius: var(--Radius-radius-md, 12px);
  background: var(--Gray-Light, #f7f7f7);
  cursor: pointer;
}
.set-left-section {
  display: flex;
  gap: 16px;
}
.set-name {
  color: var(--Base-Black, #000);
  font-feature-settings: "liga" off, "clig" off;

  /* Text lg/Medium */
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.27px;
}

.set-right-menu {
  display: flex;
  padding: var(--Spacing-spacing-xs, 8px);
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--Spacing-spacing-none, 0px);
  border-radius: var(--Radius-radius-full, 999px);
  background: var(--Base-White, #fff);
}

.set-phone-modal-head {
  color: var(--Black, #000);

  /* Display xs/Semibold */
  font-family: Urbanist;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  margin-bottom: 8px;
}

.set-phone-modal-support {
  color: var(--Gray, #7e7f83);

  /* Text m/Medium */
  font-family: Urbanist;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}

.set-phone-modal-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 20px;
}

.set-phone-input-label {
  color: var(--Base-Black, #000);

  /* Text s/Medium */
  font-family: Urbanist;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  margin-bottom: 8px;
}

.mobile-input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid rgba(13, 94, 175, 0.4);
  border-radius: 8px;
  padding: 8px 12px;
  background-color: #fff;
  width: 95%; /* Adjust width as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mobile-input-wrapper-1 {
  display: flex;
  align-items: center;
  border: 1px solid #d0d5dd;
  border-radius: 12px;
  padding: 1px 12px;
  background-color: #fff;
  width: 95%; /* Adjust width as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mobile-input {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 14px;
  color: #555;
}

.mobile-input::placeholder {
  color: #aaa;
}

.mobile-input .PhoneInput {
  width: 100%;
}

.mobile-input .PhoneInputInput {
  border: none;
  background: transparent;
  padding: 0;
  width: 100%;
}

.mobile-input .PhoneInputCountry {
  margin-right: 8px;
}

.edit-button {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 4px 8px;
  font-size: 0.9rem;
  color: #555;
  cursor: pointer;
  transition: background-color 0.2s;
}

.edit-button:hover {
  background-color: #e9e9e9;
}

.edit-button span {
  margin-right: 4px;
}

.set-phone-alert-txt {
  color: var(--Red-Normal, #f04438);

  /* Text s/Medium */
  font-family: Urbanist;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
}

.set-phone-int-cnt {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.set-phone-continue-button {
  display: flex;
  max-height: 52px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 90%;

  border-radius: 100px;
  background: var(--Greek-Blue-Normal, #0d5eaf);
  color: var(--Base-White, #fff);

  font-feature-settings: "liga" off, "clig" off;

  /* Text lg/Medium */
  font-family: Urbanist;
  font-size: 1.12rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.27px;

  border: none;
  cursor: pointer;
}

.set-phone-continue-button-disabled {
  background: var(--Greek-Blue-Light, #e7eff7);
  color: var(--greek-blue-light-active, #b4cde6);
}

.otp-verifier {
  width: 80%;
  margin: 0 auto;
}

.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 20px 0;
}

.otp-box {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  border: 2px solid #ccc;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.otp-box:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 8px rgba(74, 144, 226, 0.5);
}

.otp-box::placeholder {
  color: #ddd;
  font-size: 18px;
}

.otp-container input[type="text"] {
  caret-color: transparent; /* Hide the blinking caret */
}

.otp-rote {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.otp-typo-cnt {
  display: flex;
  align-items: center;
}
.otp-get-code {
  color: var(--Gray, #7e7f83);

  /* Text s/Regular */
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.otp-resend {
  color: var(--Gray-Dark, #5f5f62);

  /* Text s/Medium */
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
}

.set-phone-cancel-button {
  display: flex;
  max-height: 52px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* flex: 1 0 0; */
  width: 40%;

  border-radius: 100px;
  border: 1px solid var(--gray-light-hover, #e4e4e7);
  background: var(--Base-White, #fff);

  color: var(--Gray-Darker, #2c2c2e);
  font-feature-settings: "liga" off, "clig" off;

  /* Text lg/Medium */
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.27px;
}

.wider {
  width: 90%;
}

.set-phone-remove-button {
  display: flex;
  max-height: 52px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* flex: 1 0 0; */
  width: 40%;
  border-radius: 100px;
  background: var(--Red-Normal, #f04438);
  border: none;

  color: var(--Base-White, #fff);
  font-feature-settings: "liga" off, "clig" off;

  /* Text lg/Medium */
  font-family: "Urbanist";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.27px;
}

.email-settings-success-box {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #04d9b5;
  padding: 12px 16px;
  border-radius: 4px;
  margin-top: 2rem;
  color: black;
  font-size: 12px;
  width: 50%;
  justify-content: center;
  position: relative;
  margin: 16px auto 0;
}

.email-settings-success-box img {
  width: 16px;
  height: 16px;
}

@media screen and (max-width: 480px) {
  .termsofuse-action {
    text-align: left;
    padding: 0 1rem;
  }
  .settings-hero-container {
    width: 100%;
    padding: 1rem;
    margin-top: 25px;
    display: block;
  }

  .set-acc-container {
    width: 100%;
  }
}
