.input-label {
    color: var(--Gray-500, #667085);

    /* Text m/Regular */
    font-family: "Urbanist",sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
}


.signup-input:focus {
    border: 1px solid rgba(13, 94, 175, 0.40);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(13, 94, 175, 0.07);
 
    
    
    
}
