.ass-encouragment-hero-container {
  background: linear-gradient(168deg, #4675fe 6.98%, #05d6b6 102.55%);
  min-height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.flex-cntr {
  display: flex;
  align-items: center;
  justify-content: center;
}

.assess-container-encouragement {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 90dvh;
  padding: 20px 30px 20px 30px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 24px;
  background: var(--White, #fff);
  /* overflow-y: auto; */
  /* background: conic-gradient(
      from 130deg at -4.63% 74.02%,
      #05d6b6 0deg,
      #4675fe 360deg
    ),
    linear-gradient(to right, #1afafa, #010db3),
    linear-gradient(to bottom, #98cdf9 0%, #ffffff 100%); */
  background-blend-mode: overlay;
}

.assess-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.entity-class {
  width: 20px;
  height: 20px;
}

.head-cont {
  color: var(--Black, #000);
  text-align: center;

  /* Display xl/Semibold */
  font-family: Urbanist;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 48px */
  width: 100%;
  overflow-x: hidden;
}

.subTxt {
  color: var(--Gray-Normal, #7e7f83);
  text-align: center;

  /* Text lg/Regular */
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 155.556% */
  letter-spacing: -0.27px;
  width: 80%;
  text-overflow: clip;
}

.btn-cntr {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 12px;
}

.btn-support {
  color: var(--Greek-Blue-Normal, #0d5eaf);

  /* Text m/Medium */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
}

.btn-tmp-cnt {
  border-radius: 100px;
  background: var(--Greek-Blue-Normal, #0d5eaf);
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.overview-container-hero-eng {
  position: relative;
}

.po-ob {
  position: absolute;
}

.stroke-1-ov-eg {
  position: absolute;
  width: 150.596px;
  height: 280.886px;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: var(--White, #fff);
  opacity: 0.9;
  z-index: 10;
}

.stroke-2-ov-eg {
  position: absolute;

  width: 210.263px;
  height: 400.868px;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: var(--White, #fff);
  opacity: 0.8;
}

.overview-container-hero-eng {
  width: 100%;
  /* margin-top: 5px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 43dvh;
}

@media (max-width: 480px) {
  .overview-image {
    width: 100px;
    height: 100px;
  }

  .stroke-1-ov-eg {
    width: 141px;
    height: 163px;
    stroke: var(--Turquoise, #04d9b5) !important;
  }

  .stroke-2-ov-eg {
    width: 195px;
    height: 225px;
    stroke: var(--Turquoise, #04d9b5) !important;
  }

  .overview-container-hero-eng {
    height: 35dvh;
  }

  .overview-name {
    margin-bottom: 15px;
    top: 0;
  }
}

.overview-image-container-eng {
  width: 350px;
  height: 350px;
  transform: rotate(-47.426deg);
  flex-shrink: 0;

  border-radius: 440.647px;
  /* opacity: 1; 0.8; */
  background: conic-gradient(from 178deg at 50% 50%,
      #05d6b6 0deg,
      #4675fe 360deg);

  filter: blur(134px);
  position: absolute;
}

.emoji-circle {
  z-index: 100;
  /* background-color: #fff; */
  /* border-radius: 100px; */
  /* padding: 20px; */
}

@media (max-width: 600px) {
  .overview-image-container-eng {
    /* width: 250px; */
    /* height: 250px; */
    width: 80%;
    height: 80%;
  }
}

@media (min-width: 600px) {
  .overview-image-container-eng {
    filter: blur(250px);
  }
}

@media (max-width: 1124px) {

  .assess-container,
  .assess-container-encouragement {
    width: 60%;
    box-sizing: border-box;
    margin: 10px 0;
  }

  .assess-options-container-eng {
    width: 60% !important;
  }

  .acc-supressed-bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .assess-header {
    /* display: none; */
  }

  .ass-pointer {
    color: white;
  }
}

@media (max-width: 600px) {

  .assess-container,
  .assess-container-encouragement {
    width: 90% !important;
    box-sizing: border-box;
    margin: 10px 0;
  }

  .assess-options-container-eng {
    width: 90% !important;
    top: 60vh !important;
  }
}

@media (min-width: 1024px) and (max-width: 1050px) and (min-height: 1300px) and (max-height: 1366px) {

  .assess-container,
  .assess-container-encouragement {
    width: 90% !important;
    box-sizing: border-box;
    margin: 10px 0;
    /* background-color: #274079 !important; */
  }

  .assess-options-container-eng {
    width: 90% !important;
  }
}

.ass-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 4vh; */
}

.ass-content-container-attacher {
  gap: 12px;
}

.assess-options-container-eng {
  position: absolute;
  top: 52vh;
  transform: translateY(-50%);
  z-index: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 33%;
}

.flex-cntr .first-circle {
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 50%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-cntr .second-circle {
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  padding: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-cntr .third-circle {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-cntr .fourth-circle {
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  padding: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.out-assess-header {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

@media (min-width: 1026px) {
  .out-assess-header {
    display: none;
  }
}