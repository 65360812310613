/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* High z-index to overlay other content */
  backdrop-filter: blur(10px); /* Apply the blur effect */
}

.chat-modal-content {
  background-color: #fff;
  /* padding: 20px; */
  border-radius: 24px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  /* max-width: 90vw; Ensures responsiveness
   */
  width: 476px;
  max-height: 90vh; /* Ensures responsiveness */
  margin: 10px;
  scrollbar-width: none;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  text-align: left;
  /* padding: 20px; */
  /* text-align: center; */
}

@media screen and (max-width: 580px) {
  .insight-modal-container {
    width: 90% !important;
  }
}

/* @media screen and (max-width: 1200px) {
  .chat-modal-content {
    width: 50% !important;
  }
} */

/* @media screen and (max-width: 768px) {
  .chat-modal-content {
    width: 85% !important;
  }
} */
