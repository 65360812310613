.save-progress-hero-container {
  background: radial-gradient(circle at 30% 60%, #34d3b5 7%, transparent 30%),
    radial-gradient(circle at 70% 5%, #34d3b5 5%, transparent 30%),
    linear-gradient(to bottom right, #4377fc, #4377fc);

  min-height: 100dvh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Chatpage-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* #blueBackground {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  background-color: #4377fc;
  z-index: -1;

} */

.blueBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure that the SVG stays behind the content */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.save-progress-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 38%;
  gap: 25px;
  /* z-index: 9999; */
  visibility: visible; /* Ensure it's visible */
  opacity: 1; /* Ensure it's not hidden */
}

.save-progress-img {
  width: 240px;
  height: 240px;
}

.save-progress-txt {
  color: var(--White, #fff);
  text-align: center;

  font-family: Urbanist;
  font-size: 35px;
  font-style: normal;
  font-weight: 550;
  line-height: 120%;
}

.save-progress-mini-txt {
  color: var(--Gray-Light, #f7f7f7);

  /* Text m/Medium */
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  cursor: pointer;
}

.save-progress-support-txt {
  color: var(--White, #fff);
  text-align: center;

  /* Text lg/Regular */
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.27px;
}

.save-progress-support-txt-2 {
  color: var(--White, #fff);
  text-align: center;

  /* Text lg/Regular */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.27px;
  margin-bottom: 4px;
}
@media (max-width: 1024px) {
  .save-progress-content {
    width: 90%;
    box-sizing: border-box;
    margin: 10px 0;
  }
}

@media (min-width: 1024px) and (max-width: 1050px) and (min-height: 1300px) and (max-height: 1366px) {
  .save-progress-content {
    width: 90% !important;
    box-sizing: border-box;
    margin: 10px 0;
    /* background-color: #274079 !important; */
  }
}
