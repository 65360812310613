/* style.css */
.membershipmodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(2px);
  }
  
  .membershipmodal-container {
    background: #fff;
    width: 476px;
    border-radius: 24px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
 
  }
  
  .membershipmodal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .membershipmodal-heading {
    color: var(--Black, #000);

    /* Display xs/Medium */
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 28.8px */
    margin: 0px;
    
  }

  
  .membershipclose-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  
  .membershipmodal-body {
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    text-align:left;
    border-radius:24px;
    padding:16px;
border:2px solid var(--Gray-Normal,rgb(233, 231, 231));
box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
flex-direction: column;
align-items: flex-start;
gap: var(--Spacing-spacing-lg, 16px);
align-self: stretch;

  }
  
  .membershipdescription {
color: #000;
font-feature-settings: 'liga' off, 'clig' off;

/* Text lg/Medium */
font-family: Urbanist;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
letter-spacing: -0.27px;
  }
  
  .member-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .membershipcounter-btn {
    background-color: #f0f0f0;
    border: none;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .membershipmember-count {
    font-size: 16px;
    font-weight: bold;
  }
  
  .membershipprice-info {
    color: #333;
    font-size: 14px;
  }
  

  
  .membershiptotal-price {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .membershippurchase-btn {
    width: 100%;
    color: var(--Base-White, #FFF);
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    display: flex;
padding: 16px 28px;
justify-content: center;
align-items: center;
gap: 12px;
align-self: stretch;
border-radius: 100px;
border: 1px solid var(--Greek-Blue-Normal, #0D5EAF);
background: var(--Greek-Blue-Normal, #0D5EAF);
  }

  .membership-count-container{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
    width: 100%;
  }

.dot{
  content:"•";
font: 1.5rem sans-serif;
  color: var(--Crayola-Blue, #4674fe);
}


.membershipmodal-list{
  display: flex;
  gap:10px;
  align-items: flex-start;

}
  

.membershipmodal-list-txt{
  color: var(--Gray-Normal, #7E7F83);

  /* Text m/Medium */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */

}

.membership-price{
  color: var(--Black, #000);
font-family: Urbanist;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 120%;
letter-spacing: -0.24px;
}

.membership-price-text{
  color: var(--Gray-Normal, #7E7F83);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.14px;
}

.membership-total-price-txt{
  color: var(--Gray-Normal, #7E7F83);
font-feature-settings: 'liga' off, 'clig' off;

/* Text lg/Medium */
font-family: Urbanist;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
letter-spacing: -0.27px;
}


.membership-total-price{
  color: var(--Gray-Darker, #2C2C2E);

/* Text lg/Semibold */
font-family: Urbanist;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 130%; /* 23.4px */
}


.membership-payment{
  padding:24px 0px 0px 0px;
}


.membership-btn-txt{
  color: #000;
font-feature-settings: 'liga' off, 'clig' off;

/* Text lg/Medium */
font-family: Urbanist;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
letter-spacing: -0.27px;
padding: var(--Spacing-spacing-lg, 16px);
}


.membership-delete-modal-text{
  color: var(--Gray-Normal, #7E7F83);
text-align:left;
/* Text m/Regular */
font-family: Urbanist;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
margin-top:16px;
}

.membership-delete-modal-buttons{
  display:flex;
  gap:10px;
  align-items: center;
  justify-content:center;
  margin-top:24px;
}

.membership-delete-modal-cancel-button{
  color: var(--Gray-Darker, #2C2C2E);
font-feature-settings: 'liga' off, 'clig' off;
width: 50%;
/* Text lg/Medium */
font-family: Urbanist;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
letter-spacing: -0.27px;display: flex;
max-height: 52px;
padding: 16px 20px;
justify-content: center;
align-items: center;
gap: 8px;
flex: 1 0 0;
border-radius: 100px;
border: 1px solid var(--gray-light-hover, #E4E4E7);
background: var(--Base-White, #FFF);
}

.membership-delete-modal-confirm-button{
  border-radius: 100px;
background: var(--Red-Normal, #F04438);
color: #fff;
}

@media(max-width:414px)
{
  .membershipmodal-overlay{
   align-items: flex-end;

  }

  .membershipmodal-container{
border-radius: 24px 24px 0px 0px;
  }
}

@media(max-width:414px)
{
  .membershipmodal-overlay {
    align-items: flex-end;

  }
}