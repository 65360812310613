@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600&display=swap");

.header-component-hero-container {
  box-sizing: border-box;
  padding: 1rem 2rem;
  position: absolute;
  top: 1rem;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  align-items: center;
  width: 100dvw;
  height: 4.44%;
  /* max-height: 44px !important; */
  /* background-color: aquamarine; */
}

.header-menu-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  /* padding: 12px; */
  width: 3rem;
  height: 3rem;

  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.header-menu-icon-container-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  /* padding: 12px; */
  width: 3rem;
  height: 3rem;

  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.header-notification-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 100%;
}

.circular-image {
  border-radius: 50%;
  margin-top: 3px;
  height: 2.5rem;
  width: 2.5rem;
  object-fit: cover;
  cursor: pointer;
}

.header-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.CircularProgressbarWithChildren {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-circle-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
}

.menu-hero-container {
  position: absolute;
  top: 70px;
  left: 0;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.2rem;
  width: 20.25rem;
  border-radius: 24px;
  background: #0d5eaf;
  z-index: 10;
}

.menu-cust-container {
  display: flex;
  flex-direction: column;
  gap: 1.9rem;
  width: 100%;
}

.menu-txt-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: var(--White, #fff);
  text-align: center;

  /* Text md/Medium */
  font-family: "Work Sans";
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem; /* 137.5% */
  letter-spacing: -0.24px;
}

.fixed-cnt {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 16px;
}
.header-option-close-icon {
  display: none;
}

.notification-badge {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ff0000;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;
}

.bell-icon-container {
  position: relative;
}

.pending-relationship-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: white; /* Red background for the badge */
  color: #0d5eaf; /* White text */
  font-size: 12px; /* Adjust size */
  width: 20px; /* Make it a perfect circle */
  height: 20px;
  border-radius: 50%; /* Round shape */
  /* Spacing between text and badge */
}

.header-profile-circle-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #9eecff 23%, #a0ffea);
  color: #005f6d;
  font-weight: thin;
  font-size: 1.2rem;
  border-radius: 50%;
  width: 2.6rem;
  height: 2.6rem;
  cursor: pointer;
}

.header-aris-icon {
  /* padding-left: 2rem; */
  position: absolute;
  left: 46%;
}

.logout-modal-container {
  padding: 32px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.logout-modal-header {
  color: #000;

  /* Text lg/Semibold */
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
}

.logout-modal-body {
  color: var(--Gray-700, #344054);

  /* Text m/Regular */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.logout-modal-button-container {
  display: flex;
  width: 100%;
}

.logout-cancel {
  width: 50%;
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  align-self: stretch;
  color: var(--Buttons-Default-Text, #000);

  /* Text lg/Semibold */
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
  border-right: 1px solid var(--Gray-200, #e4e4e7);
  background: var(--Buttons-Default-Base, #fff);
  box-sizing: border-box;
  border-top: 1px solid var(--Gray-200, #e4e4e7);
  cursor: pointer;
}

.logout-btn {
  color: rgba(240, 68, 56, 1);
  border-right: none;
}

.header-left-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.live-session-button-header {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  padding: 10px 12px;
  cursor: pointer;
}

.live-session-button-header.disabled {
  background-color: #f7f7f7;
  color: #b9b9b9;
}
.header-component-left-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.coaching-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  padding: 10px 12px;
  cursor: pointer;
}
.coaching-button-container .mic-icon {
  min-width: 20px;
  min-height: 20px;
}
.coaching-button-container span {
  font-size: 14px;
  font-weight: 500;
}

.coaching-button-container.disabled {
  background-color: #f7f7f7;
  color: #b9b9b9;
}

@media (max-width: 1024px) {
  .menu-hero-container {
    width: 100%;
    position: fixed;
    box-sizing: border-box;
    height: 100dvh;
    top: 0 !important;
    left: 0;
    border-radius: 0;
    border: none;
  }
  .header-option-close-icon {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
  }
  .header-aris-icon {
    display: none;
  }
  .header-component-hero-container {
    padding: 10px;
  }
}

@media (min-width: 1024px) and (max-width: 1050px) and (min-height: 1300px) and (max-height: 1366px) {
  .menu-hero-container {
    width: 100%;
    position: fixed;
    box-sizing: border-box;
    height: 100dvh;
    top: 0 !important;
    left: 0;
    border-radius: 0;
    border: none;
  }
  .header-option-close-icon {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
  }
  .header-aris-icon {
    display: none;
  }
  .header-component-hero-container {
    padding: 10px;
  }
}

@media screen and (max-width: 431px) {
  .live-session-button-header {
    display: flex;
  }
}
