.cs-hero-container {
  position: relative;
  width: 75%;
  margin: 0 auto;
  margin-bottom: 40px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border-radius: 12px;
  padding-top: 1rem;
}

.cs-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 32px;
  width: 100%;
  position: relative;
  overflow-wrap: break-word;
}

.cs-heading {
  color: var(--Base-Black, #000);

  /* Display xs/Semibold */
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.cs-logo-txt {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.cs-txt-1 {
  color: var(--Gray-600, #475467);

  /* Display xs/Medium */
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 28.8px */
}

.cs-mail-clr {
  color: var(--Crayola-Blue, #4674fe);
}

.cs-support-txt {
  color: var(--Gray-Normal, #7e7f83);

  /* Text xl/Medium */
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  letter-spacing: -0.3px;
  margin-top: 0.5rem;
}
.cs-input-container {
  width: 100%;
}
.cs-txt-area {
  width: 100%;
  height: 20dvw;
  padding: 14px 16px;
  color: var(--Gray, #7e7f83);
  font-family: Urbanist;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  border-radius: 12px;
  border: 1px solid rgba(55, 57, 61, 0.4);
  background: #fff;
  box-sizing: border-box;
  resize: none;
}

.cs-btn-cntr {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cs-submit-button {
  display: flex;
  width: 343px;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 100px;
  border: 1px solid var(--Marian-Blue, #274079);
  background: var(--Marian-Blue, #274079);
  color: var(--Base-White, #fff);
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  margin-left: 1rem;
}
.cs-assist-txt {
  color: var(--Gray-600, #475467);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-align: left;
}
.end-cls {
  height: 20px;
}

.cs-support-container {
  text-align: left;
}

.cs-divider {
  display: none;
}

.mobile-cs-support-text {
  display: none;
}

@media (max-width: 1024px) {
  .cs-hero-container {
    width: 100%;
  }
  .cs-btn-cntr {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 1024px) and (max-width: 1050px) and (min-height: 1300px) and (max-height: 1366px) {
  .cs-hero-container {
    width: 100%;
  }
}

@media screen and (max-width: 440px) {
  .cs-hero-container {
    background-color: transparent;
  }
  .cs-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
    gap: 1rem;
    width: 100%;
  }
  .divider-line {
    width: 40%;
    border: 1px solid #B9B9B9;
  }
  .divider-text {
    font-size: 18px;
    font-weight: 500;
    color: #667085;
  }
  .mobile-cs-support-text {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  .cs-support-heading {
    display: none;
  }
  .cs-support-txt {
    display: none;
  }
  .cs-submit-button {
    margin-bottom: 0;
  }
  .customer-support-title {
    margin-top: 4rem;
  }
  .cust-sup-hero {
    background-color: black;
  }
  .cs-heading, .cs-txt-1 {
    font-size: 18px;
    font-weight: 500;
  }
}