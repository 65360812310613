.new-survey-hero-container {
  background-color: #4377fc;
  min-height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  max-height: 100dvh;
  max-width: 100dvw;
  overflow: hidden;
}

.mb-16 {
  margin-bottom: 16px;
}

.bottom-bg {
  position: absolute;
  bottom: 0;
  left: 0;
}

.overlay-tech {
  /* z-index: 999; */
}

.survey-bg-mobile-mid {
  position: absolute;
  top: 75%;
  left: -90%;
  display: none;
}

.survey-top-bg-clr {
  position: absolute;
  top: 0;
  left: 48%;
}

.survey-top-bg-clr-mobile {
  display: none;
}

@media (max-width: 580px) {
  .survey-bg-mobile-mid {
    left: -90% !important;
    display: block;
  }
  .bottom-bg {
    display: none;
  }
}

@media (max-width: 780px) {
  .survey-bg-mobile-mid {
    left: -20%;
    display: block;
  }
  .bottom-bg {
    display: none;
  }
  .survey-top-bg-clr {
    display: none;
  }
  .survey-top-bg-clr-mobile {
    display: block;

    position: absolute;
    top: 0;
    left: 65%;
  }
}
