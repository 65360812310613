.first-carousel-container {
    width: 100%;
    height: 21rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.ellipse {
    width: 100%;
    display: flex;
    top: -40px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.second-carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 21rem;
    width: 100%;
}

.second-box-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 150px);
    grid-gap: 10px;
}

.second-filled-box,
.second-empty-box {
    width: 150px;
    height: 150px;
}

.second-filled-box {
    background-color: rgba(72, 226, 228, 1);
}

.second-filled2-box {
    background-color: rgba(28, 176, 246, 1);
}

.second-empty-box {
    display: flex;
    justify-content: center;
    background-color: transparent;
}


/* Container for the third section */
.third-carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    height: 20rem;
    width: 100%;
  }
  


.third-image-wrapper2{
    display: grid;
    grid-template-columns: repeat(2, 150px);
    
}


  /* Wrapper for the images and the circle */
  .third-image-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%; /* Adjust as per your layout */
    height: 100%; /* Adjust as needed */
    justify-content: space-evenly;
  }
  
  /* Circle positioned in the middle behind the images */
  .circle {
    position: absolute;
    width: 150px;
    height: 150px;
    background-color: rgba(72, 226, 228, 1);
    border-radius: 50%;
    z-index: 0; /* Push behind images */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* Image container styles */
  .third-image {
    width: 150px; /* Adjust as needed */
    height: 150px;
  
    overflow: hidden; /* Ensures image fits within container */
    z-index: 1; /* On top of the circle */
 
  }
  
  /* Empty divs for spacing */
  .third-empty {
    width: 20px;
    height: 20px;
  }
  


  /* Main container for the 4th carousel item */
.fourth-carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21rem; /* Adjust height */
    width: 100%; /* Full width container */
  }
  
  /* Wrapper to align the portrait and triangle images horizontally */
  .image-triangle-wrapper {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between portrait and triangles */
    position: relative;
  }
  
  /* Styling for the portrait image */
 
  
  .portrait-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure image fills the container */
  }
  
  /* Triangle group styles */
  .triangle-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 20%; /* Adjust as necessary */
  }
  
  /* Common triangle image styling */
  .triangle {
    width: 120px; /* Set the width for triangle images */
    height: auto; /* Maintain aspect ratio */
  }
  
  @media screen and (max-width: 365px) {

    .second-box-wrapper {
        grid-template-columns: repeat(2, 120px);
        grid-gap: 6px;
    }

    .second-filled-box,
    .second-empty-box {
        width: 120px;
        height: 120px;
    }

    .third-image-wrapper2 {
        grid-template-columns: repeat(2, 120px);
    }

    .third-image {
        width: 120px;
        height: 120px;
    }

    .circle {
        width: 90px;
        height: 90px;
    }
    .triangle {
        width: 30px;
    }
    .triangle-group {
        height: 10%;
    }
}