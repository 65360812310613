.summary-modal-container {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    cursor: default;
}
.summary-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.summary-modal-header p {
    font-size: 24px;
    font-weight: 500;
}
.summary-modal-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.summary-modal-body p {
    font-size: 18px;
    font-weight: 500;
}
.summary-modal-body li {
    padding-left: 1.5rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    list-style: none;
    position: relative;
    color: #7E7F83;
}

.summary-modal-body li::before {
    content: "•";
    font-size: 20px; /* Reduced size */
    position: absolute;
    left: 2%;
    top: 25%;
    transform: translateY(-50%);
}