.coaching-history-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
}
.coaching-history-container .coaching-history-header {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.coaching-history-main-section {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    min-height: 0;
    overflow: auto;
}
.coaching-history-main-section-title {
    font-size: 24px;
    font-weight: 500;
}
.coaching-history-filter-button-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.coaching-history-filter-button-item {
    border: 1px solid #E4E4E7;
    background-color: #FFFFFF;
    padding: 4px 12px;
    border-radius: 100px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}
.coaching-history-filter-button-item.active-filter {
    background-color: #4674FE;
    border: 1px solid #406AE7;
    color: #FFFFFF;
}
.coaching-history-list-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow: auto;
}
.coaching-history-list-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.coaching-history-list-item p {
    color: #7E7F83;
    font-size: 12px;
    font-weight: 600;
    cursor: default;
    text-transform: uppercase;
}
.coaching-history-list-item .coaching-history-list-heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
}
.coaching-history-footer {
    flex: 0 0 auto;
    padding-bottom: 2rem;
}
.open-your-profile-button-container {
    padding: 10px 12px;
    border-radius: 100px;
    background-color: #0D5EAF;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
}

.open-your-profile-button-container.navigate {
    opacity: 0.5;
    cursor: default;
}

.no-items-message {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    color: #888;
}

/* mobile styles */
@media screen and (max-width: 440px) {
    .coaching-history-container {
        padding: 0;
    }
    .coaching-history-main-section-title {
        text-align: center;
    }
}