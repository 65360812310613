.coach-banner {
  background-color: #4377fc;
  min-height: 100vh;
  /* max-height: 100dvh; */
  overflow-y: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.coaching-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  max-height: 84px;
}
.coaching-back-button, .coaching-options-button {
  background-color: #FFFFFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  cursor: pointer;
}
.coaching-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  position: relative;
  overflow: hidden;
  padding: 2rem 0 0 0;
}
.coaching-main-container-top {
  flex-basis: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 56px;
}
.coaching-main-container-text {
  width: 100%;
  flex-basis: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 105px;
}
.coaching-llm-text-container {
  max-width: 530px;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 28.8px;
  text-align: center;
  color: #FFFFFF;
  max-height: 57px;
  overflow-y: auto;
  scroll-behavior:smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  line-height: 2rem;
  transition: scroll-top 0.3s ease-in-out;
}
.coaching-llm-text-container::-webkit-scrollbar {
  display: none;
}
.coaching-speech-controls-container {
  flex-basis: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 35px;
  min-height: 135px;
}
.coaching-speech-controls-container.recording {
  padding-top: 60px;
}
.coaching-speech-controls {
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  gap: 0.5rem;
  z-index: 999;
  margin-bottom: 2rem;
}
.coaching-speech-controls-top{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6rem;
}
.coaching-speech-controls-bottom {
  text-align: center;
  color: #FFFFFF;
}
.coaching-speaker-container, .coaching-hands-free-container {
  background-color: rgb(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  cursor: pointer;
}
.coaching-mic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 1px;
  user-select: none;
}
.coaching-mic-container .coaching-mic {
  width: fit-content;
  background-color: #04D9B5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 18px;
  cursor: pointer;
}
.coaching-speech-controls-bottom .speak-text {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  user-select: none;
}
.coaching-speech-controls-bottom .speak-text.recording {
  color: transparent;
  background-color: transparent;
} 
.coaching-speech-controls-bottom .speak-text.handsmode {
  display: none;
}
.coaching-timeout-container {
  background-color: #FFFFFF;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  gap: 1rem;
  cursor: pointer;
  z-index: 999;
  max-width: 235px;
}
.coaching-timeout-container .coaching-timeout-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
}
.coaching-timeout-container .coaching-timeout-text .coaching-timeleft {
  color:#F04438;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}
.coaching-timeout-container .coaching-timeout-icon {
  background-color: #F7F7F7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}
.coaching-video-container {
  flex-basis: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.coaching-video-container-top {
  flex-basis: 30%;
  min-height: 25px;
}
.coaching-video-container-below {
  background-color: #4377fc;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.coaching-video-container-background {
  width: 600px; /* Adjust width as needed */
  height: 200px; /* Half the height of width for semi-circle */
  background-color: #31C6AF; /* Green color */
  border-top-left-radius: 200px;
  border-top-right-radius: 200px;
  position: absolute;
  bottom: 10%;
  right: -60%;
  transform: rotate(180deg);
  z-index: -1;
  opacity: 0.70;
  filter: blur(70px);
}
.coaching-speech-controls-background {
  width: 850px; /* Adjust width as needed */
  height: 850px; /* Half the height of width for semi-circle */
  background-color: #31C6AF; /* Green color */
  border-radius: 50%;
  position: absolute;
  top: 35%;
  z-index: 0;
  opacity: 0.70;
  filter: blur(35px);
  overflow: hidden;
}
.coaching-mic-container .recording {
  background-color: #F04438;
}
.coaching-stroke-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.coaching-stroke-image1 {
  opacity: 0.8;
  filter: invert(37%) sepia(87%) saturate(1771%) hue-rotate(182deg)
    brightness(351%) contrast(98%);
  width: 150px;
  height: 150px;
  user-select: none;
}

.coaching-stroke-image2 {
  opacity: 0.8;
  filter: invert(37%) sepia(87%) saturate(1771%) hue-rotate(182deg)
    brightness(121%) contrast(98%);
  width: 200px;
  height: 200px;
  user-select: none;
}

.coaching-stroke-image3 {
  opacity: 0.8;
  filter: invert(37%) sepia(87%) saturate(1771%) hue-rotate(182deg)
    brightness(101%) contrast(98%);
  width: 250px;
  height: 250px;
  user-select: none;
}
.coaching-stroke-image4 {
  opacity: 0.4;
  filter: invert(37%) sepia(87%) saturate(1771%) hue-rotate(182deg)
    brightness(101%) contrast(98%);
  width: 300px;
  height: 300px;
  user-select: none;
}

.coaching-stroke-image1, .coaching-stroke-image2, .coaching-stroke-image3 {
  position: absolute;
}

.coaching-tag-button-container {
  background-color: rgb(255, 255, 255, 0.2);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  align-self: center;
  color: #FFFFFF;
  z-index: 9999;
}

.coaching-tag-button-container.exit {
  z-index: 999;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 1000;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 25%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0,0,0,0.1);
  z-index: 1001;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
}

.sidebar.open {
  transform: translateX(0);
}

.coaching-speaker-container .muted {
  opacity: 0.5;
}

.inactivity-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}

.inactivity-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.inactivity-modal-buttons {
  margin-top: 20px;
}

.inactivity-modal-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
}

.inactivity-modal-buttons button:hover {
  background-color: #0056b3;
}

.text-center {
  text-align: center;
}

/* .coaching-error-message {
  line-height: 0;
} */

/* Tablet styles */
@media screen and (max-width: 1024px) {
  /* .coaching-speech-controls-background {
    left: 4%;
    bottom: -60%;
  } */
  .sidebar {
    width: 50%;
  }
  /* .coaching-speech-controls-container {
    padding-top: 60px;
  } */
  .coaching-speech-controls {
    margin-bottom: 40px;
  }
}

/* Mobile styles */
@media screen and (max-width: 440px) {
  .coaching-header {
    max-height: 56px;
  }
  .coaching-back-button, .coaching-options-button {
    padding: 6px;
  }
  .coaching-speech-controls-background {
    display: none;
  }
  .coaching-speech-controls-container {
    padding-top: 0px;
  }
  .coaching-speech-controls {
    width: 100%;
    gap: 0;
    justify-content: space-between;
    padding: 0 2rem;
    margin-bottom: 0;
  }
  .coaching-stroke-container {
    bottom: 0;
  }
  .coaching-video-container-background {
    height: 200px;
    top: 10%;
  }
  .coaching-stroke-image1 {
    width: 100px;
    height: 100px;
  }
  .coaching-stroke-image2 {
    width: 150px;
    height: 150px;
  }
  .coaching-stroke-image3 {
    width: 200px;
    height: 200px;
  }
  .coaching-stroke-image4 {
    width: 250px;
    height: 250px;
  }
  .coaching-llm-text-container {
    max-width: 306px;
  }
}

/* @media screen and (max-height: 700px) {
  .coaching-speech-controls-background {
    display: none;
  }
} */

/* @media screen and (max-height: 1370px ) {
  .coaching-speech-controls-background {
    bottom: -47%;
  }
}

@media screen and (max-height: 1200px ) {
  .coaching-speech-controls-background {
    bottom: -55%;
  }
}

@media screen and (max-height: 1024px ) {
  .coaching-speech-controls-background {
    bottom: -65%;
  }
}

@media screen and (max-height: 1000px) {
  .coaching-speech-controls-background {
    bottom: -90%;
  }
} */