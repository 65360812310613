.actionplan-modal-container {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    cursor: default;
}
.actionplan-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.actionplan-modal-header p {
    font-size: 24px;
    font-weight: 500;
    width: 75%;
}
.actionplan-modal-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.actionplan-modal-body p, .actionplan-modal-actions-section p {
    font-size: 18px;
    font-weight: 500;
}
.actionplan-modal-body li {
    padding-left: 1.5rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    list-style: none;
    position: relative;
    color: #7E7F83;
}
.actionplan-modal-body li::before {
    content: "•";
    font-size: 20px; /* Reduced size */
    position: absolute;
    left: 2%;
    top: 25%;
    transform: translateY(-50%);
}
.actionplan-modal-actions-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.actionplan-filter-button-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.actionplan-filter-button-container .actionplan-filter-button-item {
    border: 1px solid #E4E4E7;
    background-color: #F7F7F7;
    padding: 4px 12px;
    border-radius: 100px;
    cursor: pointer;
}
.actionplan-filter-button-container .actionplan-filter-button-item.active-filter {
    background-color: #4674FE;
    border: 1px solid #406AE7;
    color: #FFFFFF;
}
.actionplan-selection-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.actionplan-selection-item {
    background-color: #F7F7F7;
    border-radius: 12px;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.actionplan-selection-item input[type="checkbox"] {
    appearance: none;
    min-width: 24px;
    min-height: 24px;
    border: 2px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
}

.actionplan-selection-item input[type="checkbox"]:checked {
    background-color: #04D9B5;
    border-color: #04D9B5;
}

.actionplan-selection-item input[type="checkbox"]:checked::after {
    content: "✓";
    position: absolute;
    color: white;
    font-size: 14px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.end-actionplan-button-container {
    padding: 16px 20px;
    border-radius: 100px;
    background-color: #0D5EAF;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}

@media screen and (max-width: 480px) {
    .actionplan-modal-header p{
        font-weight: 600;
    }
}