.coaching-profile-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .coaching-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    font-weight: thin;
    margin-right: 1rem;
    overflow: hidden;
  }
  
  .coaching-avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .coaching-avatar-initials {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
font-weight: thin;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #005f6d;
    background: linear-gradient(45deg, #9eecff 23%, #a0ffea);
    overflow: hidden;
  }
  
  .coaching-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .coaching-name {
    color: var(--Base-Black, #000);

    /* Text m/Medium */
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    margin: 0px;
  }
  
  .coaching-minutes {
    color: var(--Gray-Normal, #7E7F83);

    /* Text s/Regular */
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
  
  .coaching-additional-info {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: #495057;
  }
  
  .membershipmodal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .membership-primary-btn {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .membership-primary-btn:hover {
    background-color: #0056b3;
  }
  
  .option-lable{
    color: #000;
    font-feature-settings: 'liga' off, 'clig' off;
    
    /* Text lg/Medium */
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.27px;
    margin: 0px;
  }

  .option-subheading{
    color: var(--Gray-Normal, #7E7F83);

/* Text s/Regular */
font-family: Urbanist;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
margin:0px
  }

  .membership-coaching-minutes-header-container{
    margin-bottom: 24px;
  }

.membership-coaching-minutes-header{
  color: var(--Black, #000);

/* Display xs/Medium */
font-family: Urbanist;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 28.8px */
margin: 0px;

}

  
.membership-coaching-minutes-section {
  margin-bottom: 16px;
}

.membership-coaching-minutes-toggle {
  width: 100%;
  padding: 0;
  background: var(--Transperency, rgba(237, 241, 255, 0.20));
  border: 1px solid #4478fc;
  border-radius: 16px;
  text-align: left;
  cursor: pointer;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
  color: var(--Gray-Darker, #2C2C2E);

/* Text lg/Semibold */
font-family: Urbanist;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 130%; /* 23.4px */
margin-bottom: 24px;
}

.membership-coaching-minutes-toggle-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:16px  20px ;
}

.membership-coaching-minutes-transfer,
.membership-coaching-minutes-purchase {
  padding: 0px 20px;

}

.membership-coaching-minutes-plan {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  cursor: pointer;
}

.membership-coaching-minutes-plan-text {
  overflow: hidden;
  color: var(--Gray-Darker, #2C2C2E);
  text-overflow: ellipsis;
  
  /* Text m/Regular */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.membership-coaching-minutes-custom {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.membership-coaching-minutes-custom-hours {
  display: flex;
  align-items: center;
  gap: 12px;
}

.membership-coaching-minutes-custom-button {
  background: var(--Crayola-Blue-Normal, #4674FE);
  border: none;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.membership-coaching-minutes-transfer-input {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 16px 0;
}

.membership-coaching-minutes-transfer-input input {
  width: 80px;
  padding: 8px;
  border: 1px solid #e9ecef;
  border-radius: 4px;
}


.membership-coaching-minutes-purchase-button {
 
  width: 100%;
  display: flex;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 100px;
border: 1px solid var(--Greek-Blue-Normal, #0D5EAF);
background: var(--Greek-Blue-Normal, #0D5EAF);
color: var(--Base-White, #FFF);
font-family: Urbanist;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 155.556% */
cursor: pointer;
}



.membership-coaching-minutes-transfer-minutes-txt{

color: var(--Gray-Darker, #2C2C2E);
text-overflow: ellipsis;

/* Text m/Semibold */
font-family: Urbanist;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px;
letter-spacing: -0.24px;
}


.membership-coaching-minutes-transfer-txt{
  color: var(--Gray-Darker, #2C2C2E);
text-overflow: ellipsis;

/* Text m/Semibold */
font-family: Urbanist;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px;
letter-spacing: -0.24px;
}


.membership-choose-minutes-txt{
  overflow: hidden;
color: var(--Gray-Darker, #2C2C2E);
text-overflow: ellipsis;

/* Text xs/Regular */
font-family: Urbanist;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 14.4px */overflow: hidden;
color: var(--Gray-Darker, #2C2C2E);
text-overflow: ellipsis;

/* Text xs/Regular */
font-family: Urbanist;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 14.4px */
}

.membership-toggle-button{
  width: 100%;
  color: var(--Gray-Darker, #2C2C2E);
padding: 0px;
/* Text lg/Semibold */
font-family: Urbanist;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 130%; /* 23.4px */
  border: none;
  border-radius: 16px;
  background: var(--Transperency, rgba(237, 241, 255, 0.20));
}