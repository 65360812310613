.custom-radio {
  background-color: #fff;
  height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1.25px solid var(--gray-light-hover, #e4e4e7); */
}

.checked {
  background-color: #274079 !important;
}
