.termsofuse-last-subtext {
  color: #000;
  text-align: center;

  /* Text xl/Medium */
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  letter-spacing: -0.3px;
}
.hide-on-desktop {
  display: none;
}

.notification-set-hero-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  width: 50%;
  margin: 0 auto;
  border-radius: var(--Radius-radius-2xl, 24px);
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #fff;
}

.notification-set-accord {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
}

.notification-set-accord-title-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.not-set-head {
  color: #000;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;

  /* Text lg/Medium */
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.27px;
}

.not-set-sub {
  text-wrap: wrap;
  color: var(--Gray-Dark, #5f5f62);

  /* Text s/Regular */
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
@media screen and (max-width: 760px) {
  .notification-set-hero-container {
    width: 90%;
  }
}

/* Mobile Responsive Styles */
@media screen and (max-width: 488px) {
  .notification-set-hero-container {
    width: 100%;
    box-sizing: border-box;
    background: none !important;
    border: none !important;
  }
  .termsofuse-last-subtext {
    text-align: left !important;
    margin-left: 1rem;
  }
  .hide-on-desktop {
    display: inline;
  }
  .notification-set-accord {
    padding-bottom: 10px;
    border-bottom: 1px solid #7e7f83;
    border-radius: 0;
    gap: 10px !important;
  }
  .not-set-sub {
    text-align: left !important;
  }
}
