.chat-option-hero-cntr {
  display: flex;
  height: 3.5rem;
  /* width: max-content; */
  min-width: 6.7rem;

  /* flex-wrap: wrap; */
  padding: 8px 7px;
  align-items: center;
  gap: 5px;
  /* flex: 1 1 0; */
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  flex-grow: 1; /* Allow items to grow */
  flex-shrink: 1; /* Allow items to shrink */
  flex-basis: 100px;
}

.chat-option-hero-cntr-centr {
  display: flex;
  gap: 5px;
  align-items: flex-start;
}

.chat-option-txt {
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; */
  /* flex: 1 0 0; */

  overflow: hidden;
  color: #fff;
  text-overflow: inherit;
  font-family: Urbanist;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4rem; /* 142.857% */
}

.chat-option-txt-2 {
  flex-basis: 152px;
  /* flex-grow: 2; */
}

.active-chat-option {
  border-radius: 12px;
  background: var(
    --Gradient-lime,
    linear-gradient(45deg, #bcf5a7 23%, #96f5e0 100%),
    linear-gradient(45deg, #8ad8eb 23%, #82e1cc 100%),
    #82e3cc
  );
}
.active-chat-option-2 {
  border-radius: 12px;
  background: var(
    --Gradient-lime,
    linear-gradient(45deg, #bcf5a7 23%, #96f5e0 100%),
    linear-gradient(45deg, #8ad8eb 23%, #82e1cc 100%),
    #82e3cc
  );
  flex-basis: 200px; /* Set a larger initial width for the last two items */
  flex-grow: 2;
}

.active-chat-option-txt {
  color: var(--Black, #000);
}

@media (max-width: 580px) {
  .chat-option-txt-2 {
    min-width: 150px !important;
  }
  .active-chat-option-2 {
    min-width: 150px !important;
  }
}
