.relationship-chat-hero-container {
  border-radius: var(--Radius-radius-2xl, 24px);
  background: var(--Transperency, rgba(237, 241, 255, 0.2));

  width: 90%;
  height: 80dvh;
  max-height: 80dvh;
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.relationShip-chats {
  max-height: 95%;
}

.insight-hero-container {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  height: 100%; /* Add this */
  min-height: 0;
}

.insight-header {
  display: flex;
  justify-content: flex-end;
}

.insight-tab-switch-content {
  width: 100%;
  padding: 4px;
  border-radius: 16px;
  background: #f7f7f7;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.insight-tab-switch-content.metrics-null {
  background: transparent;
  justify-content: center;
}

.insight-switch-txt {
  width: 45%;
  padding: 14px 4px;
  color: var(--Black, #000);
  text-align: center;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  cursor: pointer;
}

.insight-tab-active {
  border-radius: 12px;
  border: 1px solid rgba(5, 214, 182, 0.05);
  background: var(--Crayola-Blue, #4674fe);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.24);
  color: #fff;
}

.insight-content-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  min-height: 0;
  max-height: 100%; /* Add this */
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: stable;
}

.insight-content-container::-webkit-scrollbar {
  width: 4px;
}
.insight-content-container::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 10px;
  height: 10px;
}
.insight-content-container::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Chrome, Edge, Safari */

.insight-content-header {
  color: var(--Black, #000);
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 83.333% */
}

.insight-people-container {
  width: 100%;
  display: flex;
  position: relative;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: space-around;
}

.insight-people {
  border-radius: 16px;
  border: 1px solid #f4f4f4;
  background: var(--Gray-100, #f7f7f7);
  width: 40%;
  /* max-width: 45%; */

  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  color: var(--Black, #000);
  text-align: center;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.insight-people img {
  border-radius: 50%;
}

.insight-progress-container {
  border-radius: 16px;
  border: 1px solid #f4f4f4;
  background: #f7f7f7;
  position: relative;
  display: flex;
  padding: 16px 16px 36px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
  /* width: 95%; */
}

.insight-progress-line {
  display: flex;
  height: 1px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background-color: #4674fe;
  position: absolute;
  top: 60%;
  left: 10;
  transform: translateY(-50%);
  width: 90%;
}

.triangle-bg {
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 90%;
  height: 80px; /* Adjust height as needed */
  position: absolute;
  top: 5%;
  left: 10;
  background: linear-gradient(
    90deg,
    rgba(70, 117, 254, 0.5) -16.64%,
    rgba(5, 214, 182, 0) 138.39%
  );
  clip-path: polygon(0% 100%, 100% 50%, 100% 100%, 0% 100%);
}

.insight-progress-line-inner {
  position: absolute;
  height: 20%;
  transform: translateY(185%);
  top: 10;

  width: 1px;
  background-color: #4674fe; /* Blue color for the vertical lines */
  /* z-index: 1; Ensure the lines are above the progress bar */
}

.insight-progress-info-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--Black, #000);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  padding-bottom: 50px;
}

.insight-person-container {
  display: flex;
  flex-direction: column;
  gap: 4px;

  position: absolute;
  top: 50%;

  color: var(--Black, #000);
  text-align: center;
  font-family: Urbanist;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 100% */
}

.insight-person-container img {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.insight-content {
  margin-top: 10px;
}
.insight-content-header {
  color: var(--Black, #000);
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}

.insight-content-text {
  color: var(--Black, #000);
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  opacity: 0.6;
}

.set-pressure {
  color: var(--Gray-Darker, #2c2c2e);

  /* Text xs/Regular */
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}

.advice-detail-item {
  border-radius: 12px;
  background: var(--Gray-Light, #f7f7f7);

  transition: all 0.3s ease;
  padding: 15px;
  margin-bottom: 10px;
}

/* Personality ------------------------------------*/
.personality-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.personality-heading {
  color: var(--Black, #000);
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 83.333% */
  /* margin-bottom: 16px; */
  margin-right: 65px;
}

.personality-tab-switch-content-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.personaltiy-tab-switch-content {
  display: flex;
  flex-direction: row;
  width: 60%;
  padding: 4px;
  border-radius: 100px;
  background: #f7f7f7;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.personality-switch-txt {
  width: 45%;
  padding: 10px;
  color: var(--Black, #000);
  text-align: center;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  cursor: pointer;
}

.personality-tab-active {
  border-radius: 100px;
  border: 1px solid rgba(5, 214, 182, 0.05);
  background: var(--Crayola-Blue, #4674fe);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.24);
  color: #fff;
}

@media (max-width: 770px) {
  .personaltiy-tab-switch-content {
    width: 90%;
  }
}

/* Processing---------------------------------- */

.Processing-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Processing-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Processing-txt {
  color: var(--Black, #000);
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 83.333% */
  margin-right: 27px;
}

.Insight-info {
  width: 25px;
  height: 25px;
}

/* Conflict Management---------------------------------- */

.primary-color-container {
  display: flex;
  gap: 24px;
}

.primary-color-txt {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.triangle-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 90%;
  height: 80px;
  position: absolute;
  top: 5%;
  left: 10;
  background: linear-gradient(
    90deg,
    rgba(70, 117, 254, 0.5) -16.64%,
    rgba(5, 214, 182, 0) 138.39%
  );
  clip-path: polygon(0% 100%, 100% 50%, 100% 100%, 0% 100%);
}

/* Fundamental Needs--------------------------------------- */

.Fundamental-container {
  display: flex;
  width: 100%;
  text-align: left;
}

.Fundamental-txt-left {
  width: 50%;
}

.Fundamental-txt-right {
  width: 50%;
}

.fundamental-txt {
  color: var(--Black, #000);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.fundamental-profile {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.backbutton-insights {
  border: none;
  background: inherit;
  padding: 0px;
  display: flex;
  align-items: center;
  gap: 2px;
}

.content-loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0; /* Adds spacing below the toggle buttons */
  position: relative;
}

@media (max-width: 500px) {
  .insight-people {
    width: 37%;
  }
}

@media (max-width: 480px) {
  .relationship-chat-hero-container {
    background: none;
    padding-left: 0;
    /* padding-right: 0; */
  }
  .relationShip-chats {
    margin-left: -10px;
  }
}

@media (max-width: 350px) {
  .insight-people {
    width: 30%;
  }
}

.profile-initials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #ccc; /* Or any background color you prefer */
  border-radius: 50%;
  font-size: 18px; /* Adjust the font size */
  font-weight: bold;
  color: #fff; /* Adjust the text color */
}


/* Tooltip  -------------------------------------------*/

.info-icon {
  width: 20px;
  height: 20px;
  color: rgba(185, 185, 185, 1);


}

.info-icon:hover {
  color: #333;
}

.info-icon:focus {
  outline: none;
}

/* Override react-tooltip arrow styles to match our theme */
