.audio-player-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  /* width: 100%; */
  margin-bottom: 2.5rem;
  padding-top: 1rem;
}

.speaker-container {
  display: flex;
  padding: 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.pause-container {
  display: flex;
  padding: 1.1rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  border-radius: 50%;
  background-color: #04d9b5;
  cursor: pointer;
}

.close-container {
  display: flex;
  padding: 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

@media (max-width: 768px) {
  .audio-player-container {
    width: 100%;
  }
}
