/* Profile.css */

.myprofile-topbar h1 {
  margin: 0;
  /* Ensure no margin for the heading */
  margin-bottom: 50px;
  font-size: 1.5rem;
  font-weight: 600;
}

.myprofile-profile-page {
  min-height: 100vh;
  background: linear-gradient(135deg, #e0f7fa 0%, #e8eaf6 100%);
  padding: 20px;
  font-family: " Urbanist", sans-serif;
}

.myprofile-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
}

.myprofile-back-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 8px;
}

.myprofile-header h1 {
  flex: 1;
  text-align: center;
  margin: 0;
  font-size: 1.25rem;
}


.myprofile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 150px;
  /* Adjust as needed */
  margin: 20px auto;
}

.status-circle-container {
  position: relative;
  width: 140px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.status-circle-profile-image {
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #9eecff 23%, #a0ffea);
  color: #005f6d;
  font-weight: thin;
  font-size: 1.2rem;
  border-radius: 50%;
  width: 128px;
  height: 128px;

}




.myprofile-profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.myprofile-edit-icon {
  position: absolute;
  bottom: 46px;
  right: -17px;
  margin-right: 12px;
  margin-bottom: 50PX;
  background: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.myprofile-status {
  background: var(--Gradient-2, linear-gradient(45deg, #BCF5A7 23%, #96F5E0 100%));
  color: rgba(39, 64, 121, 1);
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  bottom: 0px;
  font-family: "Urbanist", sans-serif;
}


.myprofile-profile-card {
  z-index: 1;
  background: white;
  border-radius: 24px 24px  0px 0px;;
  padding: 24px;
  max-width: 800px;
  margin: 0 auto;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */

}

.myprofile-profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.myprofile-profile-image-container {
  position: relative;
  margin-bottom: 15px;
}


.myprofile-profile-image-container {
  position: relative;
  margin-bottom: 15px;
  background: var(--tile-icon-gradient-02, linear-gradient(45deg, #85DFFF 0%, #CFB2FF 77%));
}




.myprofile-online-indicator {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 15px;
  height: 15px;
  background: #4CAF50;
  border: 2px solid white;
  border-radius: 50%;
}

.myprofile-profile-header h2 {
  text-align: center;
  /* Display xs/Semibold */
  font-family: "Urbanist";
  margin: 0px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.myprofile-subtitle {
  color: #000;

  /* Text m/Medium */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-top: 10px
  /* 137.5% */
}

.myprofile-badge {
  color: var(--Black, #000);
font-family: "Urbanist";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 137.5% */
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: flex;
  align-items: center;

  gap: 8px;
  margin-top: 10px;

  border-radius: 100px;


}

.myprofile-badge-icon {
  font-size: 1.2rem;
}

.myprofile-strengths-section {
  text-align: left;
  margin: 16px 0;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: var(--White, #FFF);
  padding: 12px;
  
}

.myprofile-strengths-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.myprofile-strength-card {
  padding: 15px;
  border-radius: 10px;
  font-size: 0.9rem;
width: 200px;
}

.myprofile-strength-text{
  font-size: 14px; /* Adjust as needed */
  line-height: 1.2; /* Line height for better readability */
  color: #333; /* Ensure readable color */
  word-wrap: break-word; /* Break long words */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  /* max-height: 3em; Limit height for multi-line text */
  white-space: normal; /* Allow text wrapping */
}


.myprofile-strength-icon {
  margin-bottom: 7px;
}


.myprofile-assessment-links {
  margin: 10px 0;
}

.myprofile-assessment-item {
  display: flex;
  align-items: center;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}


.assessment-icon {
  font-size: 24px;
  margin-right: 15px;
}

.myprofile-assessment-text {
  flex: 1;
  
}

.myprofile-assessment-text h4 {
  margin: 0;
  font-size: 1rem;
}

.myprofile-assessment-text p {
  margin: 5px 0 0;
  color: #666;
  font-size: 0.9rem;
}

.myprofile-arrow {
  font-size: 1.2rem;
  color: #666;
}

.myprofile-relationships-section {
  margin: 16px 0;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: var(--White, #FFF);
  padding: 12px;
}

.myprofile-relationships-container {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}



.myprofile-relationship-avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #005f6d;
  font-family: "Urbanist";
    font-size: 1.625rem;
    font-style: normal;
  font-weight: thin;
  background: linear-gradient(45deg, #9eecff 23%, #a0ffea);
  gap: 8px;
  margin-bottom: 6px;
}

.myprofile-personality-type{
  color: var(--Base-White, #fff);

/* Text s/Regular */
font-family: "Urbanist";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
}


.myprofile-relationship-info h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px;
  font-style: normal;
  color: var(--Base-Black, #000);
  font-weight: 600;
}


.myprofile-section-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}


.myprofile-section-header h3{
  margin: 0px;

}

.myprofile-manage-button {
  background: none;
  border: none;
  color: rgba(13, 94, 175, 1);
  font-family: "Urbanist", sans-serif;
  cursor: pointer;
  font-size: 0.9rem;
}

.myprofile-summary-section {
  text-align: left;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: var(--White, #FFF);
  padding: 12px;
}

.myprofile-summary-section h3{
  margin: 0px;
}

.myprofile-traits-container {
  display: flex;
  gap: 10px;

  flex-wrap: nowrap;
  overflow-x: auto; /* Enables horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer */
  margin-top: 15px;
}

.myprofile-traits-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari, and Edge */
}


.myprofile-trait-tag {
  border: 1px solid var(--Gray-200, #E4E4E7);
  background: var(--Gray-100, #F7F7F7);
  padding: 5px 15px;
  border-radius: 15px;
  color: var(--Black, #000);
flex-shrink: 0;
/* Text m/Medium */
font-family: "Urbanist";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 137.5% */
}

.myprofile-details-section {
  margin-top: 30px;
  text-align: left;
}

.myprofile-detail-item {
  border-radius: 12px;
  background: var(--Gray-Light, #F7F7F7);

  padding: 15px;
  margin-bottom: 10px;
}


.myprofileh3{
  color: var(--Base-Black, #000);

/* Display xs/Semibold */
font-family: "Urbanist";
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 133.333% */
}



.myprofile-detail-item h4 {
  margin: 0;
  color: var(--Black, #000);

  /* Text m/Semibold */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.24px;
}

.myprofile-detail-content {
  margin: 15px 0;
  border-radius: 12px;
  background: var(--Greek-Blue-Light, #E7EFF7);
  font-size: 0.9rem;
  line-height: 1.5;
  

}

.myprofile-detail-content{
  color: var(--Gray-Dark, #5F5F62);

/* Text m/Regular */
font-family: "Urbanist";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
word-break: break-all;

}

.expanded {
  background: var(--Greek-Blue-Light, #E7EFF7);
}

.myprofile-toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--Black, #000);

  /* Text m/Medium */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}

.myprofile-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.myprofile-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.myprofile-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.myprofile-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}




.myprofile-collapsed h4 {
  color: #666;
}

@media (max-width: 600px) {
  .myprofile-card {
    padding: 16px;
  }

  .myprofile-strengths-container {
    grid-template-columns: 1fr;
  }

  .myprofile-relationships-container {
    justify-content: center;
  }
}


.myprofile-relationships-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}



/* .myprofile-traits-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 15px;
} */

/* .myprofile-details-section {
    margin-top: 30px;
  }
  
  .myprofile-detail-item {
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
  }
  
  .myprofile-detail-item h4 {
    margin: 0;
    font-size: 1rem;
    color: #333;
  }
  
  .myprofile-detail-content {
    margin: 15px 0;
    color: #666;
    font-size: 0.9rem;
    line-height: 1.5;
  }
  
  .myprofile-toggle-container {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.9rem;
    color: #666;
  }
  
  .myprofile-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .myprofile-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .myprofile-slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .myprofile-slider {
    background-color: rgba(13, 94, 175, 1);
  }
  
  input:checked + .myprofile-slider:before {
    transform: translateX(26px);
  } */



.myprofile-details-section {
  width: 100%;
  margin-top: 20px;
}

.myprofile-detail-item {
  /* border: 1px solid #e0e0e0; */
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.myprofile-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.myprofile-detail-content {
  margin-top: 10px;
  
}

.arrowbg {
  background-color: white;
  padding: 2px;
  border-radius: 50%;
}

.myprofile-toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.myprofile-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.myprofile-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.myprofile-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 20px;
  transition: 0.4s;
}

.myprofile-switch input:checked+.myprofile-slider {
  background-color: rgba(13, 94, 175, 1);
}

.myprofile-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.myprofile-switch input:checked+.myprofile-slider:before {
  transform: translateX(20px);
}




.myprofile-collapsed h4 {
  color: #666;
}

@media (max-width: 600px) {
  .myprofile-profile-card {
    padding: 16px;
  }

  .myprofile-strengths-container {
    grid-template-columns: 1fr;
  }

  .myprofile-relationships-container {
    justify-content: center;
  }
}



.myprofile-relationships-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: start;
}

.myprofile-relationship-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  cursor: pointer;
  text-align: center;
}


.myprofile-relationship-name {
  text-align: center;
  font-size: 14px;
  color: #333;
}


.myprofile-relationship-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}


.myprofile-card.full-width {
  width: 100%;
}


.myprofile-card-container.single-card {
  grid-template-columns: 1fr;
  gap: 0;
}




.myprofile-new-section {
  margin: 20px 0;
}

.myprofile-card-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  text-align: left;
  

}

.myprofile-card {
  display: flex;
  align-items: left;
  justify-content: space-between;
  flex-direction: column;
  width: 48%;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: var(--White, #FFF);
  border-radius: 12px;
  cursor: pointer;
  transition: transform 0.2s;
}




@media(max-width:500px) {

  .myprofile-card-container {
    display: flex;
    flex-direction: column;

  }

  .myprofile-card {
    flex-direction: row;
    box-sizing: border-box;
    background: var(--Gray-Light, #F7F7F7);
    width: 100%;

    border: none
  }

  .myprofile-card-content {
    margin-left: 10px;
  }
}



.myprofile-card:hover {
  transform: scale(1.02);
}


.myprofile-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}


.myprofile-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 10px;

}

.myprofile-card-content {
  flex-grow: 1;
  /* margin-left: 10px; */
}

.myprofile-card-content h4 {
  margin: 0;
  color: var(--Base-Black, #000);
font-feature-settings: 'liga' off, 'clig' off;

/* Text lg/Medium */
font-family: Urbanist;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
letter-spacing: -0.27px;
}

.myprofile-card-content p {
  margin: 5px 0 0;
  color: #7d7d7d;
  font-size: 14px;
}

.myprofile-card-arrow {
  font-size: 24px;
  color: #7d7d7d;
}


.profile-image-container {
  position: relative;
  /* Set the container to relative positioning */
  display: inline-block;
  /* Adjust the display property to fit the images */
}

.base-image {
  display: block;
  /* Ensure the base image is a block element */
  width: 150%;
  /* Adjust width as needed */
  height: auto;
  /* Maintain aspect ratio */
  margin-left: -25%;
}

.overlay-image {
  position: absolute;
  /* Position the overlay image absolutely */
  top: 0;
  /* Align to the top of the container */
  left: 0;
  /* Align to the left of the container */
  width: 100%;
  /* Adjust width as needed */
  height: auto;
  /* Maintain aspect ratio */
  margin-top: 5px;
  pointer-events: none;
  /* Optional: allows clicks to pass through the overlay */
}


/* Class to prevent scrolling */
.no-scroll {
  overflow: hidden;
}



/* Modify myprofile-strengths-container to be horizontally scrollable on smaller screens */
@media (max-width: 768px) {
  .myprofile-strengths-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 15px;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }

  /* Hide the scrollbar across all browsers */
  .myprofile-strengths-container::-webkit-scrollbar {
    display: none; /* Hides scrollbar in Chrome, Safari, and Edge */
  }

  .myprofile-strengths-container {
    scrollbar-width: none; /* Hides scrollbar in Firefox */
    -ms-overflow-style: none; /* Hides scrollbar in Internet Explorer and Edge */
  }

  .myprofile-strength-card {
    flex-shrink: 0; /* Prevent cards from shrinking */
    width: 200px; /* Set a fixed width for horizontal scrolling */
  }



}

/* Similar horizontal scroll for relationships */
@media (max-width: 768px) {
  .myprofile-relationships-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 16px;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }

  .myprofile-relationships-container::-webkit-scrollbar {
    display: none;
  }

  .myprofile-relationships-container::-webkit-scrollbar-thumb {
    scrollbar-width: none; /* Hides scrollbar in Firefox */
    -ms-overflow-style: none; /* Hides scrollbar in Internet Explorer and Edge */
  }

  .myprofile-relationship-card {
    flex-shrink: 0;
  }
}

/* Adjust card container for tablet and mobile */
@media (max-width: 480px) {
  /* .myprofile-card-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    
  } */

  .myprofile-card {
    width: 100%;
  
  }

  .myprofile-profile-header {
    color: #000;
  }


    .myprofile-profile-card{
        height: 67dvh;
        color: #000;
        font-family: "Urbanist";
    }
    .mobile-profile-header{
        height: 32dvh;
     
    }

    .myprofile-profile-header h2 {
      color: var(--White, #FFF);
      text-align: center;
      
      /* Display xs/Semibold */
      font-family: "Urbanist";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
    }

    .myprofile-subtitle {
      color: #fff;
      font-family: "Urbanist";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      margin-top: 10px;
  }



  .myprofile-badge {
    background-color: white;
    color: #000;
    border-radius: 100px;
    padding: var(--Spacing-spacing-3xs, 4px) 12px;
  }

}



@media (max-width: 480px) {
  .myprofile-strength-card {
    width: 90px;
    height: 128px;
  }
}




.add-relationship-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  background: white;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.add-relationship-button:hover {
  background: #F9FAFB;
}

.icon-circle {
  width: 48px;
  height: 48px;
  background: #DBEAFE;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.user-plus-icon {
  width: 24px;
  height: 24px;
  color: #2563EB;
}

.button-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #111827;
  line-height: 1.4;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 16px;
  width: 90%;
  max-width: 500px;
  position: relative;
  z-index: 1001;
}