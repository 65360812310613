.session-hero-container {
    position: absolute;
    top: 60px;
    right: 90px;
    display: flex;
    width: 374px;
    max-width: 374px;
    min-height: 490px;
    padding: 1rem 0;
    flex-direction: column;
    gap: 24px;
    border-radius: 24px;
    background: #fff;
    max-height: 50dvh;
    border: 1px solid rgba(0, 0, 0, 0.05);
}
.session-head-notification {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
}
.session-cnt {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow-y: auto;
    max-height: 390px; 
}
.session-cnt::-webkit-scrollbar {
    width: 6px;
}
.session-cnt::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}
.session-cnt::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}
.session-container-hero {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.session-container-top {
    display: flex;
    align-items: center;
    gap: 12px;
    background: var(--Gray-Light, #f7f7f7);
    padding: var(--Spacing-spacing-xs, 8px);
    border-radius: 18px;
}
.session-container-hero .session-left {
    display: flex;
    padding: var(--Spacing-spacing-md, 12px);
    align-items: center;
    gap: 8px;
    border-radius: var(--Radius-radius-lg, 16px);
    background: var(
      --tile-icon-gradient-02,
      linear-gradient(45deg, #85dfff 0%, #cfb2ff 77%)
    );
}
.session-container-hero .session-right {
    flex: 1;
}
.session-container-bottom {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
}
.session-container-bottom-button-container {
    width: 50%;
    max-height: 40px;
    padding: 10px 12px;
    border-radius: 100px;
    border: 1px solid #E4E4E7;
    background-color: #FFFFFF;
    text-align: center;
    cursor: pointer;
}
.session-container-bottom-button-container.cancel {
    background-color: #F04438;
    color: #ffffff;
}