.coaching-send-invite-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem;
}
.coaching-send-invite-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.coaching-send-invite-header p {
    font-weight: 600;
    font-size: 24px;
}
.coaching-send-invite-search-bar {
    display: flex;
    align-items: center;
    border: 1px solid #E4E4E7;
    background-color: #F7F7F7;
    padding: 8px 8px 4px 10px;
    gap: 0.5rem;
    border-radius: 12px;
}
.coaching-send-invite-search-bar span img {
    width: 16px;
    height: 16px;
}
.coaching-send-invite-search-bar input {
    border: none;
    outline: none;
    width: 100%;
    background-color: transparent;
    font-weight: 400;
    font-size: 16px;
}
.coaching-send-invite-search-bar input::placeholder {
    font-weight: 400;
    font-size: 16px;
    color: #7E7F83;
}
.coaching-send-invite-filter-chips-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    overflow-x: auto;
}
.coaching-send-invite-filter-chips-container .chip-item {
    padding: 4px 12px;
    background-color: #F7F7F7;
    border: 1px solid #E4E4E7;
    border-radius: 100px;
    cursor: pointer;
}
.coaching-send-invite-filter-chips-container .chip-item.active {
    background-color: #4674FE;
    color: #FFFFFF;
}
.coaching-send-invite-footer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.coaching-send-invite-footer-button-conatiner {
    padding: 16px 20px;
    border-radius: 100px;
    border: 1px solid #E4E4E7;
    cursor: pointer;
    text-align: center;
}
.coaching-send-invite-footer-button-conatiner:nth-child(2) {
    background-color: #0D5EAF;
    color: #FFFFFF;
}
.coaching-send-invite-profiles-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    height: 50vh;
    overflow-y: auto;
}
.coaching-send-invite-profile-item{
    border: 1px solid #0000000D;
    border-radius: 12px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
}
.coaching-send-invite-profile-item-left {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.coaching-send-invite-profile-item-details {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}
.coaching-send-invite-profile-item-image {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #4674FE;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
}
.coaching-send-invite-profile-item-image img
{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.coaching-send-invite-profile-item-name {
    font-weight: 500;
    font-size: 16px;
}
.coaching-send-invite-profile-item-relationship {
    color: #7E7F83;
    font-size: 14px;
    font-weight: 400;
}
.coaching-send-invite-add-button-conatiner {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 6px 12px;
    border-radius: 100px;
    border: 1px solid #E4E4E7;
    cursor: pointer;
}
.coaching-send-invite-add-button-conatiner.added {
    background-color: #2C61E8;
    color: #FFFFFF;
}

@media screen and (max-width: 480px) {
    .coaching-send-invite-profiles-container {
        grid-template-columns: 1fr;
        height: 50vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }
    .coaching-send-invite-filter-chips-container {
        max-width: 320px;
    }
    .coaching-send-invite-footer-button-conatiner {
        width: 50%;
    }
}