.potraits-conflict-management-details {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;

  border-radius: var(--Radius-radius-md, 12px);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.potraits-conflict-management-heading-container {
  margin-bottom: 16px;
}

.potraits-conflict-management-details-super-heading {
  color: var(--Base-Black, #000);
  font-feature-settings: "liga" off, "clig" off;

  /* Display lg/Semibold */
  font-family: Urbanist;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 43.2px */
}

.potraits-conflict-management-details-super-sub-txt {
  color: var(--Gray-Dark, #5f5f62);
  font-feature-settings: "liga" off, "clig" off;

  /* Text lg/Medium */
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.27px;
}

.potraits-conflict-management-sub-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.potraits-conflict-management-details-heading {
  color: var(--Base-Black, #000);
  text-align: center;

  /* Display xs/Semibold */
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.potraits-conflict-management-details-sub-txt {
  color: var(--Gray-Normal, #7e7f83);

  /* Text m/Regular */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}
