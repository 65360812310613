.relationship-hero-container {
  width: 100%;
  min-height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(168.14deg, #4675fe 6.98%, #05d6b6 102.55%); */
  /* background: radial-gradient(circle at center, #4675fe, #05d6b6); */
}

.align-down {
  align-items: flex-end !important;
}

.relationship-gif-txt-cmb-cntr {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.relationship-container {
  width: 60%;
  /* min-height: 100dvh; */

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
}

.relationship-gif {
  width: 254px;
  height: 254px;
}

.relationship-txt-cntr {
  color: var(--White, #fff);
  text-align: center;

  /* Display xs/Medium */
  font-family: Urbanist;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 28.8px */
}

.relationship-option-cntr {
  margin-top: 44px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  flex-wrap: wrap;
}
.relationship-input-cntr {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start; /* Align items at the top */
  justify-content: center;
  gap: 5px;
  max-width: 100%;
  flex-wrap: nowrap;
  height: 1.6rem; /* Fix the height here */
  margin-top: 5px;
}

.rl-input {
  width: 100%;
  height: 100%; /* Ensure it takes the full height of the parent */
  display: flex;
  align-items: center; /* Center the input vertically */
  justify-content: center; /* Optional: if you want to center the input horizontally */
}

.rl-mic {
  /* width: 10%; */
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%;
}

.contact-relationship-moadl-hero-container {
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
}

.contact-relationship-modal-head {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 98%;
  /* gap: 10px; */
}

.contact-relationship-modal-title {
  color: var(--Black, #000);
  width: 90%;

  /* Display xs/Semibold */
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.contact-relationship-modal-close {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
}
.contact-relationship-input-cntr {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.contact-relationship-modal-search {
  width: 95%;
  border-radius: 12px;
  border: 1px solid var(--Gray-200, #e4e4e7);
  background: var(--Gray-100, #f7f7f7);

  padding: 4px 8px 4px 32px;
  color: var(--Gray, #7e7f83);
  /* text-align: center; */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
  letter-spacing: -0.16px;
}

.contact-relationship-modal-search-icon {
  position: absolute;
  left: 10px; /* Adjust based on the padding in the input */
  font-size: 16px;
  color: #aaa;
}

.contact-relationship-modal-category-cntr {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.contact-relationship-modal-category-title {
  cursor: pointer;
  border-radius: 100px;
  border: 1px solid var(--Gray-200, #e4e4e7);
  background: var(--Gray-100, #f7f7f7);
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  color: #000;
  color: var(--Black, #000);

  /* Text m/Medium */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}

.contact-relationship-modal-category-title-txt-active-category {
  background: var(--Crayola-Blue, #4674fe);
  color: #fff;
}

.contact-relationship-modal-contact-list-cntr {
  width: 100%;
  /* height: auto; */
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  position: relative;
  /* overflow-y: scroll; */
  max-height: 242px;
  /* background-color: #4674fe; */
  justify-content: safe;
  overflow-y: auto;
  scrollbar-width: none;
}

.white-background {
  background-color: #fff !important;
}

.chat-history-container {
  height: 100dvh;
  max-height: 100dvh;
  overflow-y: auto;
  width: 320px;

  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.15);

  position: absolute;
  right: 0;
  z-index: 11;
  padding: 16px 16px 32px 16px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /* Overlay */
  backdrop-filter: blur(2px);
}

.chat-history-header {
  width: 100%;
  display: flex;
  justify-content: end;
}

.chat-heading {
  color: var(--Black, #000);
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
  margin-bottom: 24px;

  flex-wrap: wrap;
  text-wrap: wrap;
}

.chat-history-list-cntr {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.cur-ptr {
  cursor: pointer;
}

.chat-history-item {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  cursor: pointer;
  overflow: hidden;
  color: var(--Black, #000);
  text-overflow: ellipsis;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
}

.careeradvice-goal-item {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .relationship-container {
    width: 90%;
    box-sizing: border-box;
    margin: 10px 0;
  }
}

@media (min-width: 1024px) and (max-width: 1050px) and (min-height: 1300px) and (max-height: 1366px) {
  .relationship-container {
    width: 90% !important;
    box-sizing: border-box;
    margin: 10px 0;
    /* background-color: #274079 !important; */
  }
}

@media (max-width: 520px) {
  .relationship-option-cntr {
    flex-wrap: nowrap !important;
    overflow-x: scroll;
  }
  .rl-input {
    width: 85%;
  }
  .rl-mic {
    width: 15%;
  }
}

/* CAREER ADVICE MODAL */

/* Overlay styling */
.careeradvice-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal box styling */
.careeradvice-modal {
  background: #ffffff;
  border-radius: var(--Radius-radius-2xl, 24px);
  max-width: 476px;
  max-height: 90%;
  overflow-y: auto;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
}

.careeradvice-modal-header {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}

/* Close button */
.careeradvice-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 32px;
  font-weight: lighter;
  color: rgba(126, 127, 131, 1);
  cursor: pointer;
}

/* Modal title */
.careeradvice-modal-title {
  color: var(--Black, #000);

  /* Display xs/Medium */
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 28.8px */
  margin: 0px;
  width: 350px;
  text-align: left;
}

/* Career goal list */
.careeradvice-goal-list {
  list-style: none;
  padding: 0px;
  padding-right: 40px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;

  /* Target the scrollbar */
}

/* Individual goal item */

/* Goal title */
.careeradvice-goal-title {
  color: var(--Black, #000);
  /* Text m/Medium */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 0px;
  margin-left: 10px;
}

/* Goal description */
.careeradvice-goal-description {
  color: var(--Gray-Darker, #2c2c2e);
  /* Text xs/Regular */
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  margin: 0;
  margin-left: 10px;
  max-width: 250px;
}

.careeradvice-goal-content {
  display: flex;
  flex-direction: row;
}

/* Mobile responsiveness */
@media screen and (max-width: 400px) {
  .careeradvice-modal {
    width: 100%;
    height: 80%;
    border-radius: 24px 24px 0px 0px;
    max-width: none;
    max-height: none;
    margin-bottom: 0px;
    padding: 16px;
  }
  .careeradvice-modal-overlay {
    align-items: flex-end;
  }

  .careeradvice-modal-title {
    width: 300px;
  }
}

@media screen and (min-width: 401px) {
  .careeradvice-modal-header {
    gap: 50px;
  }
}

/* COACHING SECTION */
.live-session-button {
  position: absolute;
  bottom: 2rem;
  right: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  padding: 10px 12px;
  cursor: pointer;
}

.live-session-button.disabled {
  background-color: #f7f7f7;
  color: #b9b9b9;
}

@media screen and (max-width: 480px) {
  .live-session-button {
    display: none;
  }
}

@media screen and (max-height: 719px) {
  .live-session-button {
    display: none;
  }
}

@media screen and (min-width: 578px) and (max-width: 1012px) {
  .live-session-button {
    bottom: 1rem;
  }
}