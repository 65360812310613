.signup-progress-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 436px;
    max-width: 600px;
    margin: 20px auto;
}

.progress-step {
    flex: 1;
    height: 5px;
    background: rgba(132, 157, 215, 1);
    border-radius: 5px;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
    margin: 0 5px;
}

.progress-step.active {
    opacity: 1;
}

.progress-step span {
    display: none;
}

.progress-bar .progress-step+.progress-step {
    margin-left: 0;
}


/* @media (max-width: 600px) {
    .signup-progress-bar  {
        width: 90%;
    }

    .progress-step {
        height: 4px;
    }
}


@media (max-width: 950px) {
    .signup-progress-bar  {
        width: 90%;
    } */

    /* .progress-step {
        height: 4px;
    }
}


@media (min-width: 1024px) {
    .signup-progress-bar {
        width: 436px;
    }

    .progress-step {
        height: 4px;
    }
} */


@media(max-width: 436px){
    .signup-progress-bar {
        width:90%;
    }}