.potrait-percentail-with-head-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.potraits-pwh-percent {
  color: var(--Base-Black, #000);
  text-align: center;

  /* Display xl/Semibold */
  font-family: Urbanist;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 48px */
}

.potraits-pwh-txt {
  color: var(--Gray-Dark, #5f5f62);
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;

  /* Text lg/Medium */
  font-family: "Work Sans";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 133.333% */
  letter-spacing: -0.27px;
}
