.pricing-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 24px;
}

.pricing-back-button-container {
  display: flex;
  align-items: center;
}

.pricing-dropdown-option-container {
  height: 70px;
}

.pricing-back-button {
  color: var(--Greek-Blue-Normal, #0d5eaf);
  /* Text m/Medium */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  background: inherit;
  border: none;
  gap: 8px;
  padding: 10px 0px;
}

.pricing-header {
  margin: 24px 0;
}

.pricing-header h1 {
  color: var(--Black, #000);
  font-family: "Urbanist", sans-serif;
  font-size: 32px;
  margin: 0px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.64px;
}

.pricing-header p {
  color: var(--Gray, #7e7f83);
  font-family: "Urbanist";
  font-size: 16px;
  margin-top: 5px;
  font-weight: 500;
  line-height: 22px;
}

.pricing-save-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing-save {
  color: var(--Crayola-Blue-Normal, #fff);
  position: absolute;
  /* right: 10px; */
  text-align: center;
  font-family: "Urbanist";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
  margin-top: 10px;
}

.pricing-save-active {
  color: #4674fe;
}

.dis-color {
  color: #fff;
}

.pricing-heading {
  overflow: hidden;
  color: var(--Black, #000);
  text-overflow: ellipsis;
  /* Display xs/Semibold */
  font-family: "Urbanist";
  font-size: 24px;
  font-style: normal;
  margin: 0px;
  font-weight: 600;
  line-height: 32px;
}

.pricing-heading2 {
  overflow: hidden;
  color: var(--Gray-Darker, #2c2c2e);
  text-overflow: ellipsis;
  /* Text m/Medium */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 0px;
}

.pricing-para-footer {
  overflow: hidden;
  color: var(--Gray, #7e7f83);
  text-overflow: ellipsis;
  /* Text xs/Medium */
  font-family: "Urbanist";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-top: 0px;
  text-align: center;
}

.pricing-additional {
  color: var(--Gray-Darker, #2c2c2e);
  /* Text lg/Semibold */
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 3px;
}

.pricing-license {
  overflow: hidden;
  color: var(--Gray-Darker, #2c2c2e);
  text-overflow: ellipsis;
  /* Text m/Regular */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0px;
}

.pricing-members-control {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 12px;
}

.pricing-control-button {
  background: var(--Crayola-Blue-Normal, #4674fe);
  color: #fff;
  border: none;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: var(--Spacing-spacing-2xs, 6px) 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-radio-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* margin-top: 15px; */
}

.pricing-toggle-price-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pricing-radio-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  overflow: hidden;
  color: var(--Gray-Darker, #2c2c2e);
  text-overflow: ellipsis;
  /* Text m/Regular */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.pricing-divider {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  /* Space between the lines and the word "or" */
  margin: 24px 0;
  /* Add some vertical spacing */
}

.divider-line {
  flex-grow: 1;
  border: none;
  border-top: 1px solid #e9e7e7;
  /* Color and thickness of the lines */
  margin: 0;
  /* Remove default margin for <hr> */
}

.pricing-divider span {
  color: var(--Gray-Normal, #7e7f83);

  /* Text m/Medium */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
}

.pricing-total-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  font-weight: 500;
}

.pricing-vip-link {
  width: 100%;
  padding: 8px;
  border: none;
  text-align: center;
  color: #0d5eaf;
  background: inherit;
  margin-bottom: 16px;
  font-family: "Urbanist";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.pricing-subscribe-button {
  width: 100%;
  display: flex;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 100px;
  border: 1px solid var(--Greek-Blue-Normal, #0d5eaf);
  background: var(--Greek-Blue-Normal, #0d5eaf);
  color: var(--Base-White, #fff);
  font-family: "Urbanist";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  cursor: pointer;
  margin-bottom: 16px;
}

.pricing-trial-text {
  text-align: center;
  color: #6b7280;
  font-size: 12px;
}

.pricing-gift-card-section p {
  font-size: 14px;
  line-height: 1.5;
}

.pricing-card-selected {
  border-radius: 16px;
  border: 1px solid #4478fc;
  background: rgba(70, 123, 251, 0.07);
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
  padding: 16px;
  /* margin-bottom: 24px; */
}

.pricing-gift-button {
  background: #4478fc;
  border: none;
  border-radius: 100px;
  color: #fff;
  color: var(--White, #fff);
  /* Text xs/Medium */
  font-family: "Urbanist";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 2px 12px;
}

.pricing-gift-card-container {
  display: flex;
  justify-content: space-between;
}

.pricing-gift-para {
  margin-top: 0px;
  color: var(--Gray-Normal, #7e7f83);
  /* Text m/Medium */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.pricing-total-price-text {
  color: var(--Gray-Normal, #7e7f83);
  font-feature-settings: "liga" off, "clig" off;
  /* Text lg/Medium */
  font-family: "Urbanist";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: -0.27px;
}

.pricing-total-price-text-offer {
  color: var(--Gray-Normal, #7e7f83);
  font-feature-settings: "liga" off, "clig" off;
  /* Text lg/Medium */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: -0.27px;
  margin-top: 0px;
}

.pricing-total-price-amount {
  color: var(--Gray-Darker, #2c2c2e);
  /* Text lg/Semibold */
  font-family: "Urbanist";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.pricing-life-coach-plans {
  overflow: hidden;
  color: var(--Gray, #7e7f83);
  text-overflow: ellipsis;

  /* Text xs/Medium */
  font-family: "Urbanist";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  margin-top: 15px;
  margin-bottom: 5px;
}

.pricing-life-coach-plans-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
}

.pricing-life-coach-container-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pricing-life-coach-container-para {
  margin: 0px;
  overflow: hidden;
  color: var(--Gray, #7e7f83);
  text-overflow: ellipsis;
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
}

.pricing-life-coach-container-para2 {
  overflow: hidden;
  color: var(--Gray, #7e7f83);
  text-overflow: ellipsis;
  font-family: "Urbanist";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 183.333% */
}

.pricing-life-coach-container-price {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.pricing-offer {
  overflow: hidden;
  color: var(--Gray-Normal, #7e7f83);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Urbanist";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 28.8px */
  letter-spacing: -0.24px;
  text-decoration: line-through;
  text-decoration-thickness: 1 px;
  /* Adjust thickness */
}

.pricing-original-offer {
  color: var(--Black, #000);
  font-family: "Urbanist";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.24px;
}

.pricing-life-coach-container-price h2,
p {
  margin: 0px;
}

.pricing-life-coach-container-price p {
  color: var(--Gray, #7e7f83);

  /* Text m/Regular */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
}

.pricing-life-coach-plan-points {
  display: flex;
  width: 100%;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  color: var(--Gray, #7e7f83);
  text-overflow: ellipsis;

  /* Text s/Regular */
  font-family: "Urbanist";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
}

/* Overlay to cover the background */
.vip-code-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color:  rgba(253, 253, 253, 0.80); */
  fill: rgba(253, 253, 253, 0.8);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* Popup container */
.vip-code-popup {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 354px;
  /* position: relative; */
  text-align: center;
}

.vip-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Title styling */
.vip-code-title {
  margin: 0px;
  color: var(--Black, #000);
  text-align: center;
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 19.2px */
  letter-spacing: -0.32px;
}

/* Text styling */
.vip-code-text {
  color: var(--Gray, #7e7f83);
  text-align: center;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 19.2px */
  letter-spacing: -0.32px;
  margin-top: 40px;
}

/* Input field styling */
.vip-code-input {
  width: 100%;
  box-sizing: border-box;
  /* height: 56px; */
  border-radius: 12px;

  border: 1px solid #000;
  padding: var(--Spacing-spacing-lg, 16px);
  align-items: center;
  gap: 4px;
  align-self: stretch;
  margin-bottom: 20px;
  color: var(--Gray-500, #667085);
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.vip-code-input:focus {
  outline: none;

  border: 1px solid #000;
}

/* Submit button styling */
.vip-code-button {
  border-radius: 100px;
  border: 1px solid var(--Greek-Blue-Normal, #0d5eaf);
  background: var(--Greek-Blue-Normal, #0d5eaf);
  display: flex;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  font-family: "Urbanist", sans-serif;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  width: 100%;
}

.vip-code-button:hover {
  background-color: #004bb5;
}

/* Close button styling */
.vip-code-close-button {
  /* position: absolute; */
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: #aaa;
}

.vip-code-close-button:hover {
  color: #000;
}

/* CoACHING MODAL */

.pricing-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  font-family: "Urbanist";
}

.pricing-modal {
  background: white;
  border-radius: 24px;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.pricing-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.pricing-modal-header h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  font-family: "Urbanist";
}

.pricing-close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 18px;
  color: rgba(126, 127, 131, 1);
}

.pricing-modal-description {
  color: var(--Gray-Darker, #2c2c2e);
  text-align: left;

  /* Text m/Regular */
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
  /* 125% */
}

.pricing-modal-points {
  list-style: none;
  padding: 0;
  margin: 0;
}

.pricing-modal-point {
  position: relative;
  margin-bottom: 12px;
  font-size: 14px;
  overflow: hidden;
  color: var(--Gray, #7e7f83);
  text-overflow: ellipsis;

  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  font-family: "Urbanist";
}

.pricing-modal-footer {
  color: var(--Gray-Darker, #2c2c2e);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 2px;
  /* 137.5% */
}

.pricing-additional-cards {
  width: 100%;
  margin: 20px auto;
}

.margin-restricted-bottom {
  margin-bottom: 0 !important;
}

.margin-restricted-top {
  margin-top: 0 !important;
}

.pricing-toggle-card {
  border-radius: 16px;
  background: var(--Transperency, rgba(237, 241, 255, 0.2));
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  margin-bottom: 16px;
  background-color: white;
  /* overflow: hidden; */
  border: 1px solid #4478fc;
}

.pricing-toggle-header {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: white;
  border-radius: 16px;
}

.pricing-toggle-title {
  flex: 1;
}

.pricing-toggle-title h3 {
  color: var(--Gray-Darker, #2c2c2e);
  margin: 0px;
  /* Text lg/Semibold */
  font-family: "Urbanist";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 23.4px */
}

.pricing-toggle-price {
  color: var(--Black, #000);

  /* Text lg/Semibold */
  /* width: 100%; */
  font-family: "Urbanist";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 23.4px */
}

.pricing-toggle-price-txt {
  color: var(--Gray-Normal, #7e7f83);

  /* Text s/Regular */
  font-family: Urbanist;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.pricing-premium {
  display: block;
  overflow: hidden;
  color: var(--Gray, #7e7f83);
  text-overflow: ellipsis;

  /* Text m/Medium */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
}

.pricing-toggle-button {
  border-radius: 50%;
  border: none;
  background: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  color: #666;
  transition: transform 0.2s ease;
}

.pricing-toggle-button-open {
  transform: rotate(180deg);
}

.pricing-toggle-content {
  padding: 0 20px 20px;
}

.pricing-toggle-description {
  overflow: hidden;
  color: var(--Gray-Darker, #2c2c2e);
  text-overflow: ellipsis;

  /* Text m/Regular */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
  margin-top: 20px;
}

.pricing-counter-amount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pricing-member-counter {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0;
}

.pricing-counter-button {
  display: flex;
  width: 30px;
  padding: var(--Spacing-spacing-2xs, 6px) 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: var(--Radius-radius-3xs, 4px);
  background: var(--Crayola-Blue-Normal, #4674fe);
  border: none;
  color: #fff;
  font-family: "Urbanist";
}

.pricing-counter-value {
  font-size: 16px;
  font-weight: 500;
  min-width: 24px;
  font-family: "Urbanist";
  text-align: center;
}

.pricing-toggle-list {
  list-style: none;
  padding: 0;
  /* margin: 16px 0; */
  color: var(--Gray, #7e7f83);

  /* Text s/Regular */
  font-family: "Urbanist";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pricing-toggle-list li {
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  font-size: 14px;
  color: #666;
}

.pricing-toggle-list li::before {
  content: "•";
  position: absolute;
  left: 8px;
  color: #061427;
}

.pricing-point {
  margin-left: 10px;
}

.pricing-toggle-list-points {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
}

.pricing-point-list {
  display: flex;
  flex-direction: row;
  gap: 6px;
  text-align: left;
}

.pricing-send-invite {
  width: 100%;
  padding: 16px;
  border-radius: 100px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: white;
  color: var(--Gray-700, #344054);
  font-family: "Urbanist";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 155.556% */
  font-family: "Urbanist";
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.pricing-send-invite:hover {
  background-color: #f8f9fa;
}

.pricing-radio-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* margin: 16px 0; */
  font-family: "Urbanist";
  /* overflow: hidden; */
  color: var(--Gray, #7e7f83);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
}

.pricing-radio-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.pricing-radio-label input[type="radio"] {
  width: 16px;
  height: 16px;
  margin: 0;
}

.pricing-radio-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--Spacing-spacing-xl, 20px) 20px;
  border: 1px solid var(--Gray-200, #e4e4e7);
  border-radius: 12px;
  margin-bottom: 10px;
}

.custom-checkbox {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.custom-checkbox input {
  opacity: 0;
  width: 0;
  height: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.custom-checkbox input:checked + .checkmark {
  border: 1px solid var(--Greek-Blue-Normal, #0d5eaf);
  background: var(--Greek-Blue-Normal, #0d5eaf);
}

.checkmark::after {
  content: "✓";
  /* Using the actual checkmark character */
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  /* Green checkmark */
  font-size: 16px;
  /* Adjust size as needed */
  font-weight: bold;
}

.custom-checkbox input:checked + .checkmark::after {
  display: block;
}

.pricing-referalcodeh3 {
  color: var(--Base-Black, #000);

  /* Text s/Medium */
  margin-top: 0px;
  margin-bottom: 6px;
  font-family: "Urbanist";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 19.6px */
}

.pricing-referalcode-input input {
  border: none;
  outline: none;
  width: 100%;
  padding: 8px;
}

.pricing-coupon-message {
  color: #ff0000;
  font-size: 12px;
  margin-top: 8px;
}

.pricing-referalcode-container {
  display: flex;
  min-height: 48px;
  max-height: 48px;
  padding: 5px 12px;
  align-items: center;
  gap: var(--Spacing-spacing-xs, 8px);
  align-self: stretch;
  border-radius: var(--Radius-radius-md, 12px);
  border: 1px solid var(--gray-light-hover, #e4e4e7);
  background: var(--Base-White, #fff);
  margin-bottom: 6px;
}

.error-border {
  border: 1px solid #ff0000;
}

.pricing-referalcode-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pricing-apply-button {
  color: var(--Gray-Dark, #5f5f62);

  /* Text s/Semibold */
  font-family: "Urbanist";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 19.6px */
  letter-spacing: -0.14px;
  border-radius: var(--Radius-radius-md, 12px);
  border: 1px solid var(--gray-light-hover, #e4e4e7);
  background: var(--Gray-Light, #f7f7f7);
  display: flex;
  padding: var(--Spacing-spacing-xs, 8px) var(--Spacing-spacing-md, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-spacing-xs, 8px);
}

.pricing-referalcode-p {
  margin: auto 0px;
  color: var(--Gray-Normal, #7e7f83);

  /* Text m/Medium */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
}

.pricing-referalcode-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pricing-faith-select-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, sans-serif;
}

.pricing-faith-select-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.pricing-faith-select-button:hover {
  border-color: #2196f3;
}

.pricing-faith-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 4px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
}

.pricing-faith-option-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  cursor: pointer;
  font-size: 14px;
}

.pricing-faith-option-item:hover {
  background-color: #f5f5f5;
}

.pricing-faith-nested-option {
  padding-left: 30px;
}

.pricing-faith-nested-option-2 {
  padding-left: 45px;
}

.pricing-faith-nested-option-3 {
  padding-left: 60px;
}

.pricing-faith-chevron {
  transition: transform 0.2s ease;
}

.pricing-faith-chevron.rotate {
  transform: rotate(180deg);
}
