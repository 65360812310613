.success-message-box {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #04D9B5;
    padding: 12px 16px;
    border-radius: 4px;
    margin-top: 16px;
    color: black;
    font-size: 12px;
    position: fixed;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    /* max-width: 200px; */
    justify-content: center;;
  }
  
  .success-message-box img {
    width: 16px;
    height: 16px;
  }
  
