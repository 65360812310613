/* General Layout */
.Resetpasswordh2{
    font-size: 32px;
    font-family: Urbanist;
    font-weight: 500;
    margin-bottom: 20px;
}

.resetpassword-container {
    display: flex;
    height: 100vh;
    font-family: 'Urbanist', sans-serif;
  }
  
 
  .resetpassword-right-section {
    width: 40%;
    padding: 50px;
    background-color: white;
  }
  
  .resetpassword-right-section-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  

  
  .resetpassword-login-button {
    width: 100%;
    background-color: rgba(13, 94, 175, 1);
    color: white;
    padding: 12px;
    margin-top: 20px;
    font-family: 'Urbanist', sans-serif;
    margin-bottom: 10px;
    font-size: 16px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
  }
  
  .resetpassword-login-button:hover {
    background-color: #0056b3;
  }

  .success-message {
    color: rgb(3, 208, 3);
    font-weight: thin;
    font-family: Urbanist;
    margin-top: 10px;
    text-align: center;
    opacity: 0; /* Initially hidden */
    transform: scale(0.8); /* Slightly smaller initially */
    animation: popUp 0.5s ease-out forwards; /* Trigger animation */
  }
  
  /* Link Styling */

  
  /* RESPONSIVE DESIGN */
  @media (max-width: 1024px) {
    .forget-password-container {
      display: block;
      position: relative;
    }
  

    .resetpassword-right-section {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: 70%;
      max-width: 400px;
      padding: 30px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background-color: white;
    }
  }


  @media(max-width:431px){
    .resetpassword-right-section{
      width: 80%;
    }
  }
  