.chat-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 98%;
  max-height: 98%;

  width: 100%;
  overflow-y: scroll;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.chat-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.chat-container {
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */
}

.chat-contacts-initial-insider {
  display: flex;
  width: 2px;
  height: 2px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #005f6d;
  text-align: center;
  font-family: Urbanist;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 9px; /* 37.5% */

  background: linear-gradient(45deg, #9eecff 23%, #a0ffea 100%);

  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 200px;
}

.user-chat-container {
  border-radius: 16px 16px 0px 16px;
  background: var(--Greek-Blue, #0d5eaf);
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 4px;
  width: fit-content;
  max-width: 95%;
  flex-wrap: wrap;

  color: var(--Colors-Text-Primary, #fff);

  /* Text m/Regular */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.ai-chat-hero-container {
  display: flex;
  width: fit-content;
  align-items: flex-end;
  gap: 2px;
}
.ai-chat-container {
  border-radius: 16px 16px 16px 0px;
  background: #e7f3ff;

  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  color: var(--Black, #000);

  /* Text m/Regular */
  font-family: Urbanist;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */

  max-width: 95%;
  flex-wrap: wrap;
}

.aris-chat-icon {
  background: cover no-repeat;
  object-fit: cover;
  width: 40px;
  margin-bottom: -8px;
}

.right {
  align-self: flex-end;
}

.left {
  align-items: flex-start;
}

.chat-in-small-pic {
  border-radius: 50px;
  /* display: flex; */
  width: 20px;
  height: 20px;

  /* align-items: flex-start; */
  /* gap: 8px;   */
}

.chat-initiateRelationship-img-cntr {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  margin-top: 6px;
}
/* HTML: <div class="loader"></div> */
.loader-chat-wait {
  width: 30px;
  aspect-ratio: 2;
  --_g: no-repeat
    radial-gradient(circle closest-side, rgba(70, 116, 254, 0.3) 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

.ai-chat-multi-option-container {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 95%;
  margin-top: 16px;
  margin-left: 40px;
  margin-right: 16px;
}

.ai-chat-option {
  margin-top: 0;
}

.ai-chat-multi-option-label {
  display: flex;
  padding: 8px 14px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 100px;
  background: #e7f3ff;
  color: var(--Marian-Blue, #274079);
  cursor: pointer;

  /* Text s/Medium */
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
}

.ai-chat-multi-option-label:hover {
  background: #d4e7ff;
}

.ai-chat-multi-option-label-link {
  background: var(
    --Gradient-lime,
    linear-gradient(45deg, #bcf5a7 23%, #96f5e0 100%),
    linear-gradient(45deg, #8ad8eb 23%, #82e1cc 100%),
    #82e3cc
  );
}
