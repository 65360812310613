.referral-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .referral-popup {
    background: white;
    border-radius: 16px;
    width: 90%;
    max-width: 400px;
    padding: 24px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  }
  
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
  }
  
 .popup-header-h2{
    color: var(--Base-Black, #000);

/* Text lg/Semibold */
font-family: Urbanist;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 130%; /* 23.4px */
margin: 0px;


 }
  
  .popup-close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
  }
  
  .popup-content-h1 {
    color: var(--Base-Black, #000);

    /* Display m/Medium */
    font-family: Urbanist;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 38.4px */
    letter-spacing: -0.64px;
  }
  
  .description {
    color: #666;
    margin-bottom: 24px;
    line-height: 1.5;
  }
  
  .rewards-section {
    margin-bottom: 24px;
  }
  
  .rewards-section-h3 {
    color: var(--Base-Black, #000);

    /* Text xl/Medium */
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
    letter-spacing: -0.3px;
  }
  
  .reward-item {
    display: flex;
padding: var(--Spacing-spacing-lg, 16px);
/* align-items: center; */
gap: 12px;
align-self: stretch;
color: var(--Base-Black, #000);

/* Text xl/Medium */
font-family: Urbanist;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 28px */
letter-spacing: -0.3px;
border-radius: var(--Radius-radius-md, 12px);
background: var(--Gray-Light, #F7F7F7);
margin-bottom: 8px;
  }
  
  .reward-item.premium {
    display: flex;
padding: var(--Spacing-spacing-lg, 16px);
/* align-items: center; */
gap: 12px;
align-self: stretch;
color: var(--Base-Black, #000);

/* Text xl/Medium */
font-family: Urbanist;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 28px */
letter-spacing: -0.3px;
    background: linear-gradient(to right, #e0f7ff, #f0f9ff);
    border-radius: var(--Radius-radius-md, 12px);
    border: 2px solid var(--Gradient, #4675FE);
    background: var(--Gray-Light, #F7F7F7);
  }


  .referral-header-txt{
    color: var(--Base-Black, #000);
font-feature-settings: 'liga' off, 'clig' off;

/* Text lg/Medium */
font-family: Urbanist;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
letter-spacing: -0.27px;
  }
  

  .referrals-section {
    margin-bottom: 24px;
  }
  
  .referral-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .show-all {
    color: var(--Gray-Dark, #5F5F62);

    /* Text s/Medium */
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    background: inherit;
    border:none;
    display: flex;
    align-items: center;
    gap: 3px;

  }
  
  .referral-avatars {
    display: flex;
    gap: 38px;
  }
  
  .avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
  
  .avatar-circle {
    border-radius: 50%;
    display: flex;
    width: 56px;
    height: 56px;
    padding: var(--Spacing-spacing-xs, 8px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: var(--Midnight-Green-Normal, #005F6D);
    text-align: center;
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
    border-radius: var(--Radius-radius-full, 999px);
background: var(--avatar-gradient-02, linear-gradient(45deg, #9EECFF 23%, #A0FFEA 100%));
position: relative;
  }
  
  .verified-badge {
    position: absolute;
    right: -2px;
    bottom: -2px;
    width: 12px;
    height: 12px;
    background: #0066cc;
    border-radius: 50%;
    border: 2px solid white;
  }
  
  .avatar-name {
    font-size: 12px;
    color: #666;
  }
  
  .invite-button {
    width: 100%;
    border: none;
    display: flex;
    max-height: 52px;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 100px;
background: var(--Greek-Blue-Normal, #0D5EAF);
color: var(--Base-White, #FFF);
font-feature-settings: 'liga' off, 'clig' off;

/* Text lg/Medium */
font-family: Urbanist;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
letter-spacing: -0.27px;
margin-bottom: 16px;
  }
  
  .popup-learn-more {
color: var(--Greek-Blue-Normal, #0D5EAF);

/* Text m/Medium */
font-family: Urbanist;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 137.5% */
width: 100%;
border: none;
background: inherit;
  }