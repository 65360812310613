.pending-content {
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    border-radius: var(--Radius-radius-2xl, 24px);
    padding: 20px;
    padding-top: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .pending-title {
    text-align: left;
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  .request-list {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Display 2 items per row */
    gap: 16px; /* Space between items */
  }
  
  .request-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    background-color: white;
    border-radius: var(--Radius-radius-sm, 10px);
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  .req-sent{
    width: 100% !important;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .name {
    font-size: 14px;
    color: #1a1a1a;
  }
  
  .accept-button {
    background-color: rgba(13, 94, 175, 1);
    color: white;
    border: none;
    border-radius: 100px;
    padding: 10px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .reject-button {
    background: inherit;
    color: #1a1a1a;
    padding: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .reject-button:hover {
    color: #ef4444;
  }

  .phone-bg {
    background-color: #f0f0f0;
  }
  
  /* Media Query for screens smaller than 768px */
  @media (max-width: 768px) {
    .pending-content {
    height: 100dvh; /* Full width on mobile */
    padding: 15px; /* Adjust padding for mobile */
    border-radius: var(--Radius-radius-2xl, 24px);
  }
    .request-list {
      grid-template-columns: 1fr; /* Stack the requests vertically */
    }
  }
  

  /* Modal Background */
.accept-form-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal is on top */
}

.accept-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

/* Close Button */
.accept-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #333;
  cursor: pointer;
}

.accept-close-button:hover {
  color: red;
}

/* Form Fields Styling */
.accept-form-modal form {
  display: flex;
  flex-direction: column;

}

.accept-form-modal form div {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  align-items: flex-start;
}

.accept-form-modal form label {
  font-weight: bold;
}

.accept-form-modal form input {
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;

}

.accept-form-modal form input:focus {
 
  outline: none;
}

/* Buttons */
.accept-submit-button, .accept-cancel-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.accept-submit-button {
  background-color: #007bff;
  color: white;
}

.accept-submit-button:hover {
  background-color: #0056b3;
}

.accept-cancel-button {
  background-color: #f0f0f0;
  color: #333;
}

.accept-cancel-button:hover {
  background-color: #ccc;
}



/* Container for the modal */
.accept-request-modal {
  width: 90%;
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 756px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Modal above the overlay */
  border-radius: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.active-pending-request{
  border-radius: 16px !important;
}

.pending-badge{
  background: var(--Transperency, rgba(237, 241, 255, 0.20)) !important;
  color: #fff !important;
}

.pending-badge2{
  background: var(--Red-500, #FF5F4D) !important;

}

.request-user-details{
  display: flex;
  align-items: center;
  justify-content: space-between;
width: 90%;
}


.sentreqedit{
  width: 800px;
  max-width: 800px !important;
  height: auto;
  max-height: 622.42px;

}

.sendreq-close-container{
  width: 100%;
  text-align: right;
}

@media(max-width:800px)
{

  
  .sentreqedit{
  overflow-y: scroll;}
}




@media (max-width: 768px) {
  .accept-request-modal {
    height: 90%;
    width: 100%;
    overflow-y: scroll; /* Enable scrolling */
    scrollbar-width: none; /* For Firefox to hide scrollbar */
    border-radius: 24px 24px 0px 0px;
  }

  /* Webkit-based browsers */
  .accept-request-modal::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Webkit browsers (Chrome, Safari) */
  }
}

/* Overlay for background blur */
.pending-modal-overlay {
  position: fixed; /* Ensure it covers the entire screen */
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1000; /* Behind the modal */
}

@media (max-width: 768px) {
  .pending-modal-overlay {
    display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  }
}


.accept-modal-content-pending {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: inherit;
}

.accept-close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  font-weight: bold;
  color: #555;
  cursor: pointer;
  align-self: flex-end;
}

.accept-close-button:hover {
  color: #000;
}

.accept-modal h3 {
  text-align: center;
  font-family: "Urbanist", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #1a202c;
  margin:0px;
  margin-bottom: 10px;
}

.accept-submit-button {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
}

.accept-submit-button:hover {
  background-color: #45a049;
}


.profile-initials {
  width: 50px;
  height: 50px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 600;
    color: #005f6d;
    background: linear-gradient(45deg, #9eecff 23%, #a0ffea);
}

.edit-bg-sent{
  background-color: #fff !important;
  padding: 20px;
}


@media(max-width:414px){
  .pending-txt-switch{
    font-size: 12px !important;
  }
}