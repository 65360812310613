.edit-container {
  padding: 24px;
  background: white;
  max-width: 800px;
  margin: 0 auto;
  border-radius: var(--Radius-radius-2xl, 24px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.edit-profile-section {
  display: flex;
  flex-direction: column;
  /* align-items: left; */
  margin-bottom: 32px;
}
.edit-profile-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.edit-profile-image,
.edit-profile-initials {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-profile-image img.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.edit-profile-initials {
  background: #e3f2fd;
  color: #1976d2;
  font-size: 1rem;
  font-weight: 600;
}

img.default-profile {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.edit-photo-text {
  color: #090909;
  font-size: 14px;
  cursor: pointer;
  margin-top: 8px;
}

.edit-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-family: "Urbanist";
  font-style: normal;
  font-size: 14px;
}

.edit-form-grid {
  display: grid;

  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
}

.edit-form-field {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
}

.edit-form-field input,
.edit-form-field select {
  width: 100%;
  padding: var(--Spacing-spacing-lg, 16px) var(--Spacing-spacing-md, 12px);
  border: 1px solid #e4e4e7;
  border-radius: 12px;
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  color: #1a202c;
  background-color: white;
  max-width: 100%;
  box-sizing: border-box;
  color: var(--Base-Black, #000);
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.edit-form-field input:focus {
  outline: none;
}

.edit-form label {
  color: var(--Base-Black, #000);

  /* Text s/Medium */
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
}

@media (max-width: 768px) {
  .edit-form-grid {
    grid-template-columns: 1fr;
  }
}

.edit-form-actions {
  display: flex;
}

.edit-person-btn {
  max-width: 200px;
  border: none;
  background-color: rgba(
    224,
    231,
    245,
    1
  ); /* Light background when not filled */
  color: rgba(116, 148, 210, 1);
  border-radius: 25px !important;
  padding: 10px 20px !important;
  text-transform: none !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  display: flex;
  font-family: "Urbanist", sans-serif;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Space between text and image */
  cursor: pointer;
}

.edit-person-btn.filled {
  background-color: rgba(13, 94, 175, 1); /* Blue when filled */
  color: white;
}

.nxtbtn {
  width: 20px;
  height: 20px;
}

.edit-delete-button {
  background: none;
  color: #ef4444;
  border: none;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  cursor: pointer;
  height: 48px;
}

/* Modal Overlay */
.dlt-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  font-family: "Urbanist", sans-serif;
}

/* Modal Content */
.dlt-modal-content {
  background-color: #fff;
  width: 400px;
  padding: 20px;
  margin: 10px;
  border-radius: 24px;
  position: relative;
  text-align: left;
  font-family: "Urbanist", sans-serif;
}

.dlt-modal-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dlt-modal-content h3 {
  width: 268px;
  color: var(--Black, #000);
  margin: 0px;
  /* Display xs/Semibold */
  font-family: "Urbanist";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.dlt-modal-content-para {
  color: var(--Gray-Normal, #7e7f83);
  width: 343px;
  /* Text m/Regular */
  font-family: "Urbanist";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  margin-bottom: 24px;
}

@media (max-width: 476px) {
  .dlt-modal-overlay {
    align-items: flex-end;
    position: fixed;
  }
  .dlt-modal-content {
    /* display: flex; */
    border-radius: 24px 24px 0px 0px;
    margin: 0px;
  }
}

/* Close Button */
.dlt-modal-close-btn {
  position: absolute;
  top: 20px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

/* Actions */
.dlt-modal-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* Buttons */
.dlt-modal-cancel-btn {
  display: flex;
  max-height: 52px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  color: var(--Gray-Darker, #2c2c2e);
  font-feature-settings: "liga" off, "clig" off;

  /* Text lg/Medium */
  font-family: "Urbanist";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.27px;
  border-radius: 100px;
  border: 1px solid var(--gray-light-hover, #e4e4e7);
  background: var(--Base-White, #fff);
}

.dlt-modal-confirm-btn {
  flex: 1;
  padding: 10px;
  background: #ff5f5f;
  border: none;
  color: var(--Base-White, #fff);
  font-feature-settings: "liga" off, "clig" off;
  border-radius: 30px;
  /* Text lg/Medium */
  font-family: "Urbanist";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.27px;
  cursor: pointer;
}
