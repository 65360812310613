/* General Layout */
.forget-password-container {
    display: flex;
    height: 100vh;
    font-family: 'Urbanist', sans-serif;
  }
  
  .forgetpassword-close-icon{
    /* padding: 20px; */
    border: #7c7c7c;
    border-radius: 50%;
    background: inherit;
  }
 
  .forgetpassword-right-section {
    width: 40%;
    padding: 50px;
    background-color: white;
  }
  
  .forgetpassword-right-section-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .reset-closeButton-container {
    background-color: aqua;
  }

  
  .forgetpassword-login-button {
    width: 100%;
    background-color: rgba(13, 94, 175, 1);
    color: white;
    padding: 20px 24px;
    margin-top: 20px;
    font-family: 'Urbanist', sans-serif;
    margin-bottom: 10px;
    font-size: 16px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
  }
  
  .forgetpassword-login-button:hover {
    background-color: #0056b3;
  }
  
   


  
  /* RESPONSIVE DESIGN */
  @media (max-width: 1024px) {
    .forget-password-container {
      display: block;
      position: relative;
    }
  

    .forgetpassword-right-section {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      height: 70%;
      max-width: 400px;
      padding: 30px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background-color: white;
    }
  }


  @media (max-width:431px){
    .forgetpassword-right-section{
      width: 80%;
    }
  }
 

  .error-message {
    color: red;
    margin-top: 5px;
    font-size: 14px;
  }
  
  .success-message {
    color: rgb(3, 208, 3);
    font-weight: thin;
    font-family: Urbanist;
    margin-top: 10px;
    text-align: center;
    opacity: 0; /* Initially hidden */
    transform: scale(0.8); /* Slightly smaller initially */
    animation: popUp 0.5s ease-out forwards; /* Trigger animation */
  }
  
  /* Define the popUp animation */
  @keyframes popUp {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    50% {
      opacity: 1;
      transform: scale(1.1); /* Slight overshoot for the pop effect */
    }
    100% {
      opacity: 1;
      transform: scale(1); /* Back to normal size */
    }
  }
  
  
  

  .forget-passwordh2{
    font-size: 32px;
    font-family: Urbanist;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .reset-closeButton-container {
    background-color: aqua;
  }

  .forgetpassword-right-section-content div .forgotpassword-close-container {
    width: '52px';
    height: '52px';
    border: '1px solid rgba(228, 228, 231, 1)';
    border-radius: '50%';
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
  }