@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400;500;600;700&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* KAVIYAPRIYA */

.gradient-background {
  background: conic-gradient(
      from 130deg at -4.63% 74.02%,
      #05d6b6 0deg,
      #4675fe 360deg
    ),
    linear-gradient(to right, #1afafa, #010db3),
    linear-gradient(to bottom, #98cdf9 0%, #ffffff 100%);

  background-blend-mode: overlay;
  width: 100%;
  height: 20dvh;

  padding: 0px;

  text-align: center;
  margin-bottom: 0px;
  font-family: "Urbanist", sans-serif;
}

/* #background {
  position: absolute;
  width: 100%;
  top: -300px;
}

#oval1 {
  width: 100%;
  min-height: 200px;
  border-radius: 1571.251px;
  background: conic-gradient(from 187deg at 50% 50%, #00FFD8 0deg, #0041FF 360deg);
  filter: blur(150px);
}

#oval2 {
  width: 100%;
  min-height: 200px;
  border-radius: 1571.251px;
  background: conic-gradient(from 178deg at 50% 50%, #05D6B6 0deg, #4675FE 360deg);
  filter: blur(50px);
} */

#background {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  font-family: "Urbanist", sans-serif;
  text-align: center;
}

#oval1,
#oval2 {
  position: absolute;
  width: 100%;
  height: 200px;
  border-radius: 1571.251px;
}

#oval1 {
  top: -200px;
  background: conic-gradient(
    from 187deg at 50% 50%,
    #00ffd8 0deg,
    #0041ff 360deg
  );
  filter: blur(150px);
  z-index: -1;
}

#oval2 {
  top: -100px;
  background: conic-gradient(
    from 178deg at 50% 50%,
    #05d6b6 0deg,
    #4675fe 360deg
  );
  filter: blur(50px);
  z-index: -1;
}

.logo {
  margin-top: 25px;
  margin-bottom: 15px;
  width: 30px;
  height: 30px;
}

.submit-button {
  width: 100%;
  padding: 16px 28px;

  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  border-radius: 100px;
  border: 1px solid #e0e7f5;
  background: #e0e7f5;
  color: #7494d2;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Urbanist", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: -0.27px;
  margin-bottom: 20px;
}

.submit-button.valid {
  background: rgba(13, 94, 175, 1);
  color: white;
}

.submit-button.valid .nxtbtn {
  content: url("./Assets/images/arrow-right.png");
}

.submit-button.invalid {
  background: rgba(224, 231, 245, 1);
  color: rgba(116, 148, 210, 1);
}

.nxtbtn {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

/* 
 */

.lable {
  width: 436px;
  color: #7e7f83;
  font-size: 16px;
  font-family: Urbanist;
  font-weight: 500;
  line-height: 22px;
  word-wrap: break-word;
}

.error-message {
  color: red;
  font-family: "Urbanist", sans-serif;
}

/* @media (max-width: 768px) {
 .gradient-background{
  height: 60dvh;
 }
} */

/* 
@media (max-width: 600px) {
  .submit-button {
    padding: 10px 120px; 
    max-width: 100%; 
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .submit-button {
   padding: 10px 140px;
    max-width: 80%; 
  }
}
 */

#background-in {
  position: absolute;
  width: 100%;
  top: -300px;
}
#oval-in-1 {
  width: 100%;
  min-height: 200px;
  border-radius: 1571.251px;
  background: conic-gradient(
    from 187deg at 50% 50%,
    #00ffd8 0deg,
    #0041ff 360deg
  );
  filter: blur(150px);
}

#oval-in-2 {
  width: 100%;
  min-height: 200px;
  border-radius: 1571.251px;
  border-radius: 1571.251px;
  background: conic-gradient(
    from 178deg at 50% 50%,
    #05d6b6 0deg,
    #4675fe 360deg
  );
  filter: blur(50px);
}

.CircularProgressbar {
  width: auto;
}
.circular-progress-story {
  width: 100% !important;
}
