.smart-habit-modal-header {
    display: flex;
    justify-content: space-between;
}
.summary-modal-header-title {
    flex-basis: 75%;
    font-weight: 500;
    font-size: 24px;
}
.smart-habbit-input-text {
    font-weight: 500;
    font-size: 16px
}