.back-btn-continer {
    display: flex;

}

.back-btn {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: rgba(13, 94, 175, 1);
    font-weight: 500;
    font-size: 16px;
    padding: 0px;
    font-family: "Urbanist";
    margin-bottom: 6px;
}

.arrow-left {
    padding-right: 1px;
    width: 15px;
    height: 15px;
}

.btn-continer {
    display: flex;
    justify-self: center;
    align-items: center;
    text-align: center;
}