/* components/VoiceRecorder/styles.css */
.voice-recorder-container {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
  }
  
  .record-button {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 8px;
    padding: 16px 0px;
    border: none;
    border-radius: 8px;
    background-color: white;
    color: #4a5568;
    transition: all 0.2s ease;
    cursor: pointer;
    font-size: 14px;
    margin: 10px 0px;
  }
  
  .record-button .mic-icon {
    width: 20px;
    height: 20px;
    stroke: #000;
   
  }


  .mic-rec-button{
    color: var(--Black, #000);

/* Text s/Regular */
font-family: "Urbanist",sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
  }
  
  .record-button.recording {
    padding: 10px;
      background-color: #fee2e2;
      color: #dc2626;
      border: 1px solid #f85656;
  }
  
  .record-button:hover {
    background-color: #f8fafc;
  }
  
  .record-button.recording:hover {
    background-color: #fef2f2;
  }
  
  .record-button .pulse {
    animation: pulse 1.5s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .recording-complete {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
  }
  
  .audio-player {
    height: 40px;
    flex-grow: 0.05;
  }
  
  .edit-button {
    padding: 8px;
    border: none;
    background: none;
    color: #4a5568;
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .edit-button:hover {
    color: #1a202c;
  }
  
  .edit-button .icon {
    width: 20px;
    height: 20px;
  }


  

