.onboarding-rec-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.onboarding-rec-modal {
  background: #fff;
  border-radius: 16px;
  width: 350px;
  padding: 44px 16px 32px 16px;
  margin: 10px;
  text-align: center;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.identifies-s1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.onboarding-rec-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: inherit;
  border: none;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.onboarding-rec-group-shade {
  position: absolute;
  top: 0px;
  left: 90px;
}

.onboarding-rec-icon-container {
  margin-top: 10px;
  margin-bottom: 16px;

  position: relative;
  z-index: 1;
}

.onboarding-rec-microphone-icon {
  width: 60px;
  height: 60px;
  background: var(
    --Gradient,
    linear-gradient(168deg, #4675fe 6.98%, #05d6b6 102.55%)
  );
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
}

.onboarding-rec-mic-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.guidance-text {
  color: red !important;
}

.onboarding-rec-recording-animation {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 5px solid rgba(70, 116, 254, 1);
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.onboarding-rec-modal-title {
  color: var(--Black, #000);
  text-align: center;
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin-top: 15px;
}

.onboarding-rec-timer {
  margin: 10px 0;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.onboarding-rec-start-button,
.onboarding-rec-stop-button,
.onboarding-rec-maybe-later {
  border-radius: 56px;
  background: #e7f3ff;
  border: none;
  width: 100%;
  padding: 10px 20px;
  color: var(--Greek-Blue, #0d5eaf);
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  cursor: pointer;
  margin-top: 20px;
}

.onboarding-rec-stop-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid #0d5eaf;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  display: flex;
}

.onboarding-rec-start-button:hover,
.onboarding-rec-stop-button:hover {
  background: #e7f3ff;
}

.onboarding-rec-maybe-later {
  background: transparent;
  color: var(--Gray, #7e7f83);
  text-align: center;
  font-family: Urbanist;
  font-size: 15px;
  font-style: normal;
}

/* Container for the mic icon */

/* Mic icon styling */

/* Static strokes (rings) */
.onboarding-rec-microphone-icon::before,
.onboarding-rec-microphone-icon::after,
.onboarding-rec-microphone-icon .stroke {
  /* content: ''; */
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid #3a86ff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

/* First stroke */
.onboarding-rec-microphone-icon::before {
  width: 80px;
  height: 80px;
  stroke: var(--Crayola-Blue, #4674fe);
  opacity: 0.2;
}

/* Second stroke */
.onboarding-rec-microphone-icon::after {
  width: 100px;
  height: 100px;
  stroke: var(--Crayola-Blue, #4674fe);
  opacity: 0.15;
}

/* Third stroke */
.onboarding-rec-microphone-icon .stroke {
  width: 120px;
  height: 120px;
  stroke: var(--Crayola-Blue, #4674fe);
  opacity: 0.1;
}

.mic-permission-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.mic-permission-modal {
  background-color: white;
  border-radius: 16px;
  width: 90%;
  max-width: 300px;
  padding: 16px;
  position: relative;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mic-permission-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.mic-permission-content-header {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.mic-permission-icon {
  width: 64px;
  height: 64px;
}

.mic-permission-content h2 {
  color: #000;
  font-size: 17px;
  font-style: "Urbanist", sans-serif;
  font-weight: 600;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.41px;
  text-transform: capitalize;

  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
}

.mic-permission-content p {
  text-align: left;
  color: #000;
  font-family: Urbanist;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.08px;
}

.mic-permission-buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.mic-permission-button {
  color: var(--Sky-Blue-Normal, #1cb0f6);
  text-align: center;
  font-family: "Urbanist", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.41px;
  text-transform: capitalize;
  border-radius: 5px;
  border: 1px solid #d2d2d2;
  padding: 4px 10px;
  background: rgba(248, 248, 248, 0.92);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .mic-permission-modal {
    width: 95%;
    padding: 24px;
  }

  .mic-permission-icon {
    width: 48px;
    height: 48px;
  }

  .mic-permission-content h2 {
    font-size: 18px;
  }

  .mic-permission-content p {
    font-size: 14px;
  }
}
