.notification-hero-container {
  position: absolute;
  top: 60px;
  right: 90px;

  display: flex;
  width: 374px;
  height: 50dvh;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  border-radius: 24px;
  background: #fff;
  max-height: 50dvh;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.head-notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.cur-pt {
  cursor: pointer;
}
.head-not-txt {
  color: #000;

  /* Display m/Medium */
  font-family: Urbanist;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.3px;
  /* margin-right: 8px; */
}

.not-tab-selection-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.not-tab-container {
  display: flex;
  padding: 8px 8px 16px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  cursor: pointer;
  border-bottom: 2px solid var(--Greek-Blue, #0d5eaf);

  color: var(--Greek-Blue, #0d5eaf);
  text-align: center;

  /* Text sm/Medium */
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.21px;
}

.not-tab-container-non-active {
  cursor: pointer;

  display: flex;
  padding: 8px 8px 16px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  border-bottom: 2px solid rgba(0, 0, 0, 0.1);

  color: rgba(19, 19, 22, 0.5);
  text-align: center;

  /* Text sm/Regular */
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.21px;
}

.notify-container-hero {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  background: var(--Gray-Light, #f7f7f7);
  cursor: pointer;
  padding: var(--Spacing-spacing-xs, 8px);
  width: 92%;
}

.notify-left {
  display: flex;
  padding: var(--Spacing-spacing-md, 12px);
  align-items: center;
  gap: 8px;
  border-radius: var(--Radius-radius-lg, 16px);
  background: var(
    --tile-icon-gradient-02,
    linear-gradient(45deg, #85dfff 0%, #cfb2ff 77%)
  );
}

.notify-mid {
  display: flex;
  flex-direction: column;
}

.notify-cnt-time {
  color: var(--Gray-500, #667085);

  /* Text xs/Normal */
  font-family: Inter;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.notify-cnt-head {
  color: var(--Base-Black, #000);
  font-feature-settings: "liga" off, "clig" off;

  /* Text lg/Medium */
  font-family: Urbanist;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.27px;
}

.notify-cnt-sub,
.notify-cnt-message {
  color: var(--Gray-Normal, #7e7f83);

  /* Text s/Regular */
  font-family: Urbanist;
  font-size: 0.82rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}

.notify-cnt-message::before {
  content: '"';
}

.notify-cnt-message::after {
  content: '"';
}

.notify-right {
  display: flex;
  padding: 4px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;

  border-radius: 100px;
  background: var(--Base-White, #fff);
}

.notify-mobile {
  display: none;
}

.not-phone {
  background: var(--White, #fff);
  flex-grow: 1;
}

.notify-cnt {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  max-height: calc(50dvh - 120px); /* Subtract header height and padding */
  /* padding-right: 8px; */
  /* padding: 12px; */
  align-items: center;
  /* width: 80%; */
}

.notify-cnt::-webkit-scrollbar {
  width: 6px;
}

.notify-cnt::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.notify-cnt::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.loader-df-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  overflow: hidden;
}

.loader {
  text-align: center;
  padding: 10px;
  font-family: "Work Sans";
  color: #667085;
}

.no-notifications {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #667085;
  font-size: 18px;
  padding: 1rem 2rem;
}

@media (max-width: 600px) {
  .notify-mobile {
    display: flex;
    flex-direction: column;
    width: 100dvw;

    position: fixed;
    /* box-sizing: border-box; */
    height: 100dvh !important;
    min-height: 100dvh !important;
    top: 0 !important;
    left: 0;
    border-radius: 0;
    border: none;
    z-index: 1000;
    background-color: #fff;
  }

  .notification-hero-container {
    display: none;
  }

  .termsofuse-logo-container-notification {
    width: 100%;
    display: flex;
    border-radius: 0;
    background-color: transparent;
    margin-bottom: 8px;
    padding: 0;
    margin-left: 0;
    justify-content: space-between;
  }

  .not-phone .notify-cnt {
    max-height: calc(100dvh - 180px);
    overflow-y: auto;
    padding: 24px;
  }
}

@media (max-width: 480px) {
  .head-not-txt {
    margin-top: 6dvh;
  }
}
