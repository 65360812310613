/* Overlay styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay */
  backdrop-filter: blur(8px); /* Apply blur effect */
  z-index: 999; /* Behind the popup */
  visibility: hidden; /* Hidden by default */
  opacity: 0; /* Transparent */
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Popup container */
.mobile-pop-up-container {
  position: fixed;
  bottom: 0px;
  left: 0;
  min-height: 200px;
  max-height: 98dvh;
  width: 100%;
  background-color: #fff;
  border-radius: 24px 24px 0 0;
  padding: 5px 16px 16px 16px;
  z-index: 1000;
  backdrop-filter: blur(50px);
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: scroll;
  /* justify-content: center; */
}

/* Content inside the popup */
.mobile-pop-up-content {
}

.mobile-pop-up-container-close {
  text-align: end;
  align-self: end;
}

.mobile-popup-top-indicator {
  width: 32px;
  height: 4px;
  border-radius: 10px;
  background: #d9d9d9;
}

.mobile-popup-top-indicator-2 {
  margin-top: 20px;
  width: 134px;
  height: 5px;
  flex-shrink: 0;
  border-radius: 100px;
  background: var(--Base-Black, #000);
}

/* Show the overlay */
.overlay.visible {
  visibility: visible;
  opacity: 1; /* Make it visible */
}

/* Show the popup */
.mobile-pop-up-container.visible {
  transform: translateY(0); /* Slide it into view */
}
