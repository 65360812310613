.margin-allset {
    margin-top: 0px;
    height: 222px;
    width: 70%;
}

.failure-payment {
    border-radius: var(--Radius-radius-full, 999px);
    background: rgba(255, 255, 255, 0.20);

    /* Dialog/Background Blur */
    backdrop-filter: blur(13.591408729553223px);
    display: flex;
    width: 50px;
    height: 50px;
    padding: 23.4px 22.5px 22.6px 23.5px;
    justify-content: center;
    align-items: center;
}

.allset-txt {
    top: 30vh;
    /* width: 70%; */
}

.allset-txter {
    width: 90%;
    margin-top: 8px;
}

.transform-up {
    transform: translateY(-35%)
}

.allset-btn {
    margin-top: 50px;
}

.allset-container {
    height: auto;
}

.assess-options-container-eng-allset {
    /* position: absolute; */
    top: 52vh;
    transform: translateY(-35%);
    z-index: 2;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* width: 33%; */
}

@media(max-width:600px) {
    .allset-txt {
        top: 30vh !important;
        width: 90% !important;
    }
}


@media(max-width:330px) {
    .allset-txt {

        width: 90% !important;

    }

    .allset-title {
        font-size: 32px;
    }

    .allset-subtext {
        font-size: 16px;
    }
}