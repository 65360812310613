.termsofuse-app * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.notification-header-text {
  color: var(--Base-Black, #000);

  /* Display m/Medium */
  font-family: "Urbanist";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.64px;
}

.termsofuse-gradient-background {
  /* background: conic-gradient(
    from 130deg at -4.63% 74.02%,
    #05d6b6 0deg,
    #4675fe 360deg
  ),
  linear-gradient(to right, #1afafa, #010db3),
  linear-gradient(to bottom, #98cdf9 0%, #ffffff 100%); */

/* background-blend-mode: overlay; */
width: 100%;
height: 20dvh;

padding: 0px;

text-align: center;
margin-bottom: 0px;
font-family: "Urbanist", sans-serif;
}

.termsofuse-nav-container {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.termsofuse-logo-container {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #ffffff;
}

.termsofuse-space {
  width: 3rem;
  height: 3rem;
}

.termsofuse-last-updated {
color: var(--Gray-600, #475467);
 text-align: center;
 margin-top: 30px;
 
 /* Text sm/Regular */
 font-family: "Work Sans";
 font-size: 14px;
 font-style: normal;
 font-weight: 400;
 line-height: 20px; /* 142.857% */
 letter-spacing: -0.21px;
}

.termsofuse-content {
  padding-top: 20px;
  padding-bottom: 30px;
  white-space: pre-wrap;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #475467;
  border-top: 1px solid #b9b9b9;
  font-size: 12px;
  line-height: 20px;
}

.termsofuse-title {
 color: var(--Black, #000);
text-align: center;

/* Display m/Semibold */
font-family: Urbanist;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 38px; /* 118.75% */
}

/* Desktop View */
@media screen and (min-width: 769px) {
  .termsofuse-last-updated {
    padding-top: 10px;
  }
}
.termsofuse-navigate-bottom {
  display: none;
}
.termsofuse-nav-container {
  padding: 1rem 1.5rem;
}
.termsofuse-content {
  font-size: 12px;
  line-height: 20px;
}

/* Tablet View */
@media screen and (max-width: 480px) {
  /* .termsofuse-gradient-background {
    min-height: 25dvh;
  } */

 

  .termsofuse-last-updated {
    text-align: left;
    font-size: 0.9375rem;
    padding: 0 1rem;
  }
  .termsofuse-space {
    display: none;
  }

  .termsofuse-content {
    width: 90%;
    text-align: left;
    margin: 1rem;
    font-size: 0.9375rem;
  }

  .termsofuse-nav-container {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    padding-bottom: 0px;
  }

  .termsofuse-logo-container {
    border-radius: 0;
    margin-bottom: 10px;
    background-color: transparent;
    padding: 0;
    justify-content: left;
  }
}

/* Mobile View */
@media screen and (max-width: 480px) {
  /* .termsofuse-gradient-background {
    min-height: 25dvh;
  } */
  .termsofuse-title {
    font-size: 32px;
    /* font-weight: 900; */
    margin-top: 10px;
    text-align: left;
  }

  .termsofuse-logo-container {
    border-radius: 0;
    background-color: transparent;
    margin-bottom: 8px;
    padding: 0;
    margin-left: 0;
    justify-content: left;
  }
  .termsofuse-hr {
    margin: 1rem 1.5rem;
    border-top: 1px solid #b9b9b9;
  }
  .termsofuse-last-updated {
    /* font-size: 0.875rem; */
    text-align: left;
    padding: 0 1rem;
    margin-top: 10px;
  }
  .termsofuse-space {
    display: none;
  }

  .termsofuse-content {
    margin: 1rem;
    padding-top: 1rem;
    text-align: left;
    font-size: 0.875rem;
  }

  .termsofuse-nav-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .termsofuse-navigate-icon {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 2rem 1rem;
  }
  .termsofuse-navigate-icon div {
    background-color: #4674fe;
    border-radius: 50%;
    padding: 0.75rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .termsofuse-navigate-bottom {
    display: block;
    position: fixed;
    right: 1rem;
    top: 90%;
    z-index: 10;
  }

  .termsofuse-navigate-bottom div {
    background-color: #4674fe;
    border-radius: 50%;
    padding: 0.75rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
