.most-least-dnd-cont {
  width: 40%;
  position: relative;
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px dashed #000;
  background: #05d6b6;
  /* Make drop targets more visible during drag */
  transition: background-color 0.2s ease;
}

/* Add a new class for drop targets */
.most-least-dnd-cont.drop-target {
  background-color: rgba(5, 214, 182, 0.5);
  border: 2px dashed #000;
}

.dnd-main-cnt {
  width: 100%;
  min-height: 50px;
  /* Ensure empty containers have height */
}

.bg-dull {
  background: rgba(5, 214, 182, 0.26) !important;
}

.drag-element-container {
  position: relative;
  min-height: 30px;
  width: 90%;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: #f7f7f7;
  cursor: grab;
  touch-action: none;
  scale: 1 !important;
  /* Added for better drag behavior */
  z-index: 1;
  transform-origin: center center;
  will-change: transform;
}

.dnd-opt-head {
  color: #000;
  text-align: center;
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Limit width for better control */
  max-width: calc(100% - 24px);
}

.dnd-opt-head-title {
  text-transform: uppercase;
  /* Prevent text from wrapping during drag */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dnd-desc {
  color: var(--Gray-Dark, #5f5f62);
  font-family: "Urbanist";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-align: left;
}

.dnd-decs-insider {
  display: none;
}

.reduced-head-size {
  font-size: 14px;
}

.dnd-most-least-txt {
  color: #000;
  font-family: "Urbanist";
  font-size: 16px;
  font-weight: 500;
}

.column-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.center-column {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2px;
}

@media (max-width: 420px) {
  .reduced-head-size {
    font-size: 11px;
  }
}

/* Base styles for drag element */
.drag-element-container {
  cursor: grab;
  user-select: none;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

/* Fixed width and appearance during dragging */
.dragging {
  cursor: grabbing !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  max-width: 150px !important;
  width: 150px !important;
  scale: 1 !important;
  z-index: 1000;
}

/* Improve text handling during drag */
.dragging .dnd-opt-head {
  width: 130px !important;
  /* Reduced to account for padding */
  max-width: 130px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Text styling in dragged item */
.dragging .dnd-opt-head-title {
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 115px;
}

/* Improved body styles during dragging */
body.is-dragging {
  cursor: grabbing !important;
}

body.is-dragging * {
  /* Removed transform-style: preserve-3d which can cause positioning issues */
}

/* Ensure the dragged element follows cursor properly */
body.is-dragging .drag-element-container.dragging {
  pointer-events: none;
  position: fixed;
  /* This helps with positioning */
  margin: 0;
  /* Remove margins that could affect positioning */
  transform-origin: 50% 50% !important;
}

.column {
  flex-grow: 0 !important;
  min-width: 200px;
  transition: width 0.2s ease, background-color 0.2s ease;
}

.column.drag-over {
  width: auto !important;
  background-color: rgba(5, 214, 182, 0.1);
}

/* Improve accessibility */
.drag-element-container:focus-visible {
  outline: 2px solid #0066ff;
  outline-offset: 2px;
}

.solid-border {
  border: 1px solid #000;
}