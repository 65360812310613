.mobile-sub-txt-set-phone {
  text-align: left;
  /* padding: 0 1rem; */
}

.mobile-set-cnt {
  margin-top: 10px;
  padding: 1rem;
}

.changepassword{
  margin-top: 16px;
}