.onboarding-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100dvh;

}

.onboarding-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aris-text {
  font-size: 1rem;
  color: #fff;
  margin-top: 0px;
  width: 290px;
  text-align: center;
  font-family: "Urbanist", sans-serif;
}

.responsive-video {
  width: 60%; /* Adjust width relative to the screen */
  max-width: 600px; /* Optional: Set a maximum width */
  height: auto; /* Maintain aspect ratio */
  margin: 0 auto; /* Center the video */
  display: block; /* Ensure proper centering */
}

.step6-button-style {
  display: flex;
  width: 337px;
  max-height: 52px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Base-Black, #000);
  font-feature-settings: "liga" off, "clig" off;
  /* Text lg/Medium */
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.27px;
  border-radius: 100px;
  background: var(--Base-White, #fff);
  border: none;
  margin-top: 10px;
  cursor: pointer;
}

.onboarding-aboutaris {
  background: inherit;
  color: #fff;
}

.onboarding-aboutaris:hover {
  background: var(--Base-White, #fff);
  color: #000;
}

.static-text-step7 {
  color: var(--White, #fff);
  text-align: center;

  /* Display xl/Semibold */
  font-family: Urbanist;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 48px */
  margin-bottom: 15px;
  margin-top: 0px;
}
