.profile-coaching-history-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.profile-coaching-history-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.profile-coaching-history-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}
.profile-coaching-history-header-content-back-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    border: 1px solid #E4E4E7;
    padding: 12px;
    border-radius: 50%;
    cursor: pointer;
}
.profile-coaching-history-header-content-title {
    font-weight: 600;
    font-size: 32px;
}
.profile-coaching-history-header-content-text {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
}
.profile-coaching-history-filter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.profile-container-history-filter-item {
    padding: 4px 8px;
    border: 1px solid #E4E4E7;
    border-radius: 100px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
}
.profile-container-history-filter-item.active {
    background-color: #4674FE;
    color: white;
    border: none;
}
.profile-container-history-items-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profile-coaching-history-list-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-bottom: 1rem;
}
.profile-coaching-history-list-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 812px;
}
.profile-coaching-history-list-time {
    font-weight: 600;
    font-size: 24px;
    text-transform: capitalize;
}
.profile-coaching-history-list-item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;
}
.profile-coaching-history-list-item-content-left{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 80%;
}
.profile-coaching-history-list-item-content-title {
    font-weight: 500;
    font-size: 18px;
}
.profile-coaching-history-list-item-content-description {
    font-weight: 400;
    font-size: 16px;
    color: #7E7F83;
}
.profile-coaching-history-list-item-content-right {
    color:#00A3FF;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}
.no-items-message {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    color: #888;
}

@media screen and (max-width: 768px) {
    .profile-coaching-history-list-item-content {
        gap: 2rem;
    }
    .profile-coaching-history-list-item-content-left {
        flex-basis: 75%;
    }
    .profile-container-history-items-container .filler-div, .profile-container-history-items-container .filler-div {
        flex-basis: 25%;
    }
}

@media screen and (max-width: 480px) {
    .profile-coaching-history-header-content-title {
        font-weight: 600;
        font-size: 18px;
        color: #FFFFFF;
    }
    .profile-coaching-history-header-content-back-button-container {
        background-color: transparent;
        border: none;
        filter: invert(1);
    }
    .profile-coaching-history-header-content-text {
        background-color: #FFFFFF;
        padding: 2rem 1rem 1rem 1rem;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
    }
    .profile-coaching-history-filter-container {
        background-color: #FFFFFF;
    }
    .profile-coaching-history-container {
        gap: 0.5rem;
    }
    .profile-container-history-items-container .filler-div, .profile-container-history-items-container .filler-div {
        flex-basis: 10%;
    }
    .profile-coaching-history-header-content-text {
        font-weight: 600;
    }
    .profile-container-history-items-container {
        margin-top: 1rem;
    }
    .profile-container-history-filter-item {
        padding: 4px 12px;
    }
    .profile-coaching-history-list-item-content-left {
        flex-basis: 70%;
    }
    .profile-coaching-history-header {
        /* background: conic-gradient(from 177.9deg at 50% 50%, #05D6B6 0deg, #4675FE 360deg); */
        background: linear-gradient(to right,#05D6B6, #4675FE);
    }
    .profile-coaching-history-header-content {
        padding: 20px 20px 10px 20px;
    }
}