.PotraitsSummaryWithList {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: var(--White, #fff);
}

.txt-left-alg {
  text-align: left;
}

.PotraitsSummaryWithList li {
  color: var(--Gray-Normal, #7e7f83);

  /* Text m/Regular */
  font-family: Urbanist;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}


.processing-bullet-txt{
  color: var(--Gray-Normal, #7E7F83);

  /* Text m/Regular */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}