.profile-profile-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: row;

}

.profile-contact-type {
    margin-left: 35px;
}



.join-aris-btn {
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--Spacing-spacing-xs, 8px);
    gap: 12px;
    background: inherit;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    color: var(--Base-Black, #000);
    font-feature-settings: 'liga' off, 'clig' off;
    border-radius: 18px;
    background: var(--Gray-Light, #F7F7F7);
    /* Text lg/Medium */
    font-family: "Urbanist";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: -0.27px;
    justify-content: space-between;
}


.mobile-background {
    position: relative;
   background: #4377fc;
   overflow: visible; /* or remove any overflow constraints */
z-index: 0;
}

.mobile-background {
    background: radial-gradient(circle at 15% 78%, rgba(5, 214, 182, 0.1), transparent 10%),
                radial-gradient(circle at 80% 2%, rgba(49, 198, 175, 0.7), transparent 30%),
                radial-gradient(circle at 5% 35%, rgba(49, 198, 175, 0.7), transparent 30%), /* Reduced opacity for #31C6AF */
                radial-gradient(circle at 20% 20%, #4377fc, transparent 60%), 
                #4377fc;
    color: #fff;
  }
  
  




/* JOIN ARIS */

.join-aris-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.join-aris-modal {
    /* margin: 10px; */
    background-color: white;
    border-radius: 24px;
    width: 100%;
    display: flex;
    width: 476px;
    padding: 16px 16px 32px 16px;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.join-aris-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 24px;
}

.join-aris-title {
    color: var(--Black, #000);

    /* Display xs/Semibold */
    font-family: "Urbanist";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */
    margin: 0px;
}

.join-aris-subtitle {
    color: var(--Gray-Normal, #7E7F83);

/* Text m/Medium */
font-family: "Urbanist";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 137.5% */
margin: 0px;
padding-top: 2px;
margin-bottom: 24px;
}



.join-aris-option-btn {
    width: 100%;
    text-align: left;
    padding: 16px;
    display: flex;
    padding: var(--Spacing-spacing-xl, 20px) var(--Spacing-spacing-lg, 16px);
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: var(--Radius-radius-md, 12px);
    background: var(--Gray-Light, #F7F7F7);
    border: none;
    color: var(--Base-Black, #000);
    font-feature-settings: 'liga' off, 'clig' off;

    /* Text lg/Medium */
    font-family: "Urbanist";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: -0.27px;
}

.join-aris-option-btn:last-child {
    border-bottom: none;
}

.join-aris-button-flex{
   display: flex;
   align-items: center;
    width: 100%;
    justify-content: space-between;
}

.join-aris-input {
    display: flex;
    min-height: 40px;
    padding: 8px 12px;
    align-items: center;
    font-family: "Urbanist";
    gap: 8px;
    align-self: stretch;
    border-radius: var(--Radius-radius-md, 12px);
border: 1px solid var(--gray-light-hover, #E4E4E7);
background: var(--Base-White, #FFF);
margin-bottom: 10px;
}

.join-aris-input-heading{
   color: var(--Base-Black, #000);
    /* Text s/Medium */
    font-family: "Urbanist";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    margin-bottom: 2px;
}

.join-aris-input:focus{
    outline: none;
}

.join-aris-add-btn {
    color: var(--Greek-Blue-Normal, #0D5EAF);

    /* Text m/Medium */
    font-family: "Urbanist";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    background: inherit;
    border: none;
    margin-top: 16px;
    margin-bottom: 24px;
}

.join-aris-submit-btn {
    display: flex;
    max-height: 52px;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 100px;
    font-family: "Urbanist";
background: var(--Greek-Blue-Dark, #274079);
color: #fff;
border: none;
}

.join-aris-submit-btn:disabled {
    display: flex;
    max-height: 52px;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 100px;
background: var(--Greek-Blue-Light, #E7EFF7);
color: #B4CDE6;
font-family: "Urbanist";
border: none;
}



.join-aris-close-btn {
    border: none;
    background: inherit;
}

@media(max-width:414px){
    .join-aris-overlay {
        align-items: flex-end;
     
    }

    .join-aris-modal {  border-radius: 24px 24px 0px 0px;}
}