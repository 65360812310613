/* ----- ArisStory List ------ */
.story-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* background: radial-gradient(circle at 15% 71%, #05d6b6, transparent 20%),
    radial-gradient(circle at 75% 5%, #05d6b6, transparent 20%),
    radial-gradient(circle at 50% 20%, #4377fc, transparent 60%), #4377fc; */
  background: linear-gradient(to bottom, #468df7, #43b2e9);
  min-height: calc(100vh - 12px);
  font-family: Urbanist;
  padding-top: 12px;
}

.personality-type-stories {
  color: var(--Text-Primary, #fff);
  text-align: center;

  /* Display m/Semibold */
  font-family: Urbanist;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 118.75% */
  text-align: center;
  margin-bottom: 2px;
}

.story-container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  /* justify-content: space-between; */
  background: radial-gradient(circle at 15% 71%, #05d6b6, transparent 20%),
    radial-gradient(circle at 75% 5%, #05d6b6, transparent 20%),
    radial-gradient(circle at 50% 20%, #4377fc, transparent 60%), #4377fc;
  min-height: calc(100vh - 12px);
  font-family: Urbanist;
  padding-top: 12px;
}
.story-navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 10px; */
}

.story-navbar .story-back-button {
  display: flex;
  max-width: 2.75rem;
  max-height: 2.75rem;
  padding: 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0.25rem 1.56rem 0px rgba(0, 0, 0, 0.08);
  margin-left: 40px;
  cursor: pointer;
}

.story-navbar .story-navbar-logo {
  display: none;
}

.title {
  color: #ffffff;
  font-family: Urbanist;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
}

@media (min-width: 480px) {
  .topic-list {
    margin-top: 40px;
  }
}

.topic-header {
  color: #ffffff;
  font-family: Urbanist;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  padding: 1.6rem 1rem 1.5rem 0;
  display: none;
}

.topic-item {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  /* border-bottom: 1px solid #eee; */
}

.topic-item:last-child {
  border-bottom: none;
}

.topic-label {
  flex-grow: 1;
  margin-left: 1rem;
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 500;
}

.duration {
  font-size: 0.8rem;
  font-weight: lighter;
  color: #fff;
  margin-left: 1rem;
  margin-top: 0.3rem;
}

.play-button-container {
  background-color: #ffffff;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
}

.play-button {
  color: #000;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  margin-top: 3px;
  margin-left: 2px;
}

/* ---------- TopicsScreen ---------- */
/* General styles */
body {
  margin: 0;
  font-family: "Urbanist", sans-serif;
}

.screen-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(to bottom, #87ceeb, #4682b4); /* Blue gradient */
}

/* Top Bar */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.logo-img {
  height: 24px;
}

/* Main Section */
.main-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.shape-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
}

.shape {
  width: 50px;
  height: 50px;
  background: rgba(0, 255, 255, 0.6);
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%); /* Diamond Shape */
  animation: float 3s infinite ease-in-out;
}

/* Floating animation for shapes */
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Description Area */
.description-area {
  padding: 1rem;
  background-color: #ffffff;
  color: #000;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
}

/* Bottom Controls */
.bottom-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
}

.control-button {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  background-color: #00ff00;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.control-button.pause {
  background-color: #ffcc00;
}

.control-button.stop {
  background-color: #ff0000;
}

.as-strength-blind-hero-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  min-height: 70dvh;
}

.as-strength-txt {
  color: var(--Text-Primary, #fff);
  text-align: center;

  /* Display xl/Medium */
  font-family: Urbanist;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 48px */
}

.as-strength-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.as-strength-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--Spacing-spacing-md, 12px);
  align-items: center;
  gap: var(--Spacing-spacing-2xs, 6px);
  border-radius: var(--Radius-radius-xs, 8px);
  background: var(--Turquoise, #04d9b5);
  width: fit-content;

  color: var(--Base-Black, #000);
  font-feature-settings: "liga" off, "clig" off;

  /* Text lg/Medium */
  font-family: Urbanist;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.27px;

  opacity: 0;
  transform: translateY(10px);
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.as-blind {
  border-radius: var(--Radius-radius-xs, 8px);
  background: var(--Pink-Normal, #f6b7b0);
}

.as-progress-inner-circle {
  width: 197.977px;
  height: 197.977px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: var(--Crayola-Blue-Dark, #3557bf);
  border-radius: 150px;

  color: var(--Base-White, #fff);
  text-align: center;
  font-family: Urbanist;
  font-size: 26.107px;
  font-style: normal;
  font-weight: 500;
  line-height: 34.809px; /* 133.333% */
  letter-spacing: -0.392px;
}

.reduced-opacity {
  opacity: 0.4;
}

.word-map {
  width: 24rem;
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.word-map span {
  color: var(--Base-White, #fff);
  text-align: center;
  font-family: Urbanist;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
}

.word-map .active {
  opacity: 0.4;
}

.audio-content {
  /* width: 50%; */
  margin-bottom: 5px;
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  font-family: Urbanist;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
}

.scroll-container {
  /* position: relative; */
  height: 70px; /* Fixed height to contain the text */
  overflow: hidden;
}

.scroll-line {
  display: inline-block; /* Keep all the words in one line */
  white-space: nowrap; /* Prevent line breaks */
  opacity: 0; /* Start with opacity 0 */
  transition: opacity 0.5s ease-out; /* Smooth transition */
  color: #fff;
  text-overflow: ellipsis;
  font-family: "Urbanist";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
}

.scroll-content {
  /* white-space: pre-wrap; */
  color: #fff;
  font-family: "Urbanist";
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
}

.visible {
  opacity: 1;
}

.visual-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20rem;
  /* min-height: 70dvh; */
}

.no-border {
  border: none;
}

.clr-fff {
  color: #fff;
}
.as-eieo-hero-wrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 70dvh;
}

.as-eieo-container {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  opacity: 0.4;
}

.as-eieo-img {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 10px;
}

.as-circular-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.as-eieo-container-active {
  opacity: 1;
}

.as-eieo-text {
  color: var(--Base-White, #fff);
  text-align: center;

  /* Display xs/Medium */
  font-family: Urbanist;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 28.8px */
}

.image-grid-story {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  gap: 10px; /* Space between images */
  padding: 20px;
}

.image-item-story img {
  width: 100%; /* Make images fill their container */
  height: auto;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.comb-txt-audio-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  align-items: center;
  position: relative;
}

.scroll-wrapper {
  display: flex;
  justify-content: center;
  width: 40%;
  overflow: hidden;
}

@media (max-width: 768px) {
  .container {
    align-items: flex-start;
    padding-left: 1rem;
  }
  .story-navbar .story-back-button {
    padding-left: 10px;
  }

  .word-map {
    width: 24rem;
    min-height: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .word-map span {
    color: var(--Base-White, #fff);
    text-align: center;
    font-family: Urbanist;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 500;
  }

  .word-map .active {
    opacity: 0.4;
  }

  .audio-content {
    /* width: 80%; */
    margin-bottom: 1.5rem;
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-family: "Urbanist";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }

  .visual-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* min-width: 20rem; */
    /* min-height: 80dvh; */
  }

  .word-map {
    width: 21rem;
  }
  .word-map span {
    font-size: 2rem;
  }
  .scroll-wrapper {
    width: 100%;
  }
}

@media (max-width: 480px) {
  /* .container {
    align-items: flex-start;
    padding-left: 1rem;
  } */
  .story-navbar .story-back-button {
    margin-left: 14px;
    background-color: #ffffff;
    color: #000;
  }
  .story-navbar .story-navbar-logo {
    display: block;
  }
  .story-navbar .title {
    display: none;
  }
  .topic-header {
    display: block;
  }
  .story-navbar .story-navbar-logo {
    margin-right: 3rem;
  }
  .audio-content {
    /* width: 90%; */
  }
}
