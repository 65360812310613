.loading-page-container {
  height: 100dvh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(168.14deg, #4675fe 6.98%, #05d6b6 102.55%);
}

.loader-er {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full screen height */
  width: 100dvw;
  background: linear-gradient(168.14deg, #4675fe 6.98%, #05d6b6 102.55%);
}

.loader-df-container {
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  background-color: var(--Crayola-Blue-Light, #edf1ff);
  backdrop-filter: blur(17px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 55px;
}

.loader-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stroke-1-loader {
  position: absolute;
  width: 10dvw;
  height: 331.886px;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: var(--White, #fff);
  opacity: 0.9;
  z-index: 10;
  animation: pulse 8s infinite;
}

.stroke-2-loader {
  position: absolute;

  width: 14dvw;
  height: 458.868px;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: var(--White, #fff);
  opacity: 0.8;
  animation: pulse 8s infinite;
}

.stroke-3-loader {
  position: absolute;

  width: 18dvw;
  height: 458.868px;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: var(--White, #fff);
  opacity: 0.8;
  animation: pulse 8s infinite;
}

.stroke-4-loader {
  position: absolute;

  width: 22dvw;
  height: 458.868px;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: var(--White, #fff);
  opacity: 0.8;
  animation: pulse 8s infinite;
}

.stroke-5-loader {
  position: absolute;

  width: 26dvw;
  height: 458.868px;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: var(--White, #fff);
  opacity: 0.8;
  animation: pulse 8s infinite;
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
    opacity: 0.6;
  }

  50% {
    transform: scale(1.1);
    opacity: 1;
  }
}

.sl-spinner1 .sl-loader .sl-loader-items .sl-item::after {
  border-radius: 30% !important;
}