.carousel-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 1200px; 
  }
  
  .carousel-content {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
  }
  
  .carousel-item {
    flex: 0 0 100%;
    width: 100%;
    box-sizing: border-box;
  }
  
  .carousel-item.active {
    display: block;
  }
  
  .carousel-indicators {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }
  
  .carousel-indicators div {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #E4E4E7;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .carousel-indicators .active{
    background-color: #4674FE;
  }
  
  .carousel-navigation {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 10px 0;
  }
  
  .prev-btn, .next-btn {
    background-color: #FFFFFF;
    color: black;
    border: none;
    border-radius: 50%;
    padding: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .carousel-content .caraousel-title
  {
    font-family: "Urbanist", sans-serif;
    font-size: 36px;
    font-weight: 600;
    line-height: 43.2px;
    text-align: center;
    font-size: clamp(24px, 5vw, 36px);
  }
  
  .carousel-content .caraousel-description
  {
    font-family: "Urbanist", sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    font-size: clamp(14px, 3vw, 18px); 
  }

  .prev-btn:disabled, .next-btn:disabled {
    background-color: #F7F7F71A;
    cursor: not-allowed;
    color: #FFFFFF;
    opacity: 10;
  }

  @media screen and (max-width: 1024px) {
    .carousel-content .caraousel-title {
        color: #FFFFFF;
        margin-top: 1.5rem;
    } 
    .carousel-content .caraousel-description {
        color: #FFFFFF;
    }
  }

@media screen and (max-width: 768px) {
    .carousel-navigation {
        gap: 10px;
    }
    
    .prev-btn, .next-btn {
        padding: 8px;
    }
}

@media screen and (max-width: 480px) {
    .carousel-indicators {
        margin: 15px 0;
    }
}

@media screen and (max-width: 405px) {

    .carousel-item img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    .carousel-content .caraousel-description {
        font-size: 14px;
        line-height: 1.4;
    }
}