.uploadImage-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Overlay effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
}


.uploadImage-modal::-webkit-scrollbar {
  width: 8px;
}

.uploadImage-modal::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.uploadImage-modal::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.uploadImage-modal::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.uploadImage-modalContent {
  position: relative;
  /* width: 90%; */
  max-width: 476px;
  width: 476px;
  max-height: 80vh;
  background-color: white;
  border-radius: var(--Radius-radius-2xl, 24px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
}

/* Add these styles to your style.css file */

.audio-controls-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
 width: 60px;
}

.audio-control {
  display: flex;
  align-items: center;

margin-right: 5px;
  border-radius: 8px;
  margin: 3px 0px;
}

.audio-control label {
  flex: 1;
  margin: 0;
  font-size: 14px;
  color: #344054;
}

.update-play-button {
  color: var(--Black, #000);

/* Text s/Regular */
font-family: Urbanist;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
  background: none;
  border: none;
  cursor: pointer;
  flex-direction: row;
  padding: 8px;
  gap: 3px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.play-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.edit-button {
  /* align-self: flex-end; */
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: #344054;
}

.upload-edit-btn{
  background: none !important;
  border: none !important;
  cursor: pointer;
  padding: 8px;
  color: #344054;
  color: var(--Gray-Normal, #7E7F83);

/* Text s/Medium */
font-family: Urbanist;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: none;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
}


.name-pronunciation-container{
  display: flex;
  flex-direction: column;
  text-align: left;
}

.say-my-name{
  font-size: 12px !important;
  color: #706f6f !important;
}

.edit-button:hover {
  color: #1d2939;
}

.animate-spin{

    animation: spin 1s linear infinite;
  
}

@media (max-width: 476px) {
  .uploadImage-modal {
    align-items: flex-end;
  }

  .uploadImage-modalContent {
    width: 100%;
    border-radius: 24px 24px 0px 0px;
  }
}

.uploadImage-form {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 60vh;
  padding-right: 10px;
}

/* Custom scrollbar for better aesthetics */
.uploadImage-form::-webkit-scrollbar {
  width: 8px;
}

.uploadImage-form::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.uploadImage-form::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.uploadImage-form::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.uploadImage-closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
  z-index: 10;
  padding: 10px;
}

.uploadImage-header,
.uploadImage-statusMessage,
.uploadImage-updateButton {
  flex-shrink: 0; /* Prevent these from shrinking */
}
/* Header */
.uploadImage-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.uploadImage-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.uploadImage-title {
  font-size: 1.25rem;
  font-weight: bold;
}

/* Form Group */
.uploadImage-formGroup {
  margin-bottom: 16px;
}

.aris-voice-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.aris-voice-container-left {
  flex: 1;
}

.voice-stop-div {
  min-width: 10px;
  min-height: 10px;
  background-color: #FFF;
}

.uploadImage-formGroup label {
  display: flex;
  font-size: 0.875rem;
  color: var(--Base-Black, #000);

  /* Text s/Medium */
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
}

.date-picker-container-editpage {
  width: 100%;
  padding: 8px 12px;
  color: var(--Base-Black, #000);
  /* Text m/Medium */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  box-sizing: border-box;
  border-radius: var(--Radius-radius-md, 12px);
  border: 1px solid var(--gray-light-hover, #e4e4e7);
  background: var(--Base-White, #fff);
}

.uploadImage-input {
  width: 100%;
  padding: 8px 12px;
  color: var(--Base-Black, #000);
  /* Text m/Medium */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  box-sizing: border-box;
  border-radius: var(--Radius-radius-md, 12px);
  border: 1px solid var(--gray-light-hover, #e4e4e7);
  background: var(--Base-White, #fff);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.uploadImage-input:focus {
  outline: none;
}
/* Mic Button */
.uploadImage-micButton {
  background: none;
  border: none;
  color: #007bff;
  font-size: 0.875rem;
  cursor: pointer;
}

.uploadImage-micIcon {
  margin-right: 4px;
}

/* Status Message */
.uploadImage-statusMessage {
  color: green;
  font-size: 0.875rem;
  margin-bottom: 16px;
  margin-top: 2px;
}

/* Update Button */
.uploadImage-updateButton {
  width: 200px;
  padding: 12px;
  color: var(--greek-blue-light-active, #b4cde6);
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  border-radius: 100px;
  background: var(--Greek-Blue-Light, #e7eff7);
  border: none;
  cursor: pointer;
}

.uploadImage-updateButton.edited {
  background-color: #0056b3; /* Highlight color for edited state */
}

.uploadImage-closeButton {
  position: absolute;
  top: 10px; /* Adjust for spacing */
  right: 10px; /* Adjust for spacing */
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333; /* Adjust color for better visibility */
  z-index: 10; /* Ensure it's on top */
}

.uploadImage-avatarWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.uploadImage-editPhotoButton {
  font-family: "Urbanist", sans-serif;
  background: inherit;
  color: rgba(95, 95, 98, 1);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.record-section {
  text-align: left;
  flex-direction: column;
}

.date-picker-container-editpage {
  /* This makes the DOB field span the entire width */
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.date-picker-container-input-edit {
  border: none;
  width: 100%;
  font-family: "Urbanist", sans-serif;
  color: var(--Gray-500, #667085);

  /* Text m/Medium */

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}

.date-picker-container-input-edit:focus {
  outline: none;
}

.react-datepicker-wrapper {
  width: 90%;
}

.editpage-popup {
  display: flex;
  justify-content: center;
}

.editpage-popup-content {
  width: 300px;
  position: relative;
  bottom: 0px;

  background-color: #f44336; /* Red background */
  color: var(--Base-Black, #000);
  /* Text s/Regular */
  font-family: "Urbanist", sans-serif;
  justify-content: center;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1000;
  animation: fadein 0.3s ease, fadeout 0.3s ease 4.7s;
  border-radius: var(--Radius-radius-xs, 8px);
  background: var(--Turquoise-Normal, #04d9b5);
}

@media (max-width: 400px) {
  .uploadImage-modal {
    align-items: flex-end;
  }

  .uploadImage-modalContent {
    border-radius: 24px 24px 0px 0px;
  }
}
