.mic-cntr {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 89.552px;
  background: linear-gradient(99deg, #05d6b6 6.38%, #0bc9b8 98.88%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mic-svg {
  width: 20px;
  height: 20px;
}
